import React, { memo } from 'react'

const PopularCard = ({ image, text, right, onClick }) => {
  return (
    <div className="sm:w-1/2 w-full relative mb-5 sm:mb-0 cursor-pointer shadow-white rounded-xl active:opacity-70" onClick={onClick}>
      <img
        className="h-[350px] w-full object-cover rounded-xl"
        src={image}
      />
      <span className={`absolute bottom-5 ${right ? 'right-5' : 'left-5'} text-[18px] text-white bg-terciary rounded-full px-5 py-2`}>
        {text}
      </span>
    </div>
  )
}

export default memo(PopularCard)
