import React, { memo, useMemo } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {
  CalendarIcon,
  ClockIcon,
  CoteriSymbolIcon
} from '../../../assets/icons'
import { auth } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { twMerge } from 'tailwind-merge'

const ActiveEventsCard = ({ event, setDeleteEventModal, className }) => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  const handleEventDate = () => {
    if (user.uid === event.hostID) {
      navigate(`/event/${event.key}`)
      return
    }
    navigate(`/event/${event.key}/rsvp/${user.uid}/details`)
  }

  const handleCancelEvent = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setDeleteEventModal(event)
  }

  const timeOfEvent = useMemo(() => {
    if (!event || !event.date || !event.time || !event.timezone) return ''
    return moment(`${event.date}T${event.time}`).format(`hh:mm A`)
  }, [event])

  return (
    <div
      className={twMerge("w-full p-2 relative rounded-input flex flex-col shadow-md border-1 border-music-card cursor-pointer bg-white shadow-white active:opacity-70", className)}
      onClick={handleEventDate}
    >
      <div className="flex items-center justify-between">
        <h2 className="grow">{event.name}</h2>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            position="absolute"
            className="!h-6 !min-w-[24px]"
            top="10px"
            right="6px"
            icon={<DotsIcon />}
            variant="outline"
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
          <MenuList>
            <MenuItem onClick={handleCancelEvent} className="!text-red-600">
              Cancel event
            </MenuItem>
          </MenuList>
        </Menu>
      </div>

      <div className="flex flex-row items-center justify-between mt-[9px]">
        <div className="flex flex-row items-center">
          <CalendarIcon fill="#000" />
          <span className={event.date ? `ml-[8px]` : `ml-[8px]`}>
            {event.date
              ? moment(event.date).format('MMM DD, YYYY')
              : 'No date set'}
          </span>
        </div>

        <ChevronRightIcon fontSize={20} />
      </div>

      <div className="mt-[9px] flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start">
          <ClockIcon fill="#000" />
          <span className="ml-[8px]">
            {timeOfEvent ? timeOfEvent : 'No time set'}
          </span>
        </div>

        {event.hostID === user.uid && (
          <div className="flex flex-row items-center justify-start">
            <span className="mr-[5px] text-[12px] text-placeholder">
              Hosted by you
            </span>
            <CoteriSymbolIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ActiveEventsCard)
