import { CheckIcon, AddIcon } from '@chakra-ui/icons'
import React, { memo, useEffect } from 'react'
import { SpotifyIcon } from '../../../assets/icons'
import { twMerge } from 'tailwind-merge'
import { usePlaying } from '../../../context/PlayingContext'
import { ReactComponent as PlaySVG } from '../../../assets/icons/play.svg'
import { ReactComponent as PauseSVG } from '../../../assets/icons/pause.svg'

const MusicCard = ({
  music,
  className,
  checked,
  onClick,
  provider,
  canPlay,
  disabled
}) => {
  const { track: playing, setTrack: setPlaying } = usePlaying()

  const handleOpenSpotifyLink = () => {
    window.open(
      `https://open.spotify.com/track/${music.spotifyID || music.id}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  useEffect(() => {
    return () => {
      setPlaying(null)
    }
  }, [])

  return (
    <div
      className={twMerge(
        `w-full p-4 ${checked ? 'bg-music-card' : 'bg-white'} rounded-md flex flex-row justify-between items-center ${disabled ? 'cursor-default' : 'cursor-pointer'} shadow-apple`,
        className,
      )}
      onClick={disabled ? () => { } : onClick}
    >
      <div className="flex flex-1 flex-row items-center min-w-0">
        {canPlay ? (
          <button
            className="p-0 w-8 h-8 min-w-[32px] mr-2 rounded-full text-white flex justify-center items-center mobile:mr-4"
            onClick={(e) => {
              e.stopPropagation()
              setPlaying(playing?.id === music.id ? null : music)
            }}
          >
            {playing?.id === music.id ? (
              <PauseSVG style={{ width: 25 }} />
            ) : (
              <PlaySVG style={{ width: 25 }} />
            )}
          </button>
        ) : null}

        <img
          id="image"
          className="w-[50px] h-[45px] z-2 mobile:w-[70px] mobile:h-[65px]"
          src={music.artworkURL}
        />

        <div
          id="song"
          className="flex flex-col ml-[12px] mobile:ml-[20px] flex-1 min-w-0 truncate"
        >
          <span className='font-semibold' style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{music.name}</span>

          {!(provider === 'apple_music') && (
            <div className="flex flex-row items-center z-2 min-w-0">
              <button
                className="flex items-center min-w-0"
              // onClick={(e) => {
              //   handleOpenSpotifyLink()
              //   e.stopPropagation()
              // }}
              >
                <div className='w-[21px] h-[21px]'>
                  <SpotifyIcon fill="black" width={21} height={21} />
                </div>
                <span className='ml-1' style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {music.artistName} • {music.albumName}
                </span>
              </button>
            </div>
          )}
        </div>

        <div
          className={`w-[30px] h-[30px] flex ${checked ? 'border-none' : 'border-terciary'} border-1 items-center justify-center rounded-full ml-[30px]`}
        >
          {checked ? (
            <CheckIcon color={'#422D8F'} />
          ) : (
            <AddIcon className={"#120a31"} />
          )}
        </div>
      </div>

    </div>
  )
}

export default memo(MusicCard)
