import React from 'react'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'

const PaymentMethodModal = ({ opened, onClose, onContinue }) => {
  const DottedLine = ({ color }) => {
    const dottedStyle = {
      backgroundImage: 'radial-gradient(' + color + ' 1px, transparent 1px)',
      backgroundSize: '3px 10px'
    }

    return <div className="w-[1.5px] h-full" style={dottedStyle} />
  }

  return (
    <Modal isOpen={opened} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
      <div className="bg-white rounded-input relative pb-6 w-full flex flex-col">
        <div className="absolute inset-0 w-full h-full left-4 flex">
          <DottedLine color={"#80808033"} />
        </div>

        <div className="ml-4 flex mt-4">
          <div className='w-[2px] h-4 bg-primary' />

          <span className='font-semibold ml-2 -mt-1'>Do you want to add a payment method for your guests?</span>
        </div>

        <span className='mx-6 text-[14px] mt-4'>We'll let your guests know the best way to pay you for event-related costs.</span>

        <div className='mx-6 mt-16 flex items-center justify-between z-10'>
          <Button type={'border'} text={'No thanks'} size={'small'} onClick={onClose} />

          <Button text={'Continue'} size={'small'} onClick={onContinue} />
        </div>
      </div>
    </Modal>
  )
}


export default PaymentMethodModal