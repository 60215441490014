import React, { useState } from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import OldPlaylist from './OldPlaylist'
import { getTokens } from '../services/helpers'
import { saveSelectedTracks } from '../services/Circle'
import { ref, update } from 'firebase/database'
import { database } from '../firebase'

const TracksWErrorModal = ({
  uid,
  circleCode,
  selected,
  minLimit,
  maxLimit,
  provider,
  option,
  connections,
  tracksWError,
  setTracksWError,
  searchSimilars,
  onSelect
}) => {
  const [saving, setSaving] = useState(false)
  const toast = useToast()

  const handleSubmit = async () => {
    console.log('selected', selected)
    console.log('tracksWError', tracksWError)

    const newSelectedSongs = []
    selected.forEach((item) => {
      const newTrack = tracksWError.find((x) => x.original?.id === item.id)
      if (!!newTrack) {
        console.log('newTrack', item.id, newTrack.id)
        newSelectedSongs.push(newTrack)
      } else {
        newSelectedSongs.push(item)
      }
    })

    setSaving(true)
    try {
      // ALWAYS USE SETTINGS TOKEN
      const token = await getTokens('guest', `streaming-${circleCode}`)
      if (!token.appleMusicToken && !token.accessToken) {
        toast({
          status: 'error',
          title: 'Error while refreshing tokens',
          position: 'top'
        })
        return
      }

      await saveSelectedTracks(
        circleCode,
        newSelectedSongs,
        uid,
        provider,
        token,
        minLimit,
        maxLimit
      )

      // Save selection
      tracksWError.forEach((item) => {
        if (!!item.original) {
          const track = {
            id: item.id,
            provider: item.provider,
            title: item.name,
            href: item.href,
            artistName: item.artistName,
            albumName: item.albumName,
            popularity: item.popularity || null,
            genres: item.genres || null,
            spotifyID: item.spotifyID || null,
            appleMusicID: item.appleMusicID || null,
            spotifyHREF: item.spotifyHREF || null,
            appleMusicHREF: item.appleMusicHREF || null,
            releaseDate: item.releaseDate || null
          }
          update(
            ref(database, `songsReplaced/${item.original.id}/${item.isrc}`),
            track
          ).then(() => null)
        }
      })
    } catch (e) {
      console.log('handleSubmit', e)
    }
    setSaving(false)
    onSelect()
  }

  return (
    <Modal
      isOpen={tracksWError.length > 0}
      onClose={() => setTracksWError([])}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalHeader color="primary">
          Could not find the following songs
        </ModalHeader>
        <ModalCloseButton mt="5px" mr="0" />
        <ModalBody px="0">
          <OldPlaylist
            type="selected"
            option="0"
            provider={provider}
            playlist={tracksWError}
            circleCode={circleCode}
            maxLimit={maxLimit}
            selected={[]}
            prevSelectedSongs={[]}
            toggleSelectSong={searchSimilars}
            searchSimilars={searchSimilars}
            connections={connections}
            hideSelect={true}
            containerStyle={{
              paddingLeft: '20px',
              paddingRight: '20px',
              overflowY: 'scroll',
              maxHeight: '70vh'
            }}
          />
          <Flex flex="1" justifyContent="center" mt="20px">
            <Button
              colorScheme="pink"
              size="lg"
              mb="20px"
              py="24px"
              color="white"
              disabled={saving}
              isLoading={saving}
              onClick={handleSubmit}
            >
              Done
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TracksWErrorModal
