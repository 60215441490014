import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import Base from '../../base'
import { Helmet } from 'react-helmet'
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare'
import { useToast } from '@chakra-ui/react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, database } from '../../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserInitials } from '../../../utils/square'
import DottedLine from '../../../components/DottedLine/DottedLine'
import { ref, remove, update } from 'firebase/database'
import { useObject } from 'react-firebase-hooks/database'
import SquaresTable, {
  findQuarter
} from '../../../components/Squares/SquaresTable/SquaresTable'
import SuperBowlStripeModal from '../../../components/SuperBowl/SuperBowlStripeModal/SuperBowlStripeModal'
import { setPaymentData } from '../../../services/database'
import PaymentMethodModal from '../../../components/PaymentMethodModal/PaymentMethodModal'
import SquaresCostModal from '../SquaresCostModal/SquaresCostModal'
import { v4 } from 'uuid'
import * as amplitude from '@amplitude/analytics-browser'

const SquaresChoose = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { circleCode } = useParams()
  const [user] = useAuthState(auth)
  const [initials, setInitials] = useState('')
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [pendingPayment, setPendingPayment] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedSquares, setSelectedSquares] = useState({})
  const [addPaymentMethod, setAddPaymentMethod] = useState(false)
  const [addCostModal, setAddCostModal] = useState(false)

  const [superBowlSnap, superBowlLoading] = useObject(
    ref(database, `superBowl`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [squareSquaresSnap, squareSquaresLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )

  const eventInfo = eventSnap?.val()
  const superBowlInfo = useMemo(
    () => superBowlSnap?.val() ?? {},
    [superBowlSnap]
  )
  const squareSquares = useMemo(
    () => squareSquaresSnap?.val() ?? {},
    [squareSquaresSnap]
  )
  const myInitials = useMemo(() => {
    const key = Object.keys(squareSquares).find(
      (key) => squareSquares[key].uid === user?.uid
    )
    return key ? squareSquares[key]?.initials ?? '' : ''
  }, [squareSquares, user?.uid])

  const isGameDisabled = useMemo(
    () =>
      superBowlInfo.startTime <= new Date().getTime() / 1000 &&
      Object.keys(squareSquares).length < 100,
    [squareSquares, superBowlInfo]
  )

  const hasPaymentOption =
    eventInfo?.allowCash ||
    eventInfo?.cashapp ||
    eventInfo?.paypal ||
    eventInfo?.venmo ||
    eventInfo?.zelle ||
    eventInfo?.fixedPrice ||
    eventInfo?.costPerSquare

  const quarter = useMemo(
    () => findQuarter(superBowlInfo.currentPeriod),
    [superBowlInfo]
  )

  const handleContinue = async () => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Enter your initials to begin',
        position: 'top'
      })
      setHasError(true)
      return
    }

    if (!!eventInfo.squaresGameId || !!eventInfo?.isPremium) {
      await handleUpdate()
    } else {
      if (!hasPaymentOption) {
        setAddPaymentMethod(true)
        return
      }

      setPaymentModalOpen(true)
    }
  }

  const handleUpdate = async () => {
    try {
      setLoading(true)

      if (!eventInfo.squaresGameId) {
        amplitude.track('Super Bowl Game Payment', {
          circleCode
        })
        await update(ref(database, `circles/${circleCode}/info/`), {
          squaresGameId: v4().replace(/-/g, '')
        })
      }

      await update(
        ref(database, `circles/${circleCode}/squares`),
        selectedSquares
      )

      if (Object.keys(selectedSquares).length >= 100) {
        await remove(ref(database, `subscribers/EMPTY_SQUARES/${circleCode}`))
      }

      navigate(`/event/${circleCode}/squares/summary`)
    } catch (err) {
      console.log('SquaresChoose: ', err)
      toast({
        status: 'error',
        title: 'Something went wrong',
        position: 'top'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleConfirm = async () => {
    try {
      await update(
        ref(database, `circles/${circleCode}/squares`),
        selectedSquares
      )

      setPaymentData({ circleCode, isGame: true })
      setPendingPayment(true)
      window.open(
        `https://buy.stripe.com/${process.env.REACT_APP_GAME_STRIPE_LINK}?client_reference_id=game-${circleCode}`,
        '_blank',
        'noopener,noreferrer'
      )
    } catch (err) {
      console.log('SquaresChoose: ', err)
      toast({
        status: 'error',
        title: 'Something went wrong',
        position: 'top'
      })
    }
  }

  // Changes already selected squares initials, if user changes initial
  const handleChangeAlreadySelectSquares = useCallback(async () => {
    if (!user?.uid) return

    const updatedSquares = Object.keys(selectedSquares).reduce((acc, key) => {
      const square = selectedSquares[key]
      if (square.uid === user.uid) {
        acc[key] = {
          ...square,
          initials: getUserInitials(initials)
        }
      } else {
        acc[key] = square
      }
      return acc
    }, {})

    setSelectedSquares(updatedSquares)
  }, [initials, selectedSquares, user?.uid])

  const handleCancel = () => {
    navigate(-1)
  }

  const onChangeText = (value) => {
    if (value?.length > 19) {
      return
    }

    if (hasError && value?.length > 0) {
      setHasError(false)
    }

    if (!value || value.trim() === '') {
      setInitials('')
      return
    }

    if (value.trim().toLowerCase() === 'your initials:') {
      console.log('SquaresChoose: here')
      setInitials('')
      return
    }

    if (!value?.startsWith('Your initials:')) {
      value = `Your initials: ${value.toUpperCase()}`
    } else {
      const userInput = value.slice(14).trim()
      value = `Your initials: ${userInput.toUpperCase()}`
    }

    setInitials(value)
  }

  const onSquareClick = (row, col) => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Check the fields',
        position: 'top'
      })
      setHasError(true)
      return
    }

    const squareKey = `${row}${col}`

    const prevSelectedSquare = squareSquares[`${row}${col}`]
    if (!!prevSelectedSquare && prevSelectedSquare.uid === user?.uid) {
      toast({
        status: 'error',
        title: 'Square has been already selected',
        position: 'top'
      })
      return
    }

    if (!!selectedSquares[squareKey]) {
      const _selectedSquares = { ...selectedSquares }
      delete _selectedSquares[squareKey]
      setSelectedSquares(_selectedSquares)
    } else {
      setSelectedSquares({
        ...selectedSquares,
        [squareKey]: {
          uid: user?.uid,
          initials: getUserInitials(initials)
        }
      })
    }
  }

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!eventLoading && !loading) {
      const isHost = eventInfo?.hostID === user?.uid
      if (!eventInfo || !isHost) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
    }
  }, [eventLoading, loading, user, eventInfo, navigate, circleCode])

  useEffect(() => {
    if (!!pendingPayment && !!eventInfo && !!eventInfo.squaresGameId) {
      setLoading(false)
      navigate(`/event/${circleCode}/squares/summary`, { replace: true })
    }
  }, [eventInfo, pendingPayment, navigate, circleCode])

  useEffect(() => {
    if (!squareSquaresLoading && !!myInitials) {
      setInitials(`Your initials: ${myInitials}`)
    }
  }, [squareSquaresLoading, myInitials])

  useEffect(() => {
    handleChangeAlreadySelectSquares()
  }, [initials])

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      footerContinueOnClick={isGameDisabled ? undefined : handleContinue}
      footerCancelOnClick={handleCancel}
      footerCancelText={isGameDisabled ? 'Back' : 'Skip'}
      footerContinueProps={{
        loading
      }}
      removeVerticalPadding
      enableFooter
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - See your events history</title>
        <meta
          name="description"
          content="See events you’ve hosted or attended. Click on the tiles to see the event details."
        />
      </Helmet>

      {loading || eventLoading || squareSquaresLoading || superBowlLoading ? (
        <div className="flex justify-center items-center flex-1">
          <ClipLoader />
        </div>
      ) : isGameDisabled ? (
        <div className="flex flex-col items-center justify-center flex-1 w-full">
          <h2 className="text-primary text-[20px]">Game Closed</h2>
          <p className="text-[14px]">
            Not all squares were claimed prior to game start
          </p>
        </div>
      ) : (
        <div className="flex relative flex-1 mt-[-20px] mb-[-15px]">
          <div id="content" className="w-full">
            {/* Header */}
            <div className="mt-12 w-full px-5">
              <h2 className="text-primary text-[20px]">
                Choose your squares by clicking on them below.
              </h2>
              <p className="text-[14px]">
                Teams and corresponding numbers will appear once all squares are
                filled in.
              </p>
              {/* <div className='mt-3 flex items-center justify-between flex-row'>
                  <span className='text-[14px]'>(Venmo: @jonathanorler)</span>
                  <span className='ml-3 text-[14px] text-primary font-semibold cursor-pointer underline' onClick={handleEditGame}>Edit game settings</span>
                </div> */}

              <div className="mt-3 flex items-center justify-between flex-col sm:flex-row">
                <div className="flex items-center gap-3">
                  <SquaresSquare
                    placeholder={'Enter your initials to begin'}
                    wrapperInputClassName={`w-auto px-2 min-w-[200px] bg-white`}
                    inputProps={{ fontSize: 14 }}
                    inputClassName={'placeholder:text-[14px] bg-white'}
                    value={initials}
                    onChangeText={onChangeText}
                    removeOnChangeValidation
                    error={hasError}
                  />
                  <div className="hidden sm:flex">
                    {Object.keys(selectedSquares).length} squares
                  </div>
                </div>

                <div className="flex flex-col items-center gap-5">
                  <div className="flex items-center sm:mt-0 mt-4">
                    <div className="w-8 h-8 rounded-sm border-[2px] border-primary mr-3" />
                    <span className="pointer-events-none text-[12px]">
                      You own this square
                    </span>
                  </div>
                  <div className="flex sm:hidden">
                    {Object.keys(selectedSquares).length} squares
                  </div>
                </div>
              </div>
            </div>

            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />

            <div>
              <div
                id="content3"
                className="px-5 flex flex-col items-center flex-1"
              >
                <SquaresTable
                  onSquareClick={onSquareClick}
                  userInitials={getUserInitials(initials)}
                  selectedSquares={{ ...selectedSquares, ...squareSquares }}
                  user={user}
                  teamLeftName={superBowlInfo.homeLabel ?? 'TBD'}
                  teamTopName={superBowlInfo.awayLabel ?? 'TBD'}
                  leftRowIndexes={superBowlInfo.homeIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  topRowIndexes={superBowlInfo.awayIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  quarter={quarter}
                  winners={[
                    superBowlInfo.winners?.Q1 ?? null,
                    superBowlInfo.winners?.Q2 ?? null,
                    superBowlInfo.winners?.Q3 ?? null,
                    superBowlInfo.winners?.Q4 ?? null
                  ]}
                />
              </div>
            </div>

            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />
          </div>
        </div>
      )}
      <SuperBowlStripeModal
        isOpen={isPaymentModalOpen}
        onConfirm={handleConfirm}
        onClose={() => setPaymentModalOpen(false)}
      />

      <PaymentMethodModal
        opened={addPaymentMethod}
        onClose={() => {
          setAddPaymentMethod(false)
          setPaymentModalOpen(true)
        }}
        onContinue={() => {
          setAddPaymentMethod(false)
          setAddCostModal(true)
        }}
      />

      <SquaresCostModal
        opened={addCostModal}
        onClose={() => setAddCostModal(false)}
        circleCode={circleCode}
        onContinue={() => {
          setAddCostModal(false)
          setPaymentModalOpen(true)
        }}
      />
    </Base>
  )
}

export default SquaresChoose
