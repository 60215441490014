import BabyShower1 from '../assets/images/eventDefault/babyShower1.png'
import BabyShower2 from '../assets/images/eventDefault/babyShower2.png'
import BabyShower3 from '../assets/images/eventDefault/babyShower3.png'
import CocktailParty1 from '../assets/images/eventDefault/cocktailParty1.png'
import CocktailParty2 from '../assets/images/eventDefault/cocktailParty2.png'
import CocktailParty3 from '../assets/images/eventDefault/cocktailParty3.png'
import BirthdayParty1 from '../assets/images/eventDefault/birthday1.png'
import BirthdayParty2 from '../assets/images/eventDefault/birthday2.png'
import BirthdayParty3 from '../assets/images/eventDefault/birthday3.png'
import BirthdayParty4 from '../assets/images/eventDefault/birthday4.png'
import BirthdayParty5 from '../assets/images/eventDefault/birthday5.png'
import BirthdayParty6 from '../assets/images/eventDefault/birthday6.png'
import BirthdayParty7 from '../assets/images/eventDefault/birthday7.png'
import BirthdayParty8 from '../assets/images/eventDefault/birthday8.png'
import BirthdayParty9 from '../assets/images/eventDefault/birthday9.png'
import BirthdayParty10 from '../assets/images/eventDefault/birthday10.png'
import BirthdayParty11 from '../assets/images/eventDefault/birthday11.png'
import BirthdayParty12 from '../assets/images/eventDefault/birthday12.png'
import BirthdayParty13 from '../assets/images/eventDefault/birthday13.png'
import SummerBBQ1 from '../assets/images/eventDefault/summerBBQ1.png'
import SummerBBQ2 from '../assets/images/eventDefault/summerBBQ2.png'
import SummerBBQ3 from '../assets/images/eventDefault/summerBBQ3.png'
import PotluckParty1 from '../assets/images/eventDefault/potluckParty1.png'
import PotluckParty2 from '../assets/images/eventDefault/potluckParty2.png'
import PotluckParty3 from '../assets/images/eventDefault/potluckParty3.png'
import DinnerParty1 from '../assets/images/eventDefault/dinnerParty1.png'
import DinnerParty2 from '../assets/images/eventDefault/dinnerParty2.png'
import DinnerParty3 from '../assets/images/eventDefault/dinnerParty3.png'
import FamilyReunion1 from '../assets/images/eventDefault/familyReunion1.png'
import FamilyReunion2 from '../assets/images/eventDefault/familyReunion2.png'
import FamilyReunion3 from '../assets/images/eventDefault/familyReunion3.png'
import BridalShower1 from '../assets/images/eventDefault/bridalShower1.png'
import BridalShower2 from '../assets/images/eventDefault/bridalShower2.png'
import BridalShower3 from '../assets/images/eventDefault/bridalShower3.png'
import FourthJuly1 from '../assets/images/eventDefault/fourthJuly1.png'
import FourthJuly2 from '../assets/images/eventDefault/fourthJuly2.png'
import FourthJuly3 from '../assets/images/eventDefault/fourthJuly3.png'
import FourthJuly4 from '../assets/images/eventDefault/fourthJuly4.png'
import FourthJuly5 from '../assets/images/eventDefault/fourthJuly5.png'
import SimpleParty1 from '../assets/images/eventDefault/simpleParty1.png'
import SimpleParty2 from '../assets/images/eventDefault/simpleParty2.png'
import SimpleParty3 from '../assets/images/eventDefault/simpleParty3.png'
import SimpleParty4 from '../assets/images/eventDefault/simpleParty4.png'
import SimpleParty5 from '../assets/images/eventDefault/simpleParty5.png'
import Hanukkah1 from '../assets/images/eventDefault/hanukkah/hanukkah-1.png'
import Hanukkah2 from '../assets/images/eventDefault/hanukkah/hanukkah-2.png'
import Hanukkah3 from '../assets/images/eventDefault/hanukkah/hanukkah-3.png'
import Hanukkah4 from '../assets/images/eventDefault/hanukkah/hanukkah-4.png'
import Hanukkah5 from '../assets/images/eventDefault/hanukkah/hanukkah-5.png'
import NewYear1 from '../assets/images/eventDefault/new-year/new-year-1.png'
import NewYear2 from '../assets/images/eventDefault/new-year/new-year-2.png'
import NewYear3 from '../assets/images/eventDefault/new-year/new-year-3.png'
import NewYear4 from '../assets/images/eventDefault/new-year/new-year-4.png'
import NewYear5 from '../assets/images/eventDefault/new-year/new-year-5.png'
import Christmas1 from '../assets/images/eventDefault/christmas/christmas-1.png'
import Christmas2 from '../assets/images/eventDefault/christmas/christmas-2.png'
import Christmas3 from '../assets/images/eventDefault/christmas/christmas-3.png'
import Christmas4 from '../assets/images/eventDefault/christmas/christmas-4.png'
import Christmas5 from '../assets/images/eventDefault/christmas/christmas-5.png'
import Christmas6 from '../assets/images/eventDefault/christmas/christmas-6.png'
import Holiday1 from '../assets/images/eventDefault/holiday/holiday-1.png'
import Holiday2 from '../assets/images/eventDefault/holiday/holiday-2.png'
import Holiday3 from '../assets/images/eventDefault/holiday/holiday-3.png'
import Holiday4 from '../assets/images/eventDefault/holiday/holiday-4.png'
import Holiday5 from '../assets/images/eventDefault/holiday/holiday-5.png'
import Holiday6 from '../assets/images/eventDefault/holiday/holiday-6.png'
import Holiday7 from '../assets/images/eventDefault/holiday/holiday-7.png'
import Thanksgiving1 from '../assets/images/eventDefault/thanksgiving/thanksgiving-1.png'
import Thanksgiving2 from '../assets/images/eventDefault/thanksgiving/thanksgiving-2.png'
import Thanksgiving3 from '../assets/images/eventDefault/thanksgiving/thanksgiving-3.png'
import Thanksgiving4 from '../assets/images/eventDefault/thanksgiving/thanksgiving-4.png'
import Thanksgiving5 from '../assets/images/eventDefault/thanksgiving/thanksgiving-5.png'
import SuperBowl1 from '../assets/images/eventDefault/super-bowl/super-bowl-1.png'
import SuperBowl2 from '../assets/images/eventDefault/super-bowl/super-bowl-2.png'
import SuperBowl3 from '../assets/images/eventDefault/super-bowl/super-bowl-3.png'
import SuperBowl4 from '../assets/images/eventDefault/super-bowl/super-bowl-4.png'
import SuperBowl5 from '../assets/images/eventDefault/super-bowl/super-bowl-5.png'
import SuperBowl6 from '../assets/images/eventDefault/super-bowl/super-bowl-6.png'
import Halloween1 from '../assets/images/eventDefault/halloween/halloween-1.png'
import Halloween2 from '../assets/images/eventDefault/halloween/halloween-2.png'
import Halloween3 from '../assets/images/eventDefault/halloween/halloween-3.png'
import Halloween4 from '../assets/images/eventDefault/halloween/halloween-4.png'
import Halloween5 from '../assets/images/eventDefault/halloween/halloween-5.png'
import Halloween6 from '../assets/images/eventDefault/halloween/halloween-6.png'
import DiaMuertos1 from '../assets/images/eventDefault/dia-muertos/dia-muertos-1.png'
import DiaMuertos2 from '../assets/images/eventDefault/dia-muertos/dia-muertos-2.png'
import DiaMuertos3 from '../assets/images/eventDefault/dia-muertos/dia-muertos-3.png'
import DiaMuertos4 from '../assets/images/eventDefault/dia-muertos/dia-muertos-4.png'
import DiaMuertos5 from '../assets/images/eventDefault/dia-muertos/dia-muertos-5.png'
import General1 from '../assets/images/eventDefault/general/general1.png'
import General2 from '../assets/images/eventDefault/general/general2.png'
import General3 from '../assets/images/eventDefault/general/general3.png'
import General4 from '../assets/images/eventDefault/general/general4.png'
import General5 from '../assets/images/eventDefault/general/general5.png'
import General6 from '../assets/images/eventDefault/general/general6.png'

export const DEFAULT_COVER =
  'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty1.png?alt=media&token=3a740f5c-fd01-448f-bc6b-363faeffc7bf'

export const categories = [
  {
    label: 'All Purpose',
    value: 'all-purpose',
    images: [General1, General2, General3, General4, General5, General6],
    urls: [ 
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral1.png?alt=media&token=c4190e68-7909-4294-8713-8bc237ff868a',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral2.png?alt=media&token=28445a10-2844-4e3c-84a1-5976fa91a8ab',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral3.png?alt=media&token=00769e68-6e22-44aa-91c2-efb756e302c2',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral4.png?alt=media&token=e10d6170-c393-4de5-b8b0-541907e3ccdb',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral5.png?alt=media&token=deae3b1d-5adf-46b6-a5cb-7a8bf6bf619d',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral6.png?alt=media&token=5b360f43-24eb-4047-9aee-12491c7dc7ce' 
    ]
  },
  {
    label: 'Cocktail parties',
    value: 'cocktail-parties',
    images: [CocktailParty1, CocktailParty2, CocktailParty3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FcocktailParty1.png?alt=media&token=36b20cfe-a796-4fbf-a046-7336a6ddbb83',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FcocktailParty2.png?alt=media&token=9dcc8175-56d7-4ecd-aa2a-fd00fe75964e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FcocktailParty3.png?alt=media&token=e995c1c0-856e-4b5b-9ea8-d5353d060169'
    ]
  },
  {
    label: 'Hanukkah',
    value: 'hanukkah',
    images: [Hanukkah1, Hanukkah2, Hanukkah3, Hanukkah4, Hanukkah5],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhanukkah-1.png?alt=media&token=dec3400a-05ee-454f-b16c-8171c0f20e59',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhanukkah-2.png?alt=media&token=65f75ca5-5187-4172-b596-f5238fda49bc',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhanukkah-3.png?alt=media&token=6909169b-e11e-45b7-a63f-9790c02b6441',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhanukkah-4.png?alt=media&token=d0ce34a4-e058-4e2a-8ccb-c1b866bbe724',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhanukkah-5.png?alt=media&token=ade21973-2de3-4a12-befe-a1d87caa0aed'
    ]
  },
  {
    label: "New Year's Eve",
    value: 'new-year',
    images: [NewYear1, NewYear2, NewYear3, NewYear4, NewYear5],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fnew-year-1.png?alt=media&token=0a2fec31-a5aa-4de2-8ccd-3d305126b5c1',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fnew-year-2.png?alt=media&token=fc7d780a-3190-46c3-aaeb-ab53c4eaa7dd',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fnew-year-3.png?alt=media&token=8f937174-7d5d-4e43-b1b4-85069cdade28',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fnew-year-4.png?alt=media&token=bca6b853-fd7a-47dc-827b-7dbb91704044',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fnew-year-5.png?alt=media&token=d5620db1-c894-460a-8471-7e03b9e0fad8'
    ]
  },
  {
    label: 'Christmas',
    value: 'christmas',
    images: [
      Christmas1,
      Christmas2,
      Christmas3,
      Christmas4,
      Christmas5,
      Christmas6
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-1.png?alt=media&token=c87bfa18-d225-4991-80b3-a8f990627e9c',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-2.png?alt=media&token=10b1bc83-7045-45ac-a723-039c776aa172',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-3.png?alt=media&token=3cf917af-fda1-4957-8cf7-83f5ca0e6a0b',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-4.png?alt=media&token=fcf07774-9cef-4fea-8cd8-7a60c5e67458',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-5.png?alt=media&token=e51cdfa1-942f-468f-9eb7-79854b96d4a1',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fchristmas-6.png?alt=media&token=55e870e3-294e-408d-915b-10834fe26822'
    ]
  },
  {
    label: 'Holiday Party',
    value: 'holiday',
    images: [
      Holiday1,
      Holiday2,
      Holiday3,
      Holiday4,
      Holiday5,
      Holiday6,
      Holiday7
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-1.png?alt=media&token=65c9310b-bb1a-474c-98c2-6ad3604b13ab',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-2.png?alt=media&token=1fcd41e7-84ef-4f05-9dea-5630025a1ee3',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-3.png?alt=media&token=a7b11fd0-0ee1-4aea-b3e1-24219ab3e2f8',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-4.png?alt=media&token=3bdce70a-8a8a-47bd-bc6b-01f2a058f1a2',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-5.png?alt=media&token=156acf95-7aa8-494d-bdfb-e253c1d3ec62',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-6.png?alt=media&token=10fa5c91-0e14-40c8-a27a-a3f7fbf716cf',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fholiday-7.png?alt=media&token=c9e846a5-252a-4d6f-a114-c0a2a8b7de0e'
    ]
  },
  {
    label: 'Thanksgiving',
    value: 'thanksgiving',
    images: [
      Thanksgiving1,
      Thanksgiving2,
      Thanksgiving3,
      Thanksgiving4,
      Thanksgiving5
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fthanksgiving-1.png?alt=media&token=b4a224f0-dc38-4ab5-88ec-a0c2e0d528a3',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fthanksgiving-2.png?alt=media&token=145bd633-d4f7-4577-be56-72eb15ec1200',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fthanksgiving-3.png?alt=media&token=ddefb861-2a0f-4800-ab13-23b7fbf3698d',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fthanksgiving-4.png?alt=media&token=8fdd9f2b-52d1-4fbe-a073-06efbdad4482',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fthanksgiving-5.png?alt=media&token=c8de7012-9ce7-4c46-bfe6-0e3bbb61e1c3'
    ]
  },
  {
    label: 'Super Bowl',
    value: 'super-bowl',
    images: [
      SuperBowl6,
      SuperBowl1,
      SuperBowl2,
      SuperBowl3,
      SuperBowl4,
      SuperBowl5
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-6.png?alt=media&token=9de8f028-eaca-4461-a989-03e189346f74',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-1.png?alt=media&token=f1d7a357-0ce2-4f9d-9b7b-ba4d8ffb9c0b',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-2.png?alt=media&token=5b78ca4f-390c-4280-b046-a3cbb5520bda',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-3.png?alt=media&token=3535019f-c081-46d7-a4ab-57103f53418e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-4.png?alt=media&token=82144091-831a-45ac-ab8e-29469614a164',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fsuper-bowl-5.png?alt=media&token=1109fa30-58ac-4e12-af5a-c3669573d122'
    ]
  },
  {
    label: 'Halloween parties',
    value: 'halloween',
    images: [
      Halloween1,
      Halloween2,
      Halloween3,
      Halloween4,
      Halloween5,
      Halloween6
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-1.png?alt=media&token=18e8c07a-b9dd-428e-9192-935753e87761',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-2.png?alt=media&token=f5c60162-ae17-4a76-92d4-2f1f200f7c0c',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-3.png?alt=media&token=e7f49ce6-e3c7-4687-bc12-e42372625038',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-4.png?alt=media&token=7ae4cff9-ce78-4656-858b-a13ce7f6488e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-5.png?alt=media&token=a9212b45-9e9c-4418-a3ba-a154ebf128e5',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fhalloween-6.png?alt=media&token=725fcefb-47d1-437b-ab73-37475412deaf'
    ]
  },
  {
    label: 'Día de Muertos',
    value: 'dia-de-muertos',
    images: [DiaMuertos1, DiaMuertos2, DiaMuertos3, DiaMuertos4, DiaMuertos5],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fdia-muertos-1.png?alt=media&token=04c2e175-3a00-4348-9642-273fca70758e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fdia-muertos-2.png?alt=media&token=f16fd480-429b-4b33-af81-d8aed9763270',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fdia-muertos-3.png?alt=media&token=154640dc-0b78-4ec6-a082-a2edebc81527',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fdia-muertos-4.png?alt=media&token=55b78c12-27b9-47f3-ac5e-2604c4a43c14',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fdia-muertos-5.png?alt=media&token=f6890a9b-44fb-48ef-913f-171912efe71c'
    ]
  },
  {
    label: 'Birthday parties',
    value: 'birthday-parties',
    images: [
      General6,
      BirthdayParty1,
      BirthdayParty2,
      BirthdayParty3,
      BirthdayParty4,
      BirthdayParty5,
      BirthdayParty6,      
      BirthdayParty7,
      BirthdayParty8,
      BirthdayParty9,
      BirthdayParty10,
      BirthdayParty11,
      BirthdayParty12,
      BirthdayParty13,
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fgeneral6.png?alt=media&token=5b360f43-24eb-4047-9aee-12491c7dc7ce',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday1.png?alt=media&token=8a517ea4-959b-4a0a-b3a6-3a0a7cb3cf1b',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday2.png?alt=media&token=c6e01e2e-7244-4a90-8fb8-c4c958dda624',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday3.png?alt=media&token=e83a1c0d-dccc-46b2-85dc-750812be16f5',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday4.png?alt=media&token=c055d764-7971-46bb-a193-4cd0b38c6947',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday5.png?alt=media&token=44b5904a-dc29-4a83-aebc-3959dcb2c57c',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday6.png?alt=media&token=abce88a4-0f4b-425c-884c-c1ff37b83794',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday7.png?alt=media&token=2f9d8aa5-938c-44c5-bee8-af4f9b682763',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday8.png?alt=media&token=09580e43-5848-40ee-9139-4a3fcd18f0e7',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday9.png?alt=media&token=7e3c18aa-302e-4739-8dae-2a426c14773b',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday10.png?alt=media&token=f1cfd525-217b-4437-a160-e9a1aa6de06b',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday11.png?alt=media&token=110b514d-4294-43f8-90a8-9926b512624e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday12.png?alt=media&token=6d74fd1f-f2da-414f-81a3-9322c6a003d4',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2Fbirthday13.png?alt=media&token=8928a6e7-577f-4efb-b926-b3e23d80f80b'
    ]
  },
  {
    label: 'Summer BBQ',
    value: 'summer-bbq',
    images: [SummerBBQ1, SummerBBQ2, SummerBBQ3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsummerBBQ1.png?alt=media&token=0373f6f5-6193-450e-b5af-094129190135',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsummerBBQ2.png?alt=media&token=f9f42341-c9e1-4d39-948b-a4a26b176354',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsummerBBQ3.png?alt=media&token=06d4bc38-be28-4428-ad9d-d5b2bd28d785'
    ]
  },
  {
    label: 'Potlucks',
    value: 'potlucks',
    images: [PotluckParty1, PotluckParty2, PotluckParty3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FpotluckParty1.png?alt=media&token=a42a41b9-8d33-4f4b-a8de-fc1083d0620a',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FpotluckParty2.png?alt=media&token=45bdc066-5744-4ecd-8523-145affea6eb6',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FpotluckParty3.png?alt=media&token=a792bfe2-f3f6-471a-a7ba-d3cb9ec73073'
    ]
  },
  {
    label: 'Baby Showers',
    value: 'baby-showers',
    images: [BabyShower1, BabyShower2, BabyShower3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbabyShower1.png?alt=media&token=78145dd6-6b40-45cd-be77-676d9773ebb7',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbabyShower1.png?alt=media&token=78145dd6-6b40-45cd-be77-676d9773ebb7',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbabyShower1.png?alt=media&token=78145dd6-6b40-45cd-be77-676d9773ebb7'
    ]
  },
  {
    label: 'Bridal Showers',
    value: 'bridal-showers',
    images: [BridalShower1, BridalShower2, BridalShower3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbridalShower1.png?alt=media&token=7320ff39-fb35-41a5-8a47-44b29e50a577',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbridalShower1.png?alt=media&token=7320ff39-fb35-41a5-8a47-44b29e50a577',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FbridalShower3.png?alt=media&token=2631b166-7c53-442a-9a16-2d46f4102f38'
    ]
  },
  {
    label: '4th of July',
    value: 'fourth-of-july',
    images: [FourthJuly1, FourthJuly2, FourthJuly3, FourthJuly4, FourthJuly5],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfourthJuly1.png?alt=media&token=7ca51847-921b-4c96-8432-7efe1f024abb',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfourthJuly2.png?alt=media&token=d188ef8f-56e8-4f24-a9c4-43928dc2c0ea',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfourthJuly3.png?alt=media&token=d24cdd71-862f-40e8-9284-29dc5c946f0e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfourthJuly4.png?alt=media&token=6a0910b9-78e7-46c5-b662-8f5c2d2d6b34',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfourthJuly5.png?alt=media&token=596ccdfa-d917-4fd4-a021-17d5c6f5cd41'
    ]
  },
  {
    label: 'Simple designs',
    value: 'simple-designs',
    images: [
      SimpleParty1,
      SimpleParty2,
      SimpleParty3,
      SimpleParty4,
      SimpleParty5
    ],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty1.png?alt=media&token=3a740f5c-fd01-448f-bc6b-363faeffc7bf',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty2.png?alt=media&token=26be05aa-a003-480c-aac8-19ad75460732',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty3.png?alt=media&token=37d8f165-f588-4136-9414-4a8c4fb5402e',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty4.png?alt=media&token=04ecbe28-786d-4be3-85be-55c6cd802d74',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FsimpleParty5.png?alt=media&token=20d1a2af-e9f8-4554-a3dc-19b606cbb591'
    ]
  },
  {
    label: 'Dinner party',
    value: 'dinner-party',
    images: [DinnerParty1, DinnerParty2, DinnerParty3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FdinnerParty1.png?alt=media&token=479d0c43-2f71-474c-b5b7-2e57c8f28acf',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FdinnerParty2.png?alt=media&token=7706b45a-65a0-4997-b5cf-2f95ce55412a',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FdinnerParty3.png?alt=media&token=81cffc0e-fe7b-44d0-b343-e9440ffc4da3'
    ]
  },
  {
    label: 'Family reunion',
    value: 'family-reunion',
    images: [FamilyReunion1, FamilyReunion2, FamilyReunion3],
    urls: [
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfamilyReunion1.png?alt=media&token=009903dc-0bbf-4c06-a4d3-5ee5523d61fa',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfamilyReunion2.png?alt=media&token=83bc9ecf-9dcb-4a86-b98d-4a21a624b80a',
      'https://firebasestorage.googleapis.com/v0/b/coteri.appspot.com/o/stock%2FfamilyReunion3.png?alt=media&token=4d558942-dec3-4985-8714-9bf26f760a04'
    ]
  },
  {
    label: 'Other',
    value: 'other',
    urls: ['']
  }
]
