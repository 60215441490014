import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare'
import { useToast } from '@chakra-ui/react'
import { database } from '../../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserInitials } from '../../../utils/square'
import DottedLine from '../../../components/DottedLine/DottedLine'
import { useObject } from 'react-firebase-hooks/database'
import { ref, remove, update } from 'firebase/database'
import SquaresTable, {
  findQuarter
} from '../../../components/Squares/SquaresTable/SquaresTable'
import { sendEmail } from '../../../services/Support'
import Button from '../../../components/Button/Button'
import RSVPEventDetailsCostModal from '../../../components/RSVPEventDetails/RSVPEventDetailsCostModal/RSVPEventDetailsCostModal'

const SquaresGuestChooseView = ({ step, hasMusic, hasRequestList }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { circleCode, rsvpId } = useParams()
  const [initials, setInitials] = useState('')
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedSquares, setSelectedSquares] = useState({})
  const [paymentModal, setPaymentModal] = useState(false)

  const [superBowlSnap, superBowlLoading] = useObject(
    ref(database, `superBowl`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const [squareSquaresSnap, squareSquaresLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )

  const eventInfo = eventSnap?.val()
  const rsvpInfo = rsvpSnap?.val()
  const superBowlInfo = useMemo(
    () => superBowlSnap?.val() ?? {},
    [superBowlSnap]
  )
  const squareSquares = useMemo(
    () => squareSquaresSnap?.val() ?? {},
    [squareSquaresSnap]
  )
  const myInitials = useMemo(() => {
    const key = Object.keys(squareSquares).find(
      (key) => squareSquares[key].uid === rsvpId
    )
    return key ? squareSquares[key]?.initials ?? '' : ''
  }, [squareSquares, rsvpId])

  const isGameDisabled = useMemo(
    () =>
      superBowlInfo.startTime <= new Date().getTime() / 1000 &&
      Object.keys(squareSquares).length < 100,
    [squareSquares, superBowlInfo]
  )

  const quarter = useMemo(
    () => findQuarter(superBowlInfo.currentPeriod),
    [superBowlInfo]
  )

  const handleContinue = async () => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Check the fields',
        position: 'top'
      })
      setHasError(true)
      return
    }
    setLoading(true)

    await Promise.all([
      update(ref(database, `circles/${circleCode}/squares`), selectedSquares),
      update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        squares: true
      }),
      update(ref(database, `subscribers/GAME_STARTED/${rsvpId}`), {
        rsvpId,
        code: circleCode,
        email: rsvpInfo.email ?? null,
        phone: rsvpInfo.phone ?? null
      })
    ])

    if (
      !Object.keys(squareSquares).some(
        (key) => squareSquares[key].uid === rsvpId
      )
    ) {
      await sendEmail('JOINED_GAME', {
        rsvpId,
        code: circleCode,
        email: rsvpInfo.email ?? null,
        phone: rsvpInfo.phone ?? null
      })
    }

    if (Object.keys(selectedSquares).length >= 100) {
      await remove(ref(database, `subscribers/EMPTY_SQUARES/${circleCode}`))
    }

    if (!!step) {
      if (!!hasRequestList) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=list`)
      } else if (!!hasMusic) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`)
      } else {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      }
    } else {
      navigate(`/event/${circleCode}/rsvp/${rsvpId}/squares/summary`)
    }
  }

  const onCancel = () => {
    if (!!step) {
      if (!!hasRequestList) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=list`)
      } else if (!!hasMusic) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`)
      } else {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      }
    } else {
      navigate(-1)
    }
  }

  const onChangeText = (value) => {
    if (value?.length > 19) {
      return
    }

    if (hasError && value?.length > 0) {
      setHasError(false)
    }

    if (value === '' || value?.trim() === 'Your initials:') {
      setInitials('')
      return
    }

    if (!value?.startsWith('Your initials:')) {
      value = `Your initials: ${value.toUpperCase()}`
    } else {
      const userInput = value.slice(14).trim()
      value = `Your initials: ${userInput.toUpperCase()}`
    }

    setInitials(value)
  }

  const handleModalClose = () => {
    setPaymentModal(false)

    localStorage.setItem('paymentModalDismissed', 'true')
  }

  const handleChangeAlreadySelectSquares = useCallback(async () => {
    if (!rsvpId) return

    const updatedSquares = Object.keys(selectedSquares).reduce((acc, key) => {
      const square = selectedSquares[key]
      if (square.uid === rsvpId) {
        acc[key] = {
          ...square,
          initials: getUserInitials(initials)
        }
      } else {
        acc[key] = square
      }
      return acc
    }, {})

    setSelectedSquares(updatedSquares)
  }, [initials, selectedSquares, rsvpId])

  const onSquareClick = (row, col) => {
    if (!initials) {
      toast({
        status: 'error',
        title: 'Enter your initials to begin',
        position: 'top'
      })
      setHasError(true)
      return
    }

    const initialsHasBeenTaken = Object.keys(squareSquares).find(
      (key) =>
        squareSquares[key].initials?.toLowerCase() ===
          getUserInitials(initials).toLowerCase() &&
        squareSquares[key].uid !== rsvpId
    )
    if (initialsHasBeenTaken) {
      toast({
        status: 'error',
        title: 'Initials already taken',
        position: 'top'
      })
      return
    }

    const squareKey = `${row}${col}`

    const prevSelectedSquare = squareSquares[`${row}${col}`]
    if (!!prevSelectedSquare && prevSelectedSquare.uid === rsvpId) {
      toast({
        status: 'error',
        title: 'Square has been already selected',
        position: 'top'
      })
      return
    }

    if (!!selectedSquares[squareKey]) {
      const _selectedSquares = { ...selectedSquares }
      delete _selectedSquares[squareKey]
      setSelectedSquares(_selectedSquares)
    } else {
      setSelectedSquares({
        ...selectedSquares,
        [squareKey]: {
          name: rsvpInfo.name,
          uid: rsvpId,
          initials: getUserInitials(initials)
        }
      })
    }
  }

  useEffect(() => {
    if (!squareSquaresLoading && !!myInitials) {
      setInitials(`Your initials: ${myInitials}`)
    }
  }, [squareSquaresLoading, myInitials])

  useEffect(() => {
    handleChangeAlreadySelectSquares()
  }, [initials])

  useEffect(() => {
    const modalDismissed = localStorage.getItem('paymentModalDismissed')

    if (!modalDismissed) {
      setPaymentModal(true)
    }
  }, [])

  if (
    loading ||
    eventLoading ||
    squareSquaresLoading ||
    rsvpLoading ||
    superBowlLoading
  ) {
    return (
      <div className="flex justify-center items-center flex-1">
        <ClipLoader />
      </div>
    )
  }

  if (isGameDisabled) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 w-full">
        <h2 className="text-primary text-[20px]">Game Closed</h2>
        <p className="text-[14px]">
          Not all squares were claimed prior to game start
        </p>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className="flex relative flex-1 mt-[-20px]">
          <div id="content" className="w-full">
            <div className="w-full px-5 mt-10">
              <h2 className="text-primary text-[20px]">
                Choose your squares by clicking on them below.
              </h2>
              <p className="text-[14px]">
                Teams and corresponding numbers will appear once all squares are
                filled in.
              </p>
              <div className="mt-3 flex items-center justify-between flex-col sm:flex-row">
                <div className="flex items-center gap-3">
                  <SquaresSquare
                    placeholder={'Enter your initials to begin'}
                    wrapperInputClassName={`w-auto px-2 min-w-[200px] bg-white`}
                    inputProps={{ fontSize: 14 }}
                    inputClassName={'placeholder:text-[14px] bg-white'}
                    value={initials}
                    onChangeText={onChangeText}
                    removeOnChangeValidation
                    error={hasError}
                  />
                  <div className="hidden sm:flex">
                    {Object.keys(selectedSquares).length} squares
                  </div>
                </div>

                <div className="flex flex-col items-center gap-5">
                  <div className="flex items-center sm:mt-0 mt-4">
                    <div className="w-8 h-8 rounded-sm border-[2px] border-primary mr-3" />
                    <span className="pointer-events-none text-[12px]">
                      You own this square
                    </span>
                  </div>
                  <div className="flex sm:hidden">
                    {Object.keys(selectedSquares).length} squares
                  </div>
                </div>
              </div>
            </div>
            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />
            <div>
              <div
                id="content3"
                className="px-5 flex flex-col items-center flex-1"
              >
                <SquaresTable
                  onSquareClick={onSquareClick}
                  userInitials={getUserInitials(initials)}
                  selectedSquares={{ ...selectedSquares, ...squareSquares }}
                  rsvpId={rsvpId}
                  teamLeftName={superBowlInfo.homeLabel ?? 'TBD'}
                  teamTopName={superBowlInfo.awayLabel ?? 'TBD'}
                  leftRowIndexes={superBowlInfo.homeIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  topRowIndexes={superBowlInfo.awayIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  quarter={quarter}
                  winners={[
                    superBowlInfo.winners?.Q1 ?? null,
                    superBowlInfo.winners?.Q2 ?? null,
                    superBowlInfo.winners?.Q3 ?? null,
                    superBowlInfo.winners?.Q4 ?? null
                  ]}
                />
              </div>
            </div>
            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />
          </div>
        </div>
        <div className="h-20 flex items-center justify-between relative">
          <Button
            type={'text'}
            text={!!step ? 'Skip' : 'Cancel'}
            onClick={onCancel}
            className={'text-black'}
          />
          <Button text={'Continue'} onClick={handleContinue} />
        </div>
      </div>

      <RSVPEventDetailsCostModal
        isOpen={paymentModal}
        eventInfo={eventInfo}
        onClose={handleModalClose}
      />
    </>
  )
}

export default SquaresGuestChooseView
