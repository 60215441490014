export const selectedSquares = {
  '01': {
    col: 1,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '04': {
    col: 4,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '09': {
    col: 9,
    initials: 'MR',
    row: 0,
    uid: 'user5'
  },
  12: {
    col: 2,
    initials: 'YOU',
    row: 1,
    uid: 'me'
  },
  17: {
    col: 7,
    initials: 'VJO',
    row: 1,
    uid: 'user4'
  },
  25: {
    col: 5,
    initials: 'JRP',
    row: 2,
    uid: 'user1'
  },
  28: {
    col: 8,
    initials: 'VJO',
    row: 2,
    uid: 'user4'
  },
  33: {
    col: 3,
    initials: 'JRP',
    row: 3,
    uid: 'user1'
  },
  36: {
    col: 6,
    initials: 'YOU',
    row: 3,
    uid: 'me'
  },
  39: {
    col: 9,
    initials: 'VJO',
    row: 3,
    uid: 'user4'
  },
  41: {
    col: 1,
    initials: 'JRP',
    row: 4,
    uid: 'user1'
  },
  47: {
    col: 7,
    initials: 'MB',
    row: 4,
    uid: 'user2'
  },
  50: {
    col: 0,
    initials: 'JRP',
    row: 5,
    uid: 'user1'
  },
  56: {
    col: 6,
    initials: 'EM',
    row: 5,
    uid: 'user3'
  },
  62: {
    col: 2,
    initials: 'MB',
    row: 6,
    uid: 'user2'
  },
  65: {
    col: 5,
    initials: 'MR',
    row: 6,
    uid: 'user5'
  },
  74: {
    col: 4,
    initials: 'MB',
    row: 7,
    uid: 'user2'
  },
  76: {
    col: 6,
    initials: 'MR',
    row: 7,
    uid: 'user5'
  },
  83: {
    col: 3,
    initials: 'EM',
    row: 8,
    uid: 'user3'
  },
  91: {
    col: 1,
    initials: 'EM',
    row: 9,
    uid: 'user3'
  }
}

export const allSquares = {
  '00': {
    col: 0,
    initials: 'HIJ',
    row: 0,
    uid: 'user6'
  },
  '01': {
    col: 1,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '02': {
    col: 2,
    initials: 'FGH',
    row: 0,
    uid: 'user14'
  },
  '03': {
    col: 3,
    initials: 'RST',
    row: 0,
    uid: 'user18'
  },
  '04': {
    col: 4,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '05': {
    col: 5,
    initials: 'JRP',
    row: 0,
    uid: 'user20'
  },
  '06': {
    col: 6,
    initials: 'STU',
    row: 0,
    uid: 'user27'
  },
  '07': {
    col: 7,
    initials: 'VJOL',
    row: 0,
    uid: 'user24'
  },
  '08': {
    col: 8,
    initials: 'WXY',
    row: 0,
    uid: 'user11'
  },
  '09': {
    col: 9,
    initials: 'MR',
    row: 0,
    uid: 'user5'
  },
  10: {
    col: 0,
    initials: 'JRP',
    row: 1,
    uid: 'user20'
  },
  11: {
    col: 1,
    initials: 'HIJ',
    row: 1,
    uid: 'user6'
  },
  12: {
    col: 2,
    initials: 'YOU',
    row: 1,
    uid: 'me'
  },
  13: {
    col: 3,
    initials: 'UVW',
    row: 1,
    uid: 'user19'
  },
  14: {
    col: 4,
    initials: 'ZAB',
    row: 1,
    uid: 'user12'
  },
  15: {
    col: 5,
    initials: 'GHI',
    row: 1,
    uid: 'user23'
  },
  16: {
    col: 6,
    initials: 'QRS',
    row: 1,
    uid: 'user9'
  },
  17: {
    col: 7,
    initials: 'VJO',
    row: 1,
    uid: 'user4'
  },
  18: {
    col: 8,
    initials: 'STU',
    row: 1,
    uid: 'user27'
  },
  19: {
    col: 9,
    initials: 'IJO',
    row: 1,
    uid: 'user15'
  },
  20: {
    col: 0,
    initials: 'HIJ',
    row: 2,
    uid: 'user6'
  },
  21: {
    col: 1,
    initials: 'IJO',
    row: 2,
    uid: 'user15'
  },
  22: {
    col: 2,
    initials: 'WXY',
    row: 2,
    uid: 'user11'
  },
  23: {
    col: 3,
    initials: 'OPQ',
    row: 2,
    uid: 'user17'
  },
  24: {
    col: 4,
    initials: 'CDE',
    row: 2,
    uid: 'user13'
  },
  25: {
    col: 5,
    initials: 'JRP',
    row: 2,
    uid: 'user1'
  },
  26: {
    col: 6,
    initials: 'NOP',
    row: 2,
    uid: 'user8'
  },
  27: {
    col: 7,
    initials: 'PQR',
    row: 2,
    uid: 'user26'
  },
  28: {
    col: 8,
    initials: 'VJO',
    row: 2,
    uid: 'user4'
  },
  29: {
    col: 9,
    initials: 'GHI',
    row: 2,
    uid: 'user23'
  },
  30: {
    col: 0,
    initials: 'FGH',
    row: 3,
    uid: 'user14'
  },
  31: {
    col: 1,
    initials: 'GHI',
    row: 3,
    uid: 'user23'
  },
  32: {
    col: 2,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  33: {
    col: 3,
    initials: 'JRP',
    row: 3,
    uid: 'user1'
  },
  34: {
    col: 4,
    initials: 'NOP',
    row: 3,
    uid: 'user8'
  },
  35: {
    col: 5,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  36: {
    col: 6,
    initials: 'YOU',
    row: 3,
    uid: 'me'
  },
  37: {
    col: 7,
    initials: 'UVW',
    row: 3,
    uid: 'user19'
  },
  38: {
    col: 8,
    initials: 'TUV',
    row: 3,
    uid: 'user10'
  },
  39: {
    col: 9,
    initials: 'PQR',
    row: 3,
    uid: 'user26'
  },
  40: {
    col: 0,
    initials: 'HIJ',
    row: 4,
    uid: 'user6'
  },
  41: {
    col: 1,
    initials: 'JRP',
    row: 4,
    uid: 'user1'
  },
  42: {
    col: 2,
    initials: 'PQR',
    row: 4,
    uid: 'user26'
  },
  43: {
    col: 3,
    initials: 'OPQ',
    row: 4,
    uid: 'user17'
  },
  44: {
    col: 4,
    initials: 'JRP',
    row: 4,
    uid: 'user20'
  },
  45: {
    col: 5,
    initials: 'TUV',
    row: 4,
    uid: 'user10'
  },
  46: {
    col: 6,
    initials: 'MR',
    row: 4,
    uid: 'user22'
  },
  47: {
    col: 7,
    initials: 'MB',
    row: 4,
    uid: 'user2'
  },
  48: {
    col: 8,
    initials: 'FGH',
    row: 4,
    uid: 'user14'
  },
  49: {
    col: 9,
    initials: 'ZAB',
    row: 4,
    uid: 'user12'
  },
  50: {
    col: 0,
    initials: 'JRP',
    row: 5,
    uid: 'user1'
  },
  51: {
    col: 1,
    initials: 'TUV',
    row: 5,
    uid: 'user10'
  },
  52: {
    col: 2,
    initials: 'KLM',
    row: 5,
    uid: 'user7'
  },
  53: {
    col: 3,
    initials: 'RST',
    row: 5,
    uid: 'user18'
  },
  54: {
    col: 4,
    initials: 'MNO',
    row: 5,
    uid: 'user25'
  },
  55: {
    col: 5,
    initials: 'MR',
    row: 5,
    uid: 'user22'
  },
  56: {
    col: 6,
    initials: 'EM',
    row: 5,
    uid: 'user3'
  },
  57: {
    col: 7,
    initials: 'IJO',
    row: 5,
    uid: 'user15'
  },
  58: {
    col: 8,
    initials: 'CDE',
    row: 5,
    uid: 'user13'
  },
  59: {
    col: 9,
    initials: 'NOP',
    row: 5,
    uid: 'user8'
  },
  60: {
    col: 0,
    initials: 'MB',
    row: 6,
    uid: 'user16'
  },
  61: {
    col: 1,
    initials: 'KLM',
    row: 6,
    uid: 'user7'
  },
  62: {
    col: 2,
    initials: 'MB',
    row: 6,
    uid: 'user2'
  },
  63: {
    col: 3,
    initials: 'ZAB',
    row: 6,
    uid: 'user12'
  },
  64: {
    col: 4,
    initials: 'MR',
    row: 6,
    uid: 'user22'
  },
  65: {
    col: 5,
    initials: 'MR',
    row: 6,
    uid: 'user5'
  },
  66: {
    col: 6,
    initials: 'JRP',
    row: 6,
    uid: 'user20'
  },
  67: {
    col: 7,
    initials: 'QRS',
    row: 6,
    uid: 'user9'
  },
  68: {
    col: 8,
    initials: 'OPQ',
    row: 6,
    uid: 'user17'
  },
  69: {
    col: 9,
    initials: 'MNO',
    row: 6,
    uid: 'user25'
  },
  70: {
    col: 0,
    initials: 'UVW',
    row: 7,
    uid: 'user19'
  },
  71: {
    col: 1,
    initials: 'WXY',
    row: 7,
    uid: 'user11'
  },
  72: {
    col: 2,
    initials: 'CDE',
    row: 7,
    uid: 'user13'
  },
  73: {
    col: 3,
    initials: 'RST',
    row: 7,
    uid: 'user18'
  },
  74: {
    col: 4,
    initials: 'MB',
    row: 7,
    uid: 'user2'
  },
  75: {
    col: 5,
    initials: 'MNO',
    row: 7,
    uid: 'user25'
  },
  76: {
    col: 6,
    initials: 'MR',
    row: 7,
    uid: 'user5'
  },
  77: {
    col: 7,
    initials: 'IJO',
    row: 7,
    uid: 'user15'
  },
  78: {
    col: 8,
    initials: 'KLM',
    row: 7,
    uid: 'user7'
  },
  79: {
    col: 9,
    initials: 'ABC',
    row: 7,
    uid: 'user21'
  },
  80: {
    col: 0,
    initials: 'CDE',
    row: 8,
    uid: 'user13'
  },
  81: {
    col: 1,
    initials: 'ABC',
    row: 8,
    uid: 'user21'
  },
  82: {
    col: 2,
    initials: 'KLM',
    row: 8,
    uid: 'user7'
  },
  83: {
    col: 3,
    initials: 'EM',
    row: 8,
    uid: 'user3'
  },
  84: {
    col: 4,
    initials: 'RST',
    row: 8,
    uid: 'user18'
  },
  85: {
    col: 5,
    initials: 'MB',
    row: 8,
    uid: 'user16'
  },
  86: {
    col: 6,
    initials: 'QRS',
    row: 8,
    uid: 'user9'
  },
  87: {
    col: 7,
    initials: 'WXY',
    row: 8,
    uid: 'user11'
  },
  88: {
    col: 8,
    initials: 'VJOL',
    row: 8,
    uid: 'user24'
  },
  89: {
    col: 9,
    initials: 'STU',
    row: 8,
    uid: 'user27'
  },
  90: {
    col: 0,
    initials: 'TUV',
    row: 9,
    uid: 'user10'
  },
  91: {
    col: 1,
    initials: 'EM',
    row: 9,
    uid: 'user3'
  },
  92: {
    col: 2,
    initials: 'STU',
    row: 9,
    uid: 'user27'
  },
  93: {
    col: 3,
    initials: 'NOP',
    row: 9,
    uid: 'user8'
  },
  94: {
    col: 4,
    initials: 'ABC',
    row: 9,
    uid: 'user21'
  },
  95: {
    col: 5,
    initials: 'VJOL',
    row: 9,
    uid: 'user24'
  },
  96: {
    col: 6,
    initials: 'OPQ',
    row: 9,
    uid: 'user17'
  },
  97: {
    col: 7,
    initials: 'FGH',
    row: 9,
    uid: 'user14'
  },
  98: {
    col: 8,
    initials: 'ZAB',
    row: 9,
    uid: 'user12'
  },
  99: {
    col: 9,
    initials: 'UVW',
    row: 9,
    uid: 'user19'
  }
}

export const selectedSquaresWithWinner = {
  '00': {
    col: 0,
    initials: 'HIJ',
    row: 0,
    uid: 'user6'
  },
  '01': {
    col: 1,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '02': {
    col: 2,
    initials: 'FGH',
    row: 0,
    uid: 'user14'
  },
  '03': {
    col: 3,
    initials: 'RST',
    row: 0,
    uid: 'user18'
  },
  '04': {
    col: 4,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '05': {
    col: 5,
    initials: 'JRP',
    row: 0,
    uid: 'user20'
  },
  '06': {
    col: 6,
    initials: 'STU',
    row: 0,
    uid: 'user27'
  },
  '07': {
    col: 7,
    initials: 'VJOL',
    row: 0,
    uid: 'user24'
  },
  '08': {
    col: 8,
    initials: 'WXY',
    row: 0,
    uid: 'user11'
  },
  '09': {
    col: 9,
    initials: 'MR',
    row: 0,
    uid: 'user5'
  },
  10: {
    col: 0,
    initials: 'JRP',
    row: 1,
    uid: 'user20'
  },
  11: {
    col: 1,
    initials: 'HIJ',
    row: 1,
    uid: 'user6'
  },
  12: {
    col: 2,
    initials: 'YOU',
    row: 1,
    uid: 'me'
  },
  13: {
    col: 3,
    initials: 'UVW',
    row: 1,
    uid: 'user19'
  },
  14: {
    col: 4,
    initials: 'ZAB',
    row: 1,
    uid: 'user12'
  },
  15: {
    col: 5,
    initials: 'GHI',
    row: 1,
    uid: 'user23'
  },
  16: {
    col: 6,
    initials: 'QRS',
    row: 1,
    uid: 'user9'
  },
  17: {
    col: 7,
    initials: 'VJO',
    row: 1,
    uid: 'user4'
  },
  18: {
    col: 8,
    initials: 'STU',
    row: 1,
    uid: 'user27'
  },
  19: {
    col: 9,
    initials: 'IJO',
    row: 1,
    uid: 'user15'
  },
  20: {
    col: 0,
    initials: 'HIJ',
    row: 2,
    uid: 'user6'
  },
  21: {
    col: 1,
    initials: 'IJO',
    row: 2,
    uid: 'user15'
  },
  22: {
    col: 2,
    initials: 'WXY',
    row: 2,
    uid: 'user11'
  },
  23: {
    col: 3,
    initials: 'OPQ',
    row: 2,
    uid: 'user17'
  },
  24: {
    col: 4,
    initials: 'CDE',
    row: 2,
    uid: 'user13'
  },
  25: {
    col: 5,
    initials: 'JRP',
    row: 2,
    uid: 'user1'
  },
  26: {
    col: 6,
    initials: 'NOP',
    row: 2,
    uid: 'user8'
  },
  27: {
    col: 7,
    initials: 'PQR',
    row: 2,
    uid: 'user26'
  },
  28: {
    col: 8,
    initials: 'VJO',
    row: 2,
    uid: 'user4'
  },
  29: {
    col: 9,
    initials: 'GHI',
    row: 2,
    uid: 'user23'
  },
  30: {
    col: 0,
    initials: 'FGH',
    row: 3,
    uid: 'user14'
  },
  31: {
    col: 1,
    initials: 'GHI',
    row: 3,
    uid: 'user23'
  },
  32: {
    col: 2,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  33: {
    col: 3,
    initials: 'JRP',
    row: 3,
    uid: 'user1'
  },
  34: {
    col: 4,
    initials: 'NOP',
    row: 3,
    uid: 'user8'
  },
  35: {
    col: 5,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  36: {
    col: 6,
    initials: 'YOU',
    row: 3,
    uid: 'me'
  },
  37: {
    col: 7,
    initials: 'UVW',
    row: 3,
    uid: 'user19'
  },
  38: {
    col: 8,
    initials: 'TUV',
    row: 3,
    uid: 'user10'
  },
  39: {
    col: 9,
    initials: 'PQR',
    row: 3,
    uid: 'user26'
  },
  40: {
    col: 0,
    initials: 'HIJ',
    row: 4,
    uid: 'user6'
  },
  41: {
    col: 1,
    initials: 'JRP',
    row: 4,
    uid: 'user1'
  },
  42: {
    col: 2,
    initials: 'PQR',
    row: 4,
    uid: 'user26'
  },
  43: {
    col: 3,
    initials: 'OPQ',
    row: 4,
    uid: 'user17'
  },
  44: {
    col: 4,
    initials: 'JRP',
    row: 4,
    uid: 'user20'
  },
  45: {
    col: 5,
    initials: 'TUV',
    row: 4,
    uid: 'user10'
  },
  46: {
    col: 6,
    initials: 'MR',
    row: 4,
    uid: 'user22'
  },
  47: {
    col: 7,
    initials: 'MB',
    row: 4,
    uid: 'user2'
  },
  48: {
    col: 8,
    initials: 'FGH',
    row: 4,
    uid: 'user14'
  },
  49: {
    col: 9,
    initials: 'ZAB',
    row: 4,
    uid: 'user12'
  },
  50: {
    col: 0,
    initials: 'JRP',
    row: 5,
    uid: 'user1'
  },
  51: {
    col: 1,
    initials: 'TUV',
    row: 5,
    uid: 'user10'
  },
  52: {
    col: 2,
    initials: 'KLM',
    row: 5,
    uid: 'user7'
  },
  53: {
    col: 3,
    initials: 'RST',
    row: 5,
    uid: 'user18'
  },
  54: {
    col: 4,
    initials: 'MNO',
    row: 5,
    uid: 'user25'
  },
  55: {
    col: 5,
    initials: 'MR',
    row: 5,
    uid: 'user22'
  },
  56: {
    col: 6,
    initials: 'EM',
    row: 5,
    uid: 'user3'
  },
  57: {
    col: 7,
    initials: 'IJO',
    row: 5,
    uid: 'user15'
  },
  58: {
    col: 8,
    initials: 'CDE',
    row: 5,
    uid: 'user13'
  },
  59: {
    col: 9,
    initials: 'NOP',
    row: 5,
    uid: 'user8'
  },
  60: {
    col: 0,
    initials: 'MB',
    row: 6,
    uid: 'user16'
  },
  61: {
    col: 1,
    initials: 'KLM',
    row: 6,
    uid: 'user7'
  },
  62: {
    col: 2,
    initials: 'MB',
    row: 6,
    uid: 'user2'
  },
  63: {
    col: 3,
    initials: 'ZAB',
    row: 6,
    uid: 'user12'
  },
  64: {
    col: 4,
    initials: 'MR',
    row: 6,
    uid: 'user22'
  },
  65: {
    col: 5,
    initials: 'MR',
    row: 6,
    uid: 'user5'
  },
  66: {
    col: 6,
    initials: 'JRP',
    row: 6,
    uid: 'user20'
  },
  67: {
    col: 7,
    initials: 'QRS',
    row: 6,
    uid: 'user9'
  },
  68: {
    col: 8,
    initials: 'OPQ',
    row: 6,
    uid: 'user17'
  },
  69: {
    col: 9,
    initials: 'MNO',
    row: 6,
    uid: 'user25'
  },
  70: {
    col: 0,
    initials: 'UVW',
    row: 7,
    uid: 'user19'
  },
  71: {
    col: 1,
    initials: 'WXY',
    row: 7,
    uid: 'user11'
  },
  72: {
    col: 2,
    initials: 'CDE',
    row: 7,
    uid: 'user13'
  },
  73: {
    col: 3,
    initials: 'RST',
    row: 7,
    uid: 'user18'
  },
  74: {
    col: 4,
    initials: 'MB',
    row: 7,
    uid: 'user2'
  },
  75: {
    col: 5,
    initials: 'MNO',
    row: 7,
    uid: 'user25'
  },
  76: {
    col: 6,
    initials: 'MR',
    row: 7,
    uid: 'user5'
  },
  77: {
    col: 7,
    initials: 'IJO',
    row: 7,
    uid: 'user15'
  },
  78: {
    col: 8,
    initials: 'KLM',
    row: 7,
    uid: 'user7',
    winner: '1st'
  },
  79: {
    col: 9,
    initials: 'ABC',
    row: 7,
    uid: 'user21'
  },
  80: {
    col: 0,
    initials: 'CDE',
    row: 8,
    uid: 'user13'
  },
  81: {
    col: 1,
    initials: 'ABC',
    row: 8,
    uid: 'user21'
  },
  82: {
    col: 2,
    initials: 'KLM',
    row: 8,
    uid: 'user7'
  },
  83: {
    col: 3,
    initials: 'EM',
    row: 8,
    uid: 'user3'
  },
  84: {
    col: 4,
    initials: 'RST',
    row: 8,
    uid: 'user18'
  },
  85: {
    col: 5,
    initials: 'MB',
    row: 8,
    uid: 'user16'
  },
  86: {
    col: 6,
    initials: 'QRS',
    row: 8,
    uid: 'user9'
  },
  87: {
    col: 7,
    initials: 'WXY',
    row: 8,
    uid: 'user11'
  },
  88: {
    col: 8,
    initials: 'VJOL',
    row: 8,
    uid: 'user24'
  },
  89: {
    col: 9,
    initials: 'STU',
    row: 8,
    uid: 'user27'
  },
  90: {
    col: 0,
    initials: 'TUV',
    row: 9,
    uid: 'user10'
  },
  91: {
    col: 1,
    initials: 'EM',
    row: 9,
    uid: 'user3'
  },
  92: {
    col: 2,
    initials: 'STU',
    row: 9,
    uid: 'user27'
  },
  93: {
    col: 3,
    initials: 'NOP',
    row: 9,
    uid: 'user8'
  },
  94: {
    col: 4,
    initials: 'ABC',
    row: 9,
    uid: 'user21'
  },
  95: {
    col: 5,
    initials: 'VJOL',
    row: 9,
    uid: 'user24'
  },
  96: {
    col: 6,
    initials: 'OPQ',
    row: 9,
    uid: 'user17'
  },
  97: {
    col: 7,
    initials: 'FGH',
    row: 9,
    uid: 'user14'
  },
  98: {
    col: 8,
    initials: 'ZAB',
    row: 9,
    uid: 'user12'
  },
  99: {
    col: 9,
    initials: 'UVW',
    row: 9,
    uid: 'user19'
  }
}

export const selectedSquaresWithContender = {
  '00': {
    col: 0,
    initials: 'HIJ',
    row: 0,
    uid: 'user6'
  },
  '01': {
    col: 1,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '02': {
    col: 2,
    initials: 'FGH',
    row: 0,
    uid: 'user14'
  },
  '03': {
    col: 3,
    initials: 'RST',
    row: 0,
    uid: 'user18'
  },
  '04': {
    col: 4,
    initials: 'YOU',
    row: 0,
    uid: 'me'
  },
  '05': {
    col: 5,
    initials: 'JRP',
    row: 0,
    uid: 'user20'
  },
  '06': {
    col: 6,
    initials: 'STU',
    row: 0,
    uid: 'user27'
  },
  '07': {
    col: 7,
    initials: 'VJOL',
    row: 0,
    uid: 'user24'
  },
  '08': {
    col: 8,
    initials: 'WXY',
    row: 0,
    uid: 'user11'
  },
  '09': {
    col: 9,
    initials: 'MR',
    row: 0,
    uid: 'user5'
  },
  10: {
    col: 0,
    initials: 'JRP',
    row: 1,
    uid: 'user20'
  },
  11: {
    col: 1,
    initials: 'HIJ',
    isContender: true,
    row: 1,
    uid: 'user6'
  },
  12: {
    col: 2,
    initials: 'YOU',
    row: 1,
    uid: 'me'
  },
  13: {
    col: 3,
    initials: 'UVW',
    row: 1,
    uid: 'user19'
  },
  14: {
    col: 4,
    initials: 'ZAB',
    row: 1,
    uid: 'user12'
  },
  15: {
    col: 5,
    initials: 'GHI',
    row: 1,
    uid: 'user23'
  },
  16: {
    col: 6,
    initials: 'QRS',
    row: 1,
    uid: 'user9'
  },
  17: {
    col: 7,
    initials: 'VJO',
    row: 1,
    uid: 'user4'
  },
  18: {
    col: 8,
    initials: 'STU',
    row: 1,
    uid: 'user27'
  },
  19: {
    col: 9,
    initials: 'IJO',
    row: 1,
    uid: 'user15'
  },
  20: {
    col: 0,
    initials: 'HIJ',
    row: 2,
    uid: 'user6'
  },
  21: {
    col: 1,
    initials: 'IJO',
    row: 2,
    uid: 'user15'
  },
  22: {
    col: 2,
    initials: 'WXY',
    row: 2,
    uid: 'user11'
  },
  23: {
    col: 3,
    initials: 'OPQ',
    row: 2,
    uid: 'user17'
  },
  24: {
    col: 4,
    initials: 'CDE',
    row: 2,
    uid: 'user13'
  },
  25: {
    col: 5,
    initials: 'JRP',
    row: 2,
    uid: 'user1'
  },
  26: {
    col: 6,
    initials: 'NOP',
    row: 2,
    uid: 'user8'
  },
  27: {
    col: 7,
    initials: 'PQR',
    row: 2,
    uid: 'user26'
  },
  28: {
    col: 8,
    initials: 'VJO',
    row: 2,
    uid: 'user4'
  },
  29: {
    col: 9,
    initials: 'GHI',
    row: 2,
    uid: 'user23'
  },
  30: {
    col: 0,
    initials: 'FGH',
    row: 3,
    uid: 'user14'
  },
  31: {
    col: 1,
    initials: 'GHI',
    row: 3,
    uid: 'user23'
  },
  32: {
    col: 2,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  33: {
    col: 3,
    initials: 'JRP',
    row: 3,
    uid: 'user1'
  },
  34: {
    col: 4,
    initials: 'NOP',
    row: 3,
    uid: 'user8'
  },
  35: {
    col: 5,
    initials: 'MB',
    row: 3,
    uid: 'user16'
  },
  36: {
    col: 6,
    initials: 'YOU',
    row: 3,
    uid: 'me'
  },
  37: {
    col: 7,
    initials: 'UVW',
    row: 3,
    uid: 'user19'
  },
  38: {
    col: 8,
    initials: 'TUV',
    row: 3,
    uid: 'user10'
  },
  39: {
    col: 9,
    initials: 'PQR',
    row: 3,
    uid: 'user26'
  },
  40: {
    col: 0,
    initials: 'HIJ',
    row: 4,
    uid: 'user6'
  },
  41: {
    col: 1,
    initials: 'JRP',
    row: 4,
    uid: 'user1'
  },
  42: {
    col: 2,
    initials: 'PQR',
    row: 4,
    uid: 'user26'
  },
  43: {
    col: 3,
    initials: 'OPQ',
    row: 4,
    uid: 'user17'
  },
  44: {
    col: 4,
    initials: 'JRP',
    row: 4,
    uid: 'user20'
  },
  45: {
    col: 5,
    initials: 'TUV',
    row: 4,
    uid: 'user10'
  },
  46: {
    col: 6,
    initials: 'MR',
    row: 4,
    uid: 'user22'
  },
  47: {
    col: 7,
    initials: 'MB',
    row: 4,
    uid: 'user2'
  },
  48: {
    col: 8,
    initials: 'FGH',
    row: 4,
    uid: 'user14'
  },
  49: {
    col: 9,
    initials: 'ZAB',
    row: 4,
    uid: 'user12'
  },
  50: {
    col: 0,
    initials: 'JRP',
    row: 5,
    uid: 'user1'
  },
  51: {
    col: 1,
    initials: 'TUV',
    row: 5,
    uid: 'user10'
  },
  52: {
    col: 2,
    initials: 'KLM',
    row: 5,
    uid: 'user7'
  },
  53: {
    col: 3,
    initials: 'RST',
    row: 5,
    uid: 'user18'
  },
  54: {
    col: 4,
    initials: 'MNO',
    row: 5,
    uid: 'user25'
  },
  55: {
    col: 5,
    initials: 'MR',
    row: 5,
    uid: 'user22'
  },
  56: {
    col: 6,
    initials: 'EM',
    row: 5,
    uid: 'user3'
  },
  57: {
    col: 7,
    initials: 'IJO',
    row: 5,
    uid: 'user15'
  },
  58: {
    col: 8,
    initials: 'CDE',
    row: 5,
    uid: 'user13'
  },
  59: {
    col: 9,
    initials: 'NOP',
    row: 5,
    uid: 'user8'
  },
  60: {
    col: 0,
    initials: 'MB',
    row: 6,
    uid: 'user16'
  },
  61: {
    col: 1,
    initials: 'KLM',
    row: 6,
    uid: 'user7'
  },
  62: {
    col: 2,
    initials: 'MB',
    row: 6,
    uid: 'user2'
  },
  63: {
    col: 3,
    initials: 'ZAB',
    row: 6,
    uid: 'user12'
  },
  64: {
    col: 4,
    initials: 'MR',
    row: 6,
    uid: 'user22'
  },
  65: {
    col: 5,
    initials: 'MR',
    row: 6,
    uid: 'user5'
  },
  66: {
    col: 6,
    initials: 'JRP',
    row: 6,
    uid: 'user20'
  },
  67: {
    col: 7,
    initials: 'QRS',
    row: 6,
    uid: 'user9'
  },
  68: {
    col: 8,
    initials: 'OPQ',
    row: 6,
    uid: 'user17'
  },
  69: {
    col: 9,
    initials: 'MNO',
    row: 6,
    uid: 'user25'
  },
  70: {
    col: 0,
    initials: 'UVW',
    row: 7,
    uid: 'user19'
  },
  71: {
    col: 1,
    initials: 'WXY',
    row: 7,
    uid: 'user11'
  },
  72: {
    col: 2,
    initials: 'CDE',
    row: 7,
    uid: 'user13'
  },
  73: {
    col: 3,
    initials: 'RST',
    row: 7,
    uid: 'user18'
  },
  74: {
    col: 4,
    initials: 'MB',
    row: 7,
    uid: 'user2'
  },
  75: {
    col: 5,
    initials: 'MNO',
    row: 7,
    uid: 'user25'
  },
  76: {
    col: 6,
    initials: 'MR',
    row: 7,
    uid: 'user5'
  },
  77: {
    col: 7,
    initials: 'IJO',
    row: 7,
    uid: 'user15'
  },
  78: {
    col: 8,
    initials: 'KLM',
    row: 7,
    uid: 'user7',
    winner: '1st'
  },
  79: {
    col: 9,
    initials: 'ABC',
    row: 7,
    uid: 'user21'
  },
  80: {
    col: 0,
    initials: 'CDE',
    row: 8,
    uid: 'user13'
  },
  81: {
    col: 1,
    initials: 'ABC',
    row: 8,
    uid: 'user21'
  },
  82: {
    col: 2,
    initials: 'KLM',
    row: 8,
    uid: 'user7'
  },
  83: {
    col: 3,
    initials: 'EM',
    row: 8,
    uid: 'user3'
  },
  84: {
    col: 4,
    initials: 'RST',
    row: 8,
    uid: 'user18'
  },
  85: {
    col: 5,
    initials: 'MB',
    row: 8,
    uid: 'user16'
  },
  86: {
    col: 6,
    initials: 'QRS',
    row: 8,
    uid: 'user9'
  },
  87: {
    col: 7,
    initials: 'WXY',
    row: 8,
    uid: 'user11'
  },
  88: {
    col: 8,
    initials: 'VJOL',
    row: 8,
    uid: 'user24'
  },
  89: {
    col: 9,
    initials: 'STU',
    row: 8,
    uid: 'user27'
  },
  90: {
    col: 0,
    initials: 'TUV',
    row: 9,
    uid: 'user10'
  },
  91: {
    col: 1,
    initials: 'EM',
    row: 9,
    uid: 'user3'
  },
  92: {
    col: 2,
    initials: 'STU',
    row: 9,
    uid: 'user27'
  },
  93: {
    col: 3,
    initials: 'NOP',
    row: 9,
    uid: 'user8'
  },
  94: {
    col: 4,
    initials: 'ABC',
    row: 9,
    uid: 'user21'
  },
  95: {
    col: 5,
    initials: 'VJOL',
    row: 9,
    uid: 'user24'
  },
  96: {
    col: 6,
    initials: 'OPQ',
    row: 9,
    uid: 'user17'
  },
  97: {
    col: 7,
    initials: 'FGH',
    row: 9,
    uid: 'user14'
  },
  98: {
    col: 8,
    initials: 'ZAB',
    row: 9,
    uid: 'user12'
  },
  99: {
    col: 9,
    initials: 'UVW',
    row: 9,
    uid: 'user19'
  }
}
