import React, { useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'
import Button from '../../Button/Button'
import { StripeIcon } from '../../../assets/icons'

const SuperBowlStripeModal = ({ isOpen, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    onConfirm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={'500px'} mx={4} p={0}>
        <ModalBody p={0}>
          <>
            <div className="flex flex-col items-center px-5">
              <StripeIcon width={120} height={60} />
              <p>
                To proceed with hosting the Super Bowl Squares game, you'll be redirected to Stripe to complete a secure payment.
              </p>
            </div>

            <div className="w-full h-[1px] bg-[#80808033] my-3" />

            <div className="flex flex-col items-center px-2">
              <p className="text-center text-primary text-[20px]">$10</p>

              <span className="px-3 text-primary w-full">
                One-time payment
              </span>
            </div>

            <div className="w-full h-[1px] bg-[#80808033] my-3" />

            <div className="flex flex-col items-center px-5">
              <p>
                After completing your secure payment with Stripe, you'll be automatically redirected back to our website and your Super Bowl Squares game will be created.
              </p>
            </div>

            <div className="flex items-center justify-between px-10 my-5">
              <Button
                type={'text'}
                text={'Close'}
                onClick={onClose}
                size={'small'}
              />
              <Button
                text={'Continue'}
                size={'small'}
                onClick={onSubmit}
                loading={loading}
              />
            </div>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SuperBowlStripeModal
