import React, { memo, useEffect, useMemo, useState } from 'react'
import { Star } from '@mui/icons-material'
import { useToast } from '@chakra-ui/react'

const SquaresItem = memo(
  ({
    rowIndex,
    colIndex,
    squareSize,
    onSquareClick,
    selectedSquares,
    currentUser,
    disableClick,
    showWinners,
    quarter = 1,
    winners = [null, null, null, null]
  }) => {
    const toast = useToast()
    const [isVisible, setIsVisible] = useState(true)

    const square = useMemo(
      () => selectedSquares[`${rowIndex}${colIndex}`],
      [colIndex, rowIndex, selectedSquares]
    )

    const {
      isMySquare,
      isContender,
      is1stWinner,
      is2ndWinner,
      is3rdWinner,
      is4thWinner
    } = useMemo(
      () => ({
        isMySquare: square?.uid === currentUser,
        isContender:
          (!!showWinners &&
            quarter <= 4 &&
            winners[quarter - 1] === `${rowIndex}${colIndex}`) ||
          square?.isContender,
        is1stWinner:
          !!showWinners &&
          quarter > 1 &&
          winners[0] === `${rowIndex}${colIndex}`,
        is2ndWinner:
          !!showWinners &&
          quarter > 2 &&
          winners[1] === `${rowIndex}${colIndex}`,
        is3rdWinner:
          !!showWinners &&
          quarter > 3 &&
          winners[2] === `${rowIndex}${colIndex}`,
        is4thWinner:
          !!showWinners &&
          quarter > 4 &&
          winners[3] === `${rowIndex}${colIndex}`
      }),
      [
        colIndex,
        currentUser,
        showWinners,
        quarter,
        rowIndex,
        square?.uid,
        winners,
        square?.isContender
      ]
    )

    const handleSquareClick = (row, col) => {
      if (disableClick) {
        return
      }

      const prevSelectedSquare = selectedSquares[`${row}${col}`]
      if (!!prevSelectedSquare && prevSelectedSquare.uid !== currentUser) {
        toast({
          status: 'error',
          title: 'This square is already selected',
          position: 'top'
        })

        return
      }

      onSquareClick(row, col)
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        setIsVisible((prevState) => !prevState)
      }, 1000)
      return () => clearInterval(intervalId)
    }, [])

    return (
      <div
        className={`rounded-sm border-[1px] border-black relative ${disableClick ? 'cursor-default' : 'cursor-pointer'} flex items-center justify-center ${is1stWinner || is2ndWinner || is3rdWinner || is4thWinner ? (isMySquare ? ' bg-blue' : ' bg-white') : isContender ? (isMySquare ? 'border-[2px] border-square-primary bg-light-blue-2' : 'border-[2px] border-light-blue-2 bg-light-blue-2') : isMySquare ? 'border-[3px] border-square-primary' : !disableClick && !!square ? 'bg-gray' : ''}`}
        onClick={() => handleSquareClick(rowIndex, colIndex)}
        style={{
          width: squareSize,
          height: squareSize
        }}
      >
        <div className="flex flex-wrap gap-1 z-50 top-0 absolute right-0">
          {is1stWinner && (
            <div className="w-6 h-6 bg-light-blue-2 rounded-full flex items-center justify-center transform translate-x-1/2 -translate-y-1/2 text-[10px]">
              1st
            </div>
          )}
          {is2ndWinner && (
            <div className="w-6 h-6 bg-light-blue-2 rounded-full flex items-center justify-center transform translate-x-1/2 -translate-y-1/2 text-[10px]">
              2nd
            </div>
          )}
          {is3rdWinner && (
            <div className="w-6 h-6 bg-light-blue-2 rounded-full flex items-center justify-center transform translate-x-1/2 -translate-y-1/2 text-[10px]">
              3rd
            </div>
          )}
          {is4thWinner && (
            <div className="w-6 h-6 bg-light-blue-2 rounded-full flex items-center justify-center transform translate-x-1/2 -translate-y-1/2 text-[10px]">
              4th
            </div>
          )}
        </div>
        {(is1stWinner || is2ndWinner || is3rdWinner || is4thWinner) &&
        isVisible ? (
          <Star
            style={{ color: isMySquare ? 'white' : 'blue' }}
            fontSize="large"
          />
        ) : (
          <span
            className={
              is1stWinner || is2ndWinner || is3rdWinner || is4thWinner
                ? `text-white ${square?.initials?.length >= 4 ? 'text-[7px]' : 'text-[8px]'} sm-custom:text-[14px]`
                : `text-black ${square?.initials?.length >= 4 ? 'text-[7px]' : 'text-[8px]'} sm-custom:text-[14px]`
            }
          >
            {square ? square.initials : ''}
          </span>
        )}
      </div>
    )
  }
)

SquaresItem.displayName = 'SquaresItem'

export default SquaresItem
