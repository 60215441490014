import React, { useEffect, useMemo, useState } from 'react'
import Base from '../../base'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Button from '../../../components/Button/Button'
import SquaresSquare from '../../../components/Squares/SquaresSquare/SquaresSquare'
import SquaresTable, {
  findQuarter
} from '../../../components/Squares/SquaresTable/SquaresTable'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase'
import SquaresSummaryModal from '../../../components/Squares/SquaresSummaryModal/SquaresSummaryModal'
import DottedLine from '../../../components/DottedLine/DottedLine'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import { database } from '../../../firebase'
import { ChevronLeft, Star } from '@mui/icons-material'
import { getPaymentData, removePaymentData } from '../../../services/database'
import { useToast } from '@chakra-ui/react'

const SquaresSummary = () => {
  const { circleCode } = useParams()
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const toast = useToast()
  const [summaryModal, setSummaryModal] = useState(false)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [superBowlSnap, superBowlLoading] = useObject(
    ref(database, `superBowl`)
  )
  const [squareSnap, squareLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )

  const eventInfo = eventSnap?.val()
  const superBowlInfo = useMemo(
    () => superBowlSnap?.val() ?? {},
    [superBowlSnap]
  )
  const squareSquares = useMemo(() => squareSnap?.val() ?? {}, [squareSnap])
  const myInitials = useMemo(() => {
    const key = Object.keys(squareSquares).find(
      (key) => squareSquares[key].uid === user?.uid
    )
    return key ? squareSquares[key]?.initials ?? '' : ''
  }, [squareSquares, user?.uid])

  const isGameDisabled = useMemo(
    () =>
      superBowlInfo.startTime <= new Date().getTime() / 1000 &&
      Object.keys(squareSquares).length < 100,
    [squareSquares, superBowlInfo]
  )

  const allSquaresPurchased = useMemo(
    () => Object.keys(squareSquares).length === 100,
    [squareSquares]
  )

  const quarter = useMemo(
    () => findQuarter(superBowlInfo.currentPeriod),
    [superBowlInfo]
  )

  const winners = useMemo(
    () => [
      superBowlInfo.winners?.Q1 ?? null,
      superBowlInfo.winners?.Q2 ?? null,
      superBowlInfo.winners?.Q3 ?? null,
      superBowlInfo.winners?.Q4 ?? null
    ],
    [superBowlInfo]
  )

  const isContender = useMemo(() => {
    if (quarter === 5) return false
    const contenderSquare = winners[quarter - 1]
    if (!!contenderSquare) {
      const square = squareSquares[contenderSquare]
      return !!square && square.uid === user?.uid
    }
    return false
  }, [quarter, squareSquares, winners, user?.uid])

  const handleCancel = () => {
    navigate(`/event/${circleCode}`)
  }

  const handlePurchaseMoreSquares = () => {
    navigate(`/event/${circleCode}/squares/choose`)
  }

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }

    if (!eventLoading && !loading && !superBowlLoading) {
      if (!eventInfo || !eventInfo.squaresGameId) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }

      const paymentData = getPaymentData() ?? {}
      if (paymentData.circleCode === circleCode && paymentData.gameCreated) {
        removePaymentData()
        toast({
          title: `Game has been created!`,
          position: 'top',
          status: 'success',
          isClosable: true
        })
      }
    }
  }, [
    circleCode,
    eventInfo,
    superBowlInfo,
    eventLoading,
    superBowlLoading,
    loading,
    navigate,
    toast,
    user
  ])

  return (
    <React.Fragment>
      <SquaresSummaryModal
        isOpen={summaryModal}
        onClose={() => setSummaryModal(false)}
        user={user}
        squares={squareSquares}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        removeVerticalPadding
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>

        {eventLoading || squareLoading || superBowlLoading ? (
          <div className="mt-[15px] flex flex-1 items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : isGameDisabled ? (
          <div className="flex flex-col items-center justify-center flex-1 w-full">
            <h2 className="text-primary text-[20px]">Game Closed</h2>
            <p className="text-[14px]">
              Not all squares were claimed prior to game start
            </p>
          </div>
        ) : (
          <div className="flex relative flex-1 mt-[-20px] mb-[-15px] flex-col">
            {/* <div id='content' className='px-2 sm:px-10 z-50 w-full flex flex-1 flex-col'>
              <div id='content1' className='flex items-center md:flex-row flex-col flex-1'>
                <div>
                  <div className='mt-12 flex items-start justify-start w-full sm:flex-row flex-col sm:justify-between sm:items-center'>
                    <div className='flex flex-1 items-center'>
                      <span className='text-[20px] font-semibold'>Cost per square</span>
                      <SquaresSquare value={`$${squareInfo?.costPerSquare}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none ml-2'} placeholderBefore inputProps={{ disabled: true }} />
                    </div>
                  </div>

                  <div className='mt-3 flex flex-col w-full sm:mt-1'>
                    <span className='text-[20px] font-semibold'>Payout Amounts</span>

                    <div className='flex flex-1 items-center gap-2'>
                      <SquaresSquare title={'1st'} value={`$${values.first}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'2st'} value={`$${values.second}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'3st'} value={`$${values.third}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                      <SquaresSquare title={'4st'} value={`$${values.fourth}`} inputClassName={"text-[18px] text-primary"} wrapperInputClassName={'border-none'} inputProps={{ disabled: true }} />
                    </div>
                  </div>
                </div>

                <div className={`flex flex-col flex-1 md:items-end mr-4 items-center md:mt-12 mt-5 md:ml-10 ml-0`}>
                  {hasSquaresPurchased ?
                    <span className='text-[14px] md:px-0 px-10 sm:text-start text-center'><span className='font-semibold'>Game settings locked</span> because guests have selected squares.</span>
                    :
                    <Button text={'Edit game settings'} size={'medium'} className={"mt-2 sm:mt-0"} type={'off-white'} onClick={handleGameSettings} />
                  }
                  {!allSquaresPurchased && <Button text={'Pick more squares'} size={'medium'} className={"mt-6"} onClick={handlePurchaseMoreSquares} />}
                  <span className='text-[14px] underline text-primary cursor-pointer font-semibold mt-2' onClick={() => setSummaryModal(true)}>Summary of squares picks</span>
                </div>
              </div>
            </div>

            <DottedLine color={"#80808033"} className={"my-10 h-[1px]"} horizontal solid /> */}

            <div className="flex flex-col px-2 sm:px-10 mt-10">
              <button
                className="mb-10 w-fit items-center flex hover:opacity-80 transition duration-300 ease-in-out active:opacity-50"
                onClick={handleCancel}
              >
                <ChevronLeft /> Return to event
              </button>

              <div id="content2" className="flex flex-col items-center flex-1">
                <div className="flex flex-col items-center gap-4 flex-1">
                  <div className="flex  flex-col items-center">
                    <div className="flex items-start justify-evenly w-full">
                      <div className="flex flex-col items-center flex-[0.3]">
                        <div className="w-10 h-10 rounded-sm border-[3px] border-square-primary" />
                        <span className="text-[14px] font-semibold mt-2">
                          Owner
                        </span>
                        <span className="text-[12px]">You own this square</span>
                      </div>

                      <div className="flex flex-col items-center flex-[0.3]">
                        <div className="w-10 h-10 rounded-sm border-2 border-square-secondary bg-square-secondary" />
                        <span className="text-[14px] font-semibold mt-2">
                          Contender
                        </span>
                        <span className="text-[12px] text-center">
                          This square corresponds to the current score of the
                          game.
                        </span>
                      </div>

                      <div className="flex flex-col items-center flex-[0.3]">
                        <div className="w-10 h-10 rounded-sm border-2 border-square-primary bg-square-primary flex items-center justify-center">
                          <Star style={{ color: 'white' }} />
                        </div>
                        <span className="text-[14px] font-semibold mt-2">
                          Winner
                        </span>
                        <span className="text-[12px] text-center">
                          Your square won for the corresponding quarter.
                        </span>
                        <div className="flex gap-1 mt-1">
                          <span className="py-1 rounded-full bg-square-secondary text-[11px] text-square-primary w-[26px] text-center">
                            1st
                          </span>
                          <span className="py-1 rounded-full bg-square-secondary text-[11px] text-square-primary w-[26px] text-center">
                            2nd
                          </span>
                          <span className="py-1 rounded-full bg-square-secondary text-[11px] text-square-primary w-[26px] text-center">
                            3rd
                          </span>
                          <span className="py-1 rounded-full bg-square-secondary text-[11px] text-square-primary w-[26px] text-center">
                            4th
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between w-full mt-5 md:flex-row flex-col">
                      <div>
                        <div className="flex flex-col border-1 border-primary rounded-md p-5 gap-3 min-w-[350px] max-w-[350px] mt-4">
                          <div className="flex gap-4">
                            <div className="flex-1">
                              <div className="flex items-center justify-between">
                                {superBowlInfo.homeLabel ?? 'TBD'}
                                <span className="text-[28px] font-bold">
                                  {superBowlInfo.scores?.total?.home ?? 0}
                                </span>
                              </div>
                              <div className="w-full h-2 rounded-full border-1 border-black bg-black" />
                            </div>
                            <div className="flex-1">
                              <div className="flex items-center justify-between">
                                <span className="text-[28px] font-bold">
                                  {superBowlInfo.scores?.total?.away ?? 0}
                                </span>
                                {superBowlInfo.awayLabel ?? 'TBD'}
                              </div>
                              <div className="w-full h-2 rounded-full border-1 border-black bg-white" />
                            </div>
                          </div>
                          <div className="flex-1 text-center text-[#646464]">
                            {!superBowlInfo.gameId ||
                            superBowlInfo.currentPeriod === 'Scheduled'
                              ? 'Scheduled'
                              : superBowlInfo.currentPeriod === 'Halftime' ||
                                  superBowlInfo.currentPeriod === 'halftime'
                                ? 'Half Time'
                                : superBowlInfo.status !== 'Completed'
                                  ? superBowlInfo.currentPeriod === 'Final'
                                    ? 'Final'
                                    : `${superBowlInfo.currentPeriod} quarter`
                                  : 'Final'}
                          </div>
                        </div>

                        <SquaresSquare
                          placeholder={'Enter your initial to begin'}
                          wrapperInputClassName={`w-full px-2 mt-4`}
                          inputProps={{ fontSize: 14, disabled: true }}
                          disabledProps={{ fontSize: 14 }}
                          inputClassName={'placeholder:text-[14px]'}
                          value={`Your initials: ${myInitials}`}
                          removeOnChangeValidation
                        />

                        {isContender ? (
                          <div className="w-full py-6 mt-4 bg-light-blue-2 text-blue font-bold text-center rounded-md">
                            You are the contender!
                          </div>
                        ) : null}
                      </div>

                      <div
                        className={`flex flex-col flex-1 items-end justify-between h-full md:ml-10 ml-0`}
                      >
                        {/* {hasSquaresPurchased ?
                          <span className='text-[14px] md:px-0 px-10 sm:text-start text-center'><span className='font-semibold'>Game settings locked</span> because guests have selected squares.</span>
                          :
                          <Button text={'Edit game settings'} size={'medium'} className={"mt-2 sm:mt-0"} type={'off-white'} onClick={handleGameSettings} />
                        } */}
                        {!allSquaresPurchased && (
                          <Button
                            text={'Pick more squares'}
                            size={'medium'}
                            className={'mt-6'}
                            onClick={handlePurchaseMoreSquares}
                          />
                        )}
                        <span
                          className="text-[14px] underline text-primary cursor-pointer font-semibold mt-2"
                          onClick={() => setSummaryModal(true)}
                        >
                          Summary of squares picks
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />

            <div>
              <div
                id="content3"
                className="px-5 flex flex-col items-center flex-1"
              >
                <SquaresTable
                  selectedSquares={squareSquares}
                  user={user}
                  disableClick
                  showWinners
                  showNumbers={Object.keys(squareSquares).length === 100}
                  teamLeftName={superBowlInfo.homeLabel ?? 'TBD'}
                  teamTopName={superBowlInfo.awayLabel ?? 'TBD'}
                  leftRowIndexes={superBowlInfo.homeIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  topRowIndexes={superBowlInfo.awayIndexes
                    ?.split(',')
                    .map((i) => parseInt(i))}
                  quarter={quarter}
                  winners={winners}
                />
              </div>
            </div>

            <DottedLine
              color={'#80808033'}
              className={'my-10 h-[1px]'}
              horizontal
              solid
            />
          </div>
        )}
      </Base>
    </React.Fragment>
  )
}

export default SquaresSummary
