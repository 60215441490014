import React, { memo, useMemo, useRef, useState } from 'react'
import RequestListItemModal from '../../../../components/EventDetails/RequestListItemModal/RequestListItemModal'
import RequestTabList from '../../../../components/EventDetails/RequestTabList/RequestTabList'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, database } from '../../../../firebase'
import { useToast } from '@chakra-ui/react'
import { v4 } from 'uuid'
import { ref, update } from 'firebase/database'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'
import { eventTimePassed } from '../../../../helpers'
import DeleteWhatToBringListModal from '../../../../components/DeleteWhatToBringListModal'

export const schema = yup
  .object({
    name: yup.string().required()
  })
  .required()

const RequestTab = ({
  isToggled,
  rsvpId,
  itemsInfo,
  guestsInfo,
  eventInfo,
  title,
  owner,
  rsvpResponse
}) => {
  const { circleCode } = useParams()
  const toast = useToast()
  const [user, loading] = useAuthState(auth)
  const [itemModal, setItemModal] = useState(false)
  const [deleteListModal, setDeleteListModal] = useState(false)
  const [toggleInput, setToggleInput] = useState(false)
  const itemInputRef = useRef()

  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)
  const userId = !!rsvpId ? rsvpId : user?.uid ?? ''
  const isRSVPAttending = rsvpId ? rsvpResponse === 'yes' : true

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      claimed: false
    }
  })

  const nameField = form.watch('name')

  const handleItemModalClose = () => {
    setItemModal(false)
  }

  const handleItemModalOpen = () => {
    setItemModal(true)
  }

  const handleToggleInput = () => {
    setToggleInput(true)

    // For web
    setTimeout(() => {
      form.setFocus('name')
    }, 500)
  }

  const handleAddItem = async (data) => {
    try {
      const id = v4().replace(/-/g, '')

      const newItem = {
        [id]: {
          id,
          name: data.name,
          claimed: false,
          owner: userId,
          claimedBy: userId,
          createdAt: moment().toISOString()
        }
      }

      await update(ref(database, `circles/${circleCode}/info`), {
        extraCollab: true
      })
      await update(ref(database, `circles/${circleCode}/items`), newItem)

      form.reset()

      // For web
      setTimeout(() => {
        form.setFocus('name')
      }, 500)
    } catch (err) {
      console.log('Error creating request item', err)
      toast({
        status: 'error',
        title: 'Error creating item.',
        position: 'top'
      })
    }
  }

  const handleInputBlur = () => {
    if (!nameField?.trim()) {
      setToggleInput(false)
    }
  }

  const items = useMemo(
    () =>
      !!itemsInfo ? Object.keys(itemsInfo).map((key) => itemsInfo[key]) : [],
    [itemsInfo]
  )

  return (
    <div className={`${isToggled ? 'mt-[10px] relative' : 'hidden'}`}>
      <div>
        <div id="header" className="flex flex-col mt-[10px]">
          <div className="flex flex-1 flex-col">
            <span className="text-[14px]">
              {title ||
                'Coordinate with your guests on food, drink, and more. Add items to the list for your guests to pick from.'}
            </span>
          </div>

          {isTimePassed
            ? null
            : isRSVPAttending && (
              <div className="w-full flex flex-col sm:flex-row gap-3 items-center justify-center mt-3">
                <div className="flex-1" />
                {toggleInput ? (
                  <form
                    onSubmit={form.handleSubmit(handleAddItem)}
                    className={`w-full h-10 mt-2 flex flex-1 items-center border-1 border-black rounded-md py-[2px] max-w-[370px]`}
                  >
                    <input
                      {...form.register('name')}
                      id="search-input"
                      ref={(e) => {
                        form.register('name')?.ref(e)
                        itemInputRef.current = e
                      }}
                      inputMode="text"
                      spellCheck="false"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      type="text"
                      className="w-full flex flex-1 mx-1 outline-none"
                      placeholder="Add item"
                      onBlur={handleInputBlur}
                    />

                    <button
                      type="submit"
                      className="bg-off-white text-primary rounded-md py-1 px-2 hover:text-white hover:bg-primary focus:bg-off-white focus:text-primary active:bg-primary active:text-white transition duration-300 ease-in-out mr-[2px]"
                    >
                      + Add
                    </button>
                  </form>
                ) : (
                  <button
                    onClick={handleToggleInput}
                    className={`w-full h-10 mt-2 flex border-1 border-off-white bg-off-white text-primary text-[18px] rounded-md py-[2px] items-center justify-center hover:text-white hover:bg-primary transition duration-300 ease-in-out max-w-[370px] active:opacity-70`}
                  >
                    + Add item
                  </button>
                )}
                <div className="flex flex-1 justify-center">
                  {!rsvpId ? (
                    <button
                      onClick={() => setDeleteListModal(true)}
                      className="text-black underline"
                    >
                      Delete list
                    </button>
                  ) : null}
                </div>
              </div>
            )}
        </div>

        <RequestTabList
          items={items}
          loading={false}
          rsvpId={rsvpId}
          eventInfo={eventInfo}
          guestsInfo={guestsInfo}
          owner={owner}
        />

        <RequestListItemModal
          isOpen={itemModal}
          onClose={handleItemModalClose}
          userId={userId}
        />

        {!!deleteListModal ? (
          <DeleteWhatToBringListModal
            isOpen
            onClose={() => setDeleteListModal(false)}
            circleCode={circleCode}
          />
        ) : null}
      </div>
    </div>
  )
}

export default memo(RequestTab)
