import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import * as yup from 'yup'
import { useToast } from '@chakra-ui/react'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database } from '../../firebase'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import { update } from 'firebase/database'
import ChangeResponseModal from '../../components/EventDetails/ChangeResponseModal/ChangeResponseModal'
import RSVPEventDetailsView from '../../components/RSVPEventDetailsView'
import EventCarouselView from '../../components/EventCarouselView'
import { Helmet } from 'react-helmet'
import PhoneOrEmailVerificationModal from '../../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import * as amplitude from '@amplitude/analytics-browser'
import RSVPDecisionButton from '../../components/RSVP/RSVPDecisionButton/RSVPDecisionButton'
import { eventTimePassed } from '../../helpers'
import { sendEmail } from '../../services/Support'

export const schema = yup
  .object({
    note: yup.string()
  })
  .required()

const RSVP = () => {
  const { circleCode, rsvpId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [changeResponseModal, setChangeResponseModal] = useState(false)
  const [updatingRSVP, setUpdatingRSVP] = useState(false)
  const [infoToVerify, setInfoToVerify] = useState(null)
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)

  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )

  const eventInfo = eventSnap?.val()
  const guestsInfo = guestsSnap?.val()
  const rsvpInfo = rsvpSnap?.val()
  const hasGame = !!eventInfo?.squaresGameId
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const handleCloseChangeResponseModal = useCallback(() => {
    setChangeResponseModal(false)
  }, [])

  const attendingOptions = useMemo(() => {
    return [
      {
        label: "I'm Going 🎉",
        value: 'yes'
      },
      {
        label: 'Unable to attend 🙁',
        value: 'no'
      }
    ]
  }, [])

  const handleUpdateRSVP = async (goingStatus) => {
    setUpdatingRSVP(true)

    const response = goingStatus === false ? 'no' : 'yes'

    // logEvent(analytics, 'invite_response', {
    //   source: response
    // })

    amplitude.track(
      'Invite responded to',
      {
        circleCode,
        response
      },
      {
        user_id: rsvpId
      }
    )

    try {
      await update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        response
      })
      // set(
      //   ref(database, `joinedCirclesRef/${rsvpId}/${circleCode}`),
      //   value === 'yes' ? moment().toISOString() : null
      // )
      if (!!rsvpInfo.email) {
        await sendEmail('RSVP_CONFIRM', {
          rsvpId,
          code: circleCode,
          response: response,
          email: rsvpInfo.email,
          hostEmail: eventInfo.hostEmail,
          name: eventInfo.name,
          hostName: eventInfo.hostName,
          date: eventInfo.date,
          time: eventInfo.time,
          timezone: eventInfo.timezone,
          address: eventInfo.address
        })
      }
      if (response === 'no') {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      } else {
        if (!!hasGame) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help`)
        } else if (!!hasRequestList) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=list`)
        } else if (!!hasMusic) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`)
        } else {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
        }
      }
    } catch (e) {
      console.log('handleUpdateRSVP', e)
      toast({
        status: 'error',
        title: e.message || 'Error while sending response',
        position: 'top'
      })
    } finally {
      setUpdatingRSVP(false)
    }
  }

  useEffect(() => {
    if (!!eventInfo && eventInfo.noteForGuests) {
      form.setValue('note', eventInfo.noteForGuests)
    }
  }, [eventInfo, form])

  useEffect(() => {
    if (!eventLoading && !rsvpLoading && !guestsLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        (!rsvpInfo.phone && !rsvpInfo.email) ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        console.log('404', eventInfo, rsvpInfo, eventInfo?.completed)
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneOrEmailModal(true)
        setInfoToVerify(
          !!rsvpInfo.email
            ? { info: rsvpInfo.email, channel: 'email' }
            : { info: rsvpInfo.phone, channel: 'sms' }
        )
        return
      }
      setInfoToVerify(null)
    }
  }, [
    eventLoading,
    rsvpLoading,
    guestsLoading,
    eventInfo,
    rsvpInfo,
    guestsInfo,
    circleCode,
    rsvpId,
    navigate,
    toast
  ])

  if (eventLoading || rsvpLoading || guestsLoading) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Reply to online invitation</title>
          <meta name="description" content="Reply to the online invitation." />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      whiteLogo
      headerBackgroundColor={'transparent'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Coteri${!!eventInfo?.name ? ` - Reply to ${eventInfo.name}` : ''} invitation`}</title>
        <meta name="description" content="Reply to the online invitation." />
      </Helmet>
      {eventLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      ) : (
        <>
          <div className={`flex flex-1 items-center h-full flex-col relative`}>
            <div className="w-full flex items-center h-full flex-col  relative">
              <div className="w-full flex gap-5 flex-col sm:flex-row">
                <div className="order-2 flex-1">
                  <RSVPEventDetailsView
                    rsvpId={rsvpId}
                    circleCode={circleCode}
                    eventInfo={eventInfo}
                    guestsInfo={guestsInfo}
                  />
                </div>
                <div className="order-1 sm:w-[42%] w-[100%] relative">
                  <EventCarouselView
                    isGuest
                    rsvpId={rsvpId}
                    circleCode={circleCode}
                    eventInfo={eventInfo}
                    rsvpInfo={rsvpInfo}
                    oldAnswer={rsvpInfo?.response ?? 'no'}
                    removeAttendingCard
                  />
                </div>
              </div>
            </div>

            {isTimePassed ? (
              <div
                className="mt-4 w-full p-4 mb-4 text-md text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <div className="font-bold">Event Ended</div>
                <div>
                  This event has ended, and responses are no longer being
                  accepted.
                </div>
              </div>
            ) : (
              <div className="w-full mt-10 mb-10">
                <div className="text-[16px] text-primary font-semibold mb-6 sm:self-start self-center">
                  Respond to this event
                </div>
                <div className="w-full flex flex-col justify-between sm:flex-row gap-3">
                  <RSVPDecisionButton
                    going
                    onClick={() => handleUpdateRSVP(true)}
                  />
                  <RSVPDecisionButton onClick={() => handleUpdateRSVP(false)} />
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <ChangeResponseModal
        isOpen={changeResponseModal}
        handleOnClose={handleCloseChangeResponseModal}
        form={form}
        attendingOptions={attendingOptions}
        handleUpdateRSVP={handleUpdateRSVP}
        loading={updatingRSVP}
      />

      <PhoneOrEmailVerificationModal
        opened={phoneOrEmailModal}
        circleCode={circleCode}
        rsvpId={rsvpId}
        infoToVerify={infoToVerify}
        onClose={() => {
          setPhoneOrEmailModal(false)
          setInfoToVerify(null)
        }}
      />
    </Base>
  )
}

export default memo(RSVP)
