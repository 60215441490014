import React from 'react'
import { twMerge } from 'tailwind-merge'

const Card = ({ title, description, className, backgroundObjectClass }) => {  
  return (
    <div className='relative'>
      <div className={twMerge('h-[100px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 blur-xl', backgroundObjectClass)} />

      <div className={twMerge('p-6 rounded-lg bg-[#D9D9D9] lg:h-[207px] h-auto bg-clip-padding backdrop-filter backdrop-blur-2xl bg-opacity-10', className)}>
        <h3 className='mb-4 text-white !font-semibold break-point-900:text-[32px] text-[22px]'>{title}</h3>

        {typeof description === 'object' ? description : <h4 className='text-white break-point-900:text-[20px] text-[16px] leading-5 !font-normal'>{description}</h4>}
      </div>
    </div>
  )
}

export default Card
