import React, { useEffect, useState } from 'react'
import Base from '../../base'
import { Helmet } from 'react-helmet'
import { database } from '../../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import PhoneOrEmailVerificationModal from '../../../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import SquaresGuestChooseView from './SquaresGuestChooseView'

const SquaresGuestChoose = () => {
  const navigate = useNavigate()
  const { circleCode, rsvpId } = useParams()
  const [infoToVerify, setInfoToVerify] = useState(null)
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const eventInfo = eventSnap?.val()
  const rsvpInfo = rsvpSnap?.val()

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        (!rsvpInfo.phone && !rsvpInfo.email) ||
        (!eventInfo.completed && !eventInfo.withCanva) ||
        !eventInfo.squaresGameId
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }

      if (!rsvpInfo.verified) {
        setPhoneOrEmailModal(true)
        setInfoToVerify(
          !!rsvpInfo.email
            ? { info: rsvpInfo.email, channel: 'email' }
            : { info: rsvpInfo.phone, channel: 'sms' }
        )
        return
      }
      setInfoToVerify(null)
    }
  }, [
    eventLoading,
    rsvpLoading,
    eventInfo,
    rsvpInfo,
    navigate,
    circleCode,
    rsvpId
  ])

  return (
    <React.Fragment>
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        removeVerticalPadding
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>
        <SquaresGuestChooseView />
      </Base>
      <PhoneOrEmailVerificationModal
        opened={phoneOrEmailModal}
        circleCode={circleCode}
        rsvpId={rsvpId}
        infoToVerify={infoToVerify}
        onClose={() => {
          setPhoneOrEmailModal(false)
          setInfoToVerify(null)
        }}
      />
    </React.Fragment>
  )
}

export default SquaresGuestChoose
