import React, { memo, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database } from '../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import RSVPEventDetailsView from '../../components/RSVPEventDetailsView'
import EventCarouselView from '../../components/EventCarouselView'
import { Helmet } from 'react-helmet'
import RequestTab from '../EventDetails/Tab/RequestTab/RequestTab'
import MusicTab from '../EventDetails/Tab/MusicTab/MusicTab'
import PhoneOrEmailVerificationModal from '../../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import RSVPEventDetailsCostModal from '../../components/RSVPEventDetails/RSVPEventDetailsCostModal/RSVPEventDetailsCostModal'

const RSVPEventDetails = () => {
  const { circleCode, rsvpId } = useParams()
  const navigate = useNavigate()
  const [toggleTab, setToggleTab] = useState(0)
  const [infoToVerify, setInfoToVerify] = useState(null)

  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode}/info`)
  )
  const [playlistSnap, playlistLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/playlist`)
  )
  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)
  const [costModal, setCostModal] = useState(false)

  const playlist = playlistSnap?.val()
  const itemsInfo = itemsSnap?.val()
  const eventInfo = eventSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])
  const rsvpInfo = rsvpSnap?.val()
  const oldAnswer = rsvpInfo?.response

  const hasGame = !!eventInfo?.squaresGameId
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        (!rsvpInfo.phone && !rsvpInfo.email) ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }

      if (!rsvpInfo.verified) {
        setPhoneOrEmailModal(true)
        setInfoToVerify(
          !!rsvpInfo.email
            ? { info: rsvpInfo.email, channel: 'email' }
            : { info: rsvpInfo.phone, channel: 'sms' }
        )
        return
      }

      if (eventInfo?.costPerSquare) {
        const costModalShown = localStorage.getItem(
          `costModalShown-${circleCode}`
        )

        if (!costModalShown) {
          setCostModal(true)
          localStorage.setItem(`costModalShown-${circleCode}`, 'true')
        }
      }

      setInfoToVerify(null)
    }
  }, [eventLoading, rsvpLoading, eventInfo, rsvpInfo, navigate, circleCode])

  useEffect(() => {
    if (!hasRequestList && hasMusic) {
      setToggleTab(1)
    }
  }, [hasMusic, hasRequestList])

  if (
    eventLoading ||
    rsvpLoading ||
    guestsLoading ||
    playlistLoading ||
    itemsLoading
  ) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Online invitation details</title>
          <meta
            name="description"
            content="See the details for your invitation. Track responses. See the music playlist."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <React.Fragment>
      <RSVPEventDetailsCostModal
        isOpen={costModal}
        onClose={() => setCostModal(false)}
        eventInfo={eventInfo}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Coteri${!!eventInfo?.name ? ` - ${eventInfo.name}` : ''}`}</title>
          <meta
            name="description"
            content="See the details for your invitation. Track responses. See the music playlist."
          />
        </Helmet>

        <div className="flex flex-1 items-center h-full flex-col relative">
          <div className="w-full flex gap-5 stripe-column:flex-row flex-col sm:mb-10 mb-0">
            <div className="w-full flex gap-5 flex-col sm:flex-row">
              <div className="order-1 sm:w-[42%] w-[100%] relative">
                <EventCarouselView
                  isGuest
                  rsvpId={rsvpId}
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  rsvpInfo={rsvpInfo}
                  oldAnswer={rsvpInfo?.response ?? 'no'}
                />
              </div>
              <div className="order-2 flex-1">
                <RSVPEventDetailsView
                  rsvpId={rsvpId}
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  guestsInfo={guestsInfo}
                  oldAnswer={oldAnswer}
                />
              </div>
            </div>
          </div>

          {eventInfo.noteForGuests && (
            <div className="flex flex-col justify-center mt-[12px] w-full">
              <span>Additional event information</span>
              <div className="w-full h-[2px] bg-secondary" />

              <div className="bg-off-white p-3 flex flex-col justify-center rounded-md mt-2">
                <span>{eventInfo.noteForGuests}</span>
              </div>
            </div>
          )}

          <div className="w-full mt-[10px]">
            {hasGame && (
              <div
                className={`cursor-pointer relative flex flex-1 py-[10px] items-center justify-center rounded-md bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]`}
                onClick={() => {
                  if (!!rsvpInfo?.squares) {
                    navigate(
                      `/event/${circleCode}/rsvp/${rsvpId}/squares/summary`
                    )
                  } else {
                    navigate(
                      `/event/${circleCode}/rsvp/${rsvpId}/squares/choose`
                    )
                  }
                }}
              >
                <span>Super Bowl Squares</span>
              </div>
            )}

            <div>
              <ul
                className={`flex w-full items-center sm:gap-2 gap-2 mt-3 mb-4 sm:flex-row flex-col`}
              >
                {hasRequestList && (
                  <li
                    onClick={() => setToggleTab(0)}
                    className={`cursor-pointer flex relative w-full py-[10px] items-center justify-center rounded-md ${toggleTab === 0 ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                  >
                    <span>What to bring list</span>
                  </li>
                )}

                {hasMusic && (
                  <li
                    onClick={() => setToggleTab(1)}
                    className={`cursor-pointer flex relative w-full py-[10px] items-center justify-center rounded-md ${toggleTab === 1 ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                  >
                    <span>Music playlist</span>
                  </li>
                )}
              </ul>

              {hasRequestList ? (
                <RequestTab
                  circleInfo={eventInfo}
                  rsvpId={rsvpId}
                  isToggled={toggleTab === 0}
                  itemsInfo={itemsInfo}
                  eventInfo={eventInfo}
                  guestsInfo={guestsInfo}
                  rsvpResponse={rsvpInfo?.response}
                  title={
                    'Help out your host. Bring something from the list below to the event. You may also add your own item by clicking the "Add" button above.'
                  }
                />
              ) : null}
              {hasMusic ? (
                <MusicTab
                  rsvpId={rsvpId}
                  isToggled={toggleTab === 1}
                  circleInfo={eventInfo}
                  playlist={playlist}
                  alreadyHasResponse={true}
                  rsvpResponse={rsvpInfo?.response}
                />
              ) : null}
            </div>
          </div>
        </div>

        <PhoneOrEmailVerificationModal
          opened={phoneOrEmailModal}
          circleCode={circleCode}
          rsvpId={rsvpId}
          infoToVerify={infoToVerify}
          onClose={() => {
            setPhoneOrEmailModal(false)
            setInfoToVerify(null)
          }}
        />
      </Base>
    </React.Fragment>
  )
}

export default memo(RSVPEventDetails)
