import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import querystring from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import Base from './base'
import { getSpotifyToken } from '../services/Spotify'
import { updateUser } from '../services/User'
import { updateTempProfile } from '../services/database'

const SpotifyCallback = () => {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const handleSpotifyAuth = async (authCode, authState) => {
    if (!authCode && !authState) {
      return navigate(`/404?from=${window.location.pathname}`, {
        replace: true
      })
    }

    const spotifyData = await getSpotifyToken(authCode)
    if (!spotifyData) {
      return navigate(`/404?from=${window.location.pathname}`, {
        replace: true
      })
    }

    if (!!user) {
      await updateUser({
        email: user.email,
        spotifyID: spotifyData.spotifyID,
        spotifyCountry: spotifyData.spotifyCountry,
        refreshToken: spotifyData.refreshToken,
        accessToken: spotifyData.accessToken,
        scope: spotifyData.scope,
        expires_in: spotifyData.expires_in,
        expiryDate: spotifyData.expiryDate
      })
    } else {
      updateTempProfile({
        spotifyID: spotifyData.spotifyID,
        spotifyCountry: spotifyData.spotifyCountry,
        refreshToken: spotifyData.refreshToken,
        accessToken: spotifyData.accessToken,
        scope: spotifyData.scope,
        expires_in: spotifyData.expires_in,
        expiryDate: spotifyData.expiryDate
      })
    }

    if (authState === 'settings') {
      return navigate(`/settings`, { replace: true })
    }
    if (authState.includes('gstreaming-')) {
      console.log('guest streaming', authState)
      const [_, circleCode, rsvpId] = authState.split('-')
      return navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`, {
        replace: true
      })
    }
    if (authState.includes('streaming-')) {
      console.log('streaming event', authState.slice(10))
      return navigate(`/event/${authState.slice(10)}/playlist`, {
        replace: true
      })
    }
  }

  useEffect(() => {
    if (!loading) {
      const params =
        typeof window != null ? querystring.parse(window.location.search) : {}
      handleSpotifyAuth(params.code, params.state).then(() => null)
    }
  }, [loading])

  return <Base hideMenu={true} />
}

export default SpotifyCallback
