import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, database } from '../firebase'
import Input from '../components/Input/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import GuestList from '../components/ManageInvites/GuestsList/GuestList'
import { SearchIcon } from '../assets/icons'
import * as yup from 'yup'
import Base from './base'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import { Helmet } from 'react-helmet'
import ClipLoader from 'react-spinners/ClipLoader'
import { getPaymentData, removePaymentData } from '../services/database'
import { useToast } from '@chakra-ui/react'

export const schema = yup
  .object({
    search: yup.string(),
    sort: yup.object().optional()
  })
  .required()

const options = [
  { label: "I'm Going 🎉", value: 'yes' },
  { label: "No response ✉️", value: 'maybe' },
  { label: "Unable to attend 🙁", value: 'no' }
]

const RSVPs = () => {
  const { circleCode } = useParams()
  const [fullList, setFullList] = useState([])
  const [list, setList] = useState([])
  const [user, loading] = useAuthState(auth)
  const toast = useToast()
  const navigate = useNavigate()

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sort: options[0]
    }
  })

  const [search, sort] = form.watch(['search', 'sort'])

  const [circleSnap, circleLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const circleInfo = circleSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  const searchList = useCallback(
    (text) => {
      setList(
        fullList.filter(
          (x) =>
            x.name?.toLowerCase()?.search(text) > -1 ||
            x.name?.toLowerCase()?.includes(text) ||
            x.email?.toLowerCase()?.replace(/[() ]/g, '').search(text) > -1 ||
            x.email?.toLowerCase()?.includes(text)
        )
      )
    },
    [fullList]
  )

  const onSearch = useCallback(() => {
    if (!search || search === '') {
      setList(fullList)
    } else {
      searchList(search.toLowerCase())
    }
  }, [fullList, search, searchList])

  useEffect(() => {
    onSearch(search)
  }, [fullList, onSearch, search])

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!circleLoading && !guestsLoading && !loading) {
      const isHost = circleInfo?.hostID === user?.uid
      if (!circleInfo || !isHost) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      // if (eventInfo.withCanva && !eventInfo.completed) {
      //   navigate(`/event/update/${circleCode}/info`, { replace: true })
      //   return
      // }
      const list = Object.entries(guestsInfo)
        .map(([gKey, gData]) => ({
          id: gKey,
          ...gData
        }))
        .sort((a, b) => (a.response === 'yes' ? -1 : !b.response ? 1 : 0))
      setFullList(list)

      const paymentData = getPaymentData() ?? {}
      if (paymentData.circleCode === circleCode && paymentData.invitesSent) {
        removePaymentData()
        toast({
          title: `Emails invitations has been sent!`,
          position: 'top',
          status: 'success',
          isClosable: true
        })
      }
    }
  }, [
    circleCode,
    circleLoading,
    guestsLoading,
    loading,
    user,
    navigate,
    toast
  ])

  if (loading || !user || circleLoading || guestsLoading)
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
        footerCancelText={'Back'}
        footerCancelOnClick={() =>
          navigate(`/event/${circleCode}`, { replace: true })
        }
        enableFooter
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Track online invitation RSVPs - See guest responses - Coteri
          </title>
          <meta
            name="description"
            content="See the replies to your online invitation. Send more invitations via email."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      whiteLogo
      headerBackgroundColor={'transparent'}
      footerCancelText={'Back'}
      footerCancelOnClick={() =>
        navigate(`/event/${circleCode}`, { replace: true })
      }
      enableFooter
    >
      <div className="flex flex-1 flex-col items-center md:items-stretch">
        <h2 className="mobile:text-[20px] text-[16px]">See who's attending</h2>

        <div className='flex sm:flex-row flex-col w-full'>
          <Input
            register={form.register('search')}
            form={form}
            type="icon"
            placeholderBg="#f5f5f7"
            placeholder="Search through guests..."
            className="mt-5 mr-10 sm:flex-1 w-full"
            iconRight={<SearchIcon fill="#000" />}
          />

          <Input
            register={form.register('sort')}
            type={'select'}
            className={'mt-5 sm:w-auto w-full'}
            placeholder="Sort by"
            placeholderBg="#f5f5f7"
            options={options}
            form={form}
          />
        </div>

        <div className="mt-5 flex flex-col items-center justify-around sm:flex-row w-full">
          <div className="px-10 py-2 border-1 border-primary rounded-md mb-2 sm:mb-0 w-full text-center sm:mr-2 mr-0">
            <span className="font-semibold">
              {list.filter((guest) => guest.response === 'yes').length}
            </span>{' '}
            I'm Going 🎉
          </div>
          <div className="px-10 py-2 border-1 border-black rounded-md mb-2 sm:mb-0 w-full text-center sm:mr-2 mr-0">
            <span className="font-semibold">
              {list.filter((guest) => !guest.response).length}
            </span>{' '}
            No response ✉️
          </div>
          <div className="px-9 py-2 border-1 border-error rounded-md mb-2 sm:mb-0 w-full text-center">
            <span className="font-semibold">
              {list.filter((guest) => guest.response === 'no').length}
            </span>{' '}
            Unable to attend 🙁
          </div>
        </div>

        {list.length === 0 ? (
          <div className="flex flex-1 items-center justify-center">
            <span>No guests found</span>
          </div>
        ) : (
          <div className="flex flex-1 items-start justify-center w-full">
            <GuestList guests={list} sort={sort} removeRsvp />
          </div>
        )}
      </div>
    </Base>
  )
}

export default RSVPs
