import React, { memo } from 'react'
import ActiveEventsCard from '../../../../components/MyEvents/ActiveEventsCard/ActiveEventsCard'

const PastEvents = ({ isToggled, pastEvents, setDeleteEventModal }) => {
  return (
    <div className={`${isToggled ? 'mt[10px]' : 'hidden'} flex flex-1`}>
      {pastEvents.length === 0 ? (
        <div className="flex flex-1 items-center justify-center">
          <span>No past events</span>
        </div>
      ) : (
        <div id="header" className="flex flex-1 flex-col mt-[10px] items-center">
          {pastEvents.map((event, index) => (
            <ActiveEventsCard
              key={index}
              className={`mb-4 max-w-[650px]`}
              event={event}
              setDeleteEventModal={setDeleteEventModal}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(PastEvents)
