import React, { memo, useMemo, useState } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  CostIcon,
  LocationIcon,
  NoteIcon,
  PeopleOutlineIcon
} from '../assets/icons'
import Button from '../components/Button/Button'
import moment from 'moment'
import GuestsModal from './GuestsModal'
import RSVPEventDetailsCostModal from './RSVPEventDetails/RSVPEventDetailsCostModal/RSVPEventDetailsCostModal'

const RSVPEventDetailsView = ({
  rsvpId,
  circleCode,
  eventInfo,
  guestsInfo
}) => {
  const [popUp, setPopUp] = useState(false)
  const [costModal, setCostModal] = useState(false)

  const timeOfEvent = useMemo(() => {
    if (!eventInfo || !eventInfo.date || !eventInfo.time || !eventInfo.timezone)
      return ''
    return moment(`${eventInfo.date}T${eventInfo.time}`).format(
      `hh:mm A [${eventInfo.timezone}]`
    )
  }, [eventInfo])

  const handleViewGuests = () => {
    // logEvent(analytics, 'show_guests', {
    //   source: 'Invitation'
    // })
    setPopUp(true)
  }

  if (!eventInfo) {
    return null
  }

  const hasPaymentOption = eventInfo?.allowCash || eventInfo?.cashapp || eventInfo?.paypal || eventInfo?.venmo || eventInfo?.zelle || eventInfo?.fixedPrice || eventInfo?.costPerSquare
  const noFixedPrice = !eventInfo?.fixedPrice

  return (
    <div className="flex flex-col flex-1">
      <div>
        <div className="mt-4 flex flex-row items-center">
          <span className="mr-[35px] font-medium text-[24px]">
            {eventInfo.name}
          </span>
        </div>
      </div>

      <div className="mt-5">
        <span className="mr-[35px] font-medium">
          Hosted by {eventInfo.hostName ? eventInfo.hostName : ''}
        </span>

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md shadow-purple">
          <div className="flex flex-row items-center justify-start ">
            <CalendarIcon />
            {eventInfo.date ? (
              <span className="ml-[8px]">
                {moment(eventInfo.date).format('MMMM DD, YYYY')}
              </span>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
          <div className="flex flex-row items-center justify-start ">
            <ClockIcon />
            {eventInfo.time ? (
              <span className="ml-[8px]">{timeOfEvent}</span>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
        </div>

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md shadow-purple">
          <div className="flex flex-row items-center justify-start">
            <LocationIcon fill="#5b4abc" pinColor="#422d8f" />
            {eventInfo.address?.label ? (
              <div className="flex flex-col">
                <span className="ml-[8px]">{eventInfo.address?.label}</span>
                <span className="ml-[8px]">{eventInfo.address?.label2}</span>
              </div>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
        </div>

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md shadow-purple">
          <div className="flex flex-row items-center justify-start">
            <PeopleOutlineIcon fill="#5b4abc" />
            <span className="ml-[8px]">
              {!!guestsInfo ? Object.values(guestsInfo).length : 0} RSVPs •{' '}
            </span>
            <span
              className="text-primary cursor-pointer font-medium hover:underline"
              onClick={handleViewGuests}
            >
              {' '}
              View guests
            </span>
          </div>
        </div>

        {hasPaymentOption && (
          <div
            className={`bg-off-white p-3 flex flex-row justify-between mt-[12px] rounded-md border-1 shadow-purple`}
            onClick={() => setCostModal(true)}
          >
            <div className="flex items-center">
              <CostIcon className="w-[24px] h-[24px]" fill={'#5B4ABC'} />
              <span
                className={`ml-[8px] text-primary font-medium 'hover:underline cursor-pointer`}
              >
                {noFixedPrice ? 'Pay your host' : `$${eventInfo.costPerSquare} per person`}
              </span>
            </div>

            <span className={` text-primary underline cursor-pointer`}>
              See how to pay your host
            </span>
          </div>
        )}
      </div>

      <GuestsModal
        rsvpId={rsvpId}
        circleCode={circleCode}
        popUp={popUp}
        setPopUp={setPopUp}
      />

      <RSVPEventDetailsCostModal
        isOpen={costModal}
        onClose={() => setCostModal(false)}
        eventInfo={eventInfo}
      />
    </div>
  )
}

export default memo(RSVPEventDetailsView)
