import React, { useCallback, useEffect, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import { Helmet } from 'react-helmet'
import Toggle from '../../components/Toggle/Toggle'
import SquaresSquare from '../../components/Squares/SquaresSquare/SquaresSquare'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { calculatePayoutAmount } from '../../utils/square'
import DottedLine from '../../components/DottedLine/DottedLine'
import { database } from '../../firebase'
import { useObject } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'

export const SQUARES_CREATE = 'SQUARES_CREATE'

const Squares = () => {
  const toast = useToast()
  const { circleCode } = useParams()
  const [squareCost, setSquareCost] = useState(undefined)
  const [totalCost, setTotalCost] = useState(undefined)
  const [payouts, setPayouts] = useState({
    first: '',
    second: '',
    third: ''
  })
  const [fourthPayout, setFourthPayout] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [venmo, setVenmo] = useState(undefined)
  const [cashapp, setCashapp] = useState(undefined)
  const [paypal, setPaypal] = useState(undefined)
  const [zelle, setZelle] = useState(undefined)
  const [allowCash, setAllowCash] = useState(false)

  const handleSquareCostChange = (incomingValue) => {
    let value = incomingValue

    if (value === '') {
      setSquareCost('')
      setTotalCost(undefined)
      return
    }

    setSquareCost(value)
  }

  const handlePayoutChange = (quarter, incomingValue) => {
    let value = incomingValue

    if (value === '') {
      setPayouts((prev) => ({
        ...prev,
        [quarter]: ''
      }))
      return
    }

    setPayouts((prev) => ({
      ...prev,
      [quarter]: value
    }))
  }

  const handleContinue = () => {
    if (!squareCost || !payouts.first || !payouts.second || !payouts.third) {
      toast({
        status: 'error',
        title: 'Fill all the fields before continuing',
        position: 'top'
      })
      return
    }

    sessionStorage.setItem(
      SQUARES_CREATE,
      JSON.stringify({
        costPerSquare: squareCost,
        payouts: {
          ...payouts,
          fourth: fourthPayout
        },
        venmo,
        cashapp,
        paypal,
        zelle,
        allowCash
      })
    )

    navigate(`/event/${circleCode}/squares/choose`)
  }

  const handleCancel = () => {
    navigate('/')
  }

  useEffect(() => {
    if (!squareCost) {
      setFourthPayout(0)
      return
    }

    const first = calculatePayoutAmount(totalCost, payouts.first)
    const second = calculatePayoutAmount(totalCost, payouts.second)
    const third = calculatePayoutAmount(totalCost, payouts.third)
    const total = first + second + third

    const remaining = totalCost - total
    setFourthPayout(remaining > 0 ? remaining : 0)
  }, [payouts, squareCost, totalCost])

  const loadSession = useCallback(() => {
    try {
      setLoading(true)

      const session = sessionStorage.getItem(SQUARES_CREATE)

      if (!session) {
        return
      }

      const sessionData = JSON.parse(session)

      if (!sessionData) {
        return
      }

      setSquareCost(sessionData.costPerSquare ?? '')
      setPayouts(sessionData.payouts ?? {})
      setVenmo(sessionData.venmo ?? '')
      setCashapp(sessionData.cashapp ?? '')
      setPaypal(sessionData.paypal ?? '')
      setZelle(sessionData.zelle ?? '')
      setAllowCash(sessionData.allowCash ?? false)
    } catch (err) {
      console.log('Squares: error loading session', err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!squareCost) {
      setTotalCost(0)
      return
    }

    setTotalCost(squareCost * 100)
  }, [squareCost])

  // useEffect(() => {
  //   if (!squareLoading) {
  //     if (squareInfo) {
  //       setSquareCost(squareInfo.costPerSquare ?? '')
  //       setPayouts(squareInfo.payouts ?? {})
  //       setVenmo(squareInfo.venmo ?? '')
  //       setCashapp(squareInfo.cashapp ?? '')
  //       setPaypal(squareInfo.paypal ?? '')
  //       setZelle(squareInfo.zelle ?? '')
  //       setAllowCash(squareInfo.allowCash ?? false)
  //     } else {
  //       loadSession()
  //     }
  //   }
  // }, [squareLoading, squareInfo, loadSession])

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      footerContinueOnClick={handleContinue}
      footerCancelOnClick={handleCancel}
      footerContinueProps={{
        disabled:
          !squareCost || !payouts.first || !payouts.second || !payouts.third
      }}
      removeVerticalPadding
      enableFooter
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - See your events history</title>
        <meta
          name="description"
          content="See events you’ve hosted or attended. Click on the tiles to see the event details."
        />
      </Helmet>

      {loading ? (
        <div className="flex justify-center items-center flex-1">
          <ClipLoader />
        </div>
      ) : (
        <div className="flex relative flex-1 mt-[-20px] mb-[-15px] flex-col">
          <div className="px-0 sm:px-20 z-50">
            <div className="mx-5 mt-12">
              <h2 className="text-primary text-[20px]">
                Setup your Squares games
              </h2>
              <span className="text-[14px]">
                Set the price per square and payouts for each quarter.
              </span>
            </div>
          </div>

          <DottedLine
            color={'#80808033'}
            className={'my-10 h-[1px]'}
            horizontal
            solid
          />

          <div className="flex flex-col px-0 sm:px-20 z-50">
            <div className="mx-5 flex items-center sm:flex-row flex-col">
              <div className="flex flex-col mr-5 sm:mb-0 mb-3">
                <span className="text-[20px] font-semibold">
                  Cost per Square
                </span>
                <span className="text-[14px]">
                  The amount to charge your guests for each square they
                  purchase.
                </span>
              </div>

              <SquaresSquare
                placeholder={'$'}
                value={squareCost}
                onChangeText={handleSquareCostChange}
                placeholderBefore
              />
            </div>

            <div className="mx-5 mt-2 flex-col items-center">
              <div className="flex flex-col">
                <span className="text-[20px] font-semibold">
                  Payout Amounts
                </span>
                <span className="text-[14px]">
                  The percentage to payout each quarter.
                </span>
              </div>

              <div className="flex gap-2 mt-3 sm:flex-row flex-col sm:items-start items-center">
                <div className="flex items-center gap-2">
                  {[
                    { key: 'first', prefix: 'st' },
                    { key: 'second', prefix: 'nd' },
                    { key: 'third', prefix: 'rd' }
                  ].map((quarter, idx) => {
                    const payoutPercentage = payouts[quarter.key]
                    const payoutAmount = calculatePayoutAmount(
                      totalCost,
                      payoutPercentage
                    )

                    return (
                      <SquaresSquare
                        key={idx}
                        title={`${idx + 1}${quarter.prefix}`}
                        subtitle={`$${payoutAmount}`}
                        placeholder={'%'}
                        value={payouts[quarter.key]}
                        onChangeText={(value) =>
                          handlePayoutChange(quarter.key, value)
                        }
                        placeholderAfter
                      />
                    )
                  })}
                </div>

                <div className="flex flex-col items-center">
                  <span className="text-[14px]">4th</span>
                  <div className="w-[70px] h-[45px] text-center flex items-center justify-center max-w-[50px] overflow-hidden">
                    <span className="text-center text-[18px] text-primary">
                      ${fourthPayout}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-5 mt-2 flex-col items-center">
              <div className="flex flex-col">
                <span className="text-[20px] font-semibold">
                  Payment Methods
                </span>
                <span className="text-[14px]">
                  The way guests will pay you for the squares they want to
                  purchase.
                </span>
              </div>

              <div className="flex flex-col gap-1 mt-3">
                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary text-[18px] cursor-pointer w-[85px]">
                    Venmo
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className="text-[18px] text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={venmo}
                      onChange={(e) => setVenmo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary text-[18px] cursor-pointer w-[85px]">
                    Cash App
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className="text-[18px] text-primary ml-1">$</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={cashapp}
                      onChange={(e) => setCashapp(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary text-[18px] cursor-pointer w-[85px]">
                    Paypal
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className="text-[18px] text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={paypal}
                      onChange={(e) => setPaypal(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary text-[18px] cursor-pointer w-[85px]">
                    Zelle
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary placeholder:text-[14px] placeholder:text-primary`}
                      placeholder="Email or phone number"
                      value={zelle}
                      onChange={(e) => setZelle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center">
                  <span className="text-primary text-[18px] cursor-pointer mr-2">
                    Allow cash payment?
                  </span>
                  <Toggle
                    textOn="Yes"
                    textOff="No"
                    value={allowCash}
                    setValue={setAllowCash}
                  />
                </div>
              </div>
            </div>
          </div>

          <DottedLine
            color={'#80808033'}
            className={'my-10 h-[1px]'}
            horizontal
            solid
          />
        </div>
      )}
    </Base>
  )
}

export default Squares
