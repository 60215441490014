/* eslint-disable no-constant-condition */
import React, { memo, useState } from 'react'
import { EditIcon } from '../../../assets/icons'
import Button from '../../Button/Button'
import RequestListItemModal from '../RequestListItemModal/RequestListItemModal'
import { useParams } from 'react-router-dom'
import RequestListDeleteModal from '../RequestListDeleteModal/RequestListDeleteModal'
import { useToast } from '@chakra-ui/react'
import { ref, update } from 'firebase/database'
import { auth, database } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { eventTimePassed } from '../../../helpers'
import { twMerge } from 'tailwind-merge'
import { AddIcon, CheckIcon } from '@chakra-ui/icons'

const RequestTabListItem = ({
  item,
  rsvpId,
  guestsInfo,
  eventInfo,
  overrideDelete,
  owner
}) => {
  const { circleCode } = useParams()
  const [user, loading] = useAuthState(auth)
  const [itemModal, setItemModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const toast = useToast()

  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  const isGoing = guestsInfo[rsvpId]?.response === 'yes' || owner

  const userId = !!rsvpId ? rsvpId : user?.uid ?? ''

  const claimed = item.claimed && item.claimedBy === userId

  const amIItemOwner = (!item.claimed || item.claimedBy === userId) && !isTimePassed && (isGoing || owner)

  const handleClaimItem = async (val) => {
    if (loading) return
    try {
      await update(ref(database, `circles/${circleCode}/items/${item.id}`), {
        claimed: !!val ? true : null,
        claimedBy: !!val ? userId : null
      })
      toast({
        status: 'success',
        title: val ? 'Item claimed' : 'Item unclaimed',
        position: 'top'
      })
    } catch (err) {
      console.log('Error')
      toast({
        status: 'error',
        title: 'Error attempting to claim item',
        position: 'top'
      })
    }
  }


  return (
    <>
      <div className={twMerge(
        `w-full p-4 ${claimed ? 'bg-music-card' : 'bg-white'} rounded-md flex flex-row justify-between items-center ${amIItemOwner ? 'cursor-pointer' : 'cursor-default'} apple-shadow`,
      )}
        onClick={amIItemOwner ? () => handleClaimItem(!item.claimed) : null}>
        <div className="flex-1 flex flex-col justify-center">
          <span>
            {item.name}
          </span>
          <span className="text-[12px]">
            {!!item.claimed ? (
              item.claimedBy === userId ? (
                <span className="mr-2">You</span>
              ) : (
                <span>
                  {item?.claimedBy === eventInfo?.hostID
                    ? eventInfo?.hostName ?? item?.claimedBy
                    : guestsInfo[item?.claimedBy]?.name ?? item?.claimedBy}
                </span>
              )
            ) : <span>Not claimed</span>}
          </span>
        </div>


        {/* <td className="py-3">
          <div className="flex-1 flex text-placeholder items-center">
            {!!item.claimed ? (
              item.claimedBy === userId ? (
                <span className="mr-2">You</span>
              ) : (
                <span>
                  {item?.claimedBy === eventInfo?.hostID
                    ? eventInfo?.hostName ?? item?.claimedBy
                    : guestsInfo[item?.claimedBy]?.name ?? item?.claimedBy}
                </span>
              )
            ) : null}

            {(!item.claimed || item.claimedBy === userId) && !isTimePassed ? (
              !isGoing && !owner ? (
                <span>No owner</span>
              ) : (
                <Button
                  size={'extra-small'}
                  text={!!item.claimed ? 'unclaim' : 'claim'}
                  type={!!item.claimed ? 'border' : 'default'}
                  onClick={() => handleClaimItem(!item.claimed)}
                />
              )
            ) : null}
          </div>
        </td> */}

        <div className='flex items-center'>
          {owner && !isTimePassed ? (
            <div className="flex-1 flex items-center justify-evenly">
              <EditIcon
                fill="#00000099"
                className="cursor-pointer"
                onClick={(e) => {
                  setItemModal(true)
                  e.stopPropagation()
                }}
              />
            </div>
          ) : null}

          <div
            className={`w-[30px] h-[30px] flex items-center justify-center rounded-full ml-[30px]`}
          >
            {!!item.claimed ? (
              item.claimedBy === userId ? (
                <CheckIcon color={'#422D8F'} />
              ) : (
                null
              )
            ) : isGoing && <AddIcon className={"#120a31"} />}
          </div>
        </div>
      </div>

      <RequestListItemModal
        isOpen={itemModal}
        onClose={() => setItemModal(false)}
        onDelete={() => setDeleteModal(true)}
        item={item}
        userId={userId}
        owner={owner}
      />

      <RequestListDeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        item={item}
        overrideDelete={overrideDelete}
      />
    </>
  )
}

export default memo(RequestTabListItem)
