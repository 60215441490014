import React, { memo,  useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, signOut } from '../../firebase'
import { useMediaQuery } from '../../hook/useMediaQuery'
import { useNavigate, Link } from 'react-router-dom'
import { ReactComponent as CoteriGlow } from '../../assets/icons/coteri-glow.svg'
import {    
  Menu,
  MenuList,  
} from '@chakra-ui/react'
import MenuButton from '../MenuButton/MenuButton'

const Header = ({ whiteLogo, menuList }) => {      
  const [redirect, setRedirect] = useState(null)

  const menuRef = useRef(null) // Ref to track the menu
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const breakpoints = {
    lg: 1024
  }

  const { lg } = useMediaQuery(breakpoints)

  const handleSignOut = async () => {
    // removeSettingsData()
    await signOut(auth)
  }

  const handleCreateEvent = () => {
    if (!user || user.isAnonymous) {
      
      return
    }

    navigate('/event/new')
  }

  const handleLogOut = () => {
    // setBurguer(false)
    // logEvent(analytics, 'menu_clicked', {
    //   source: 'LOG_OUT'
    // })
    handleSignOut()
  }

  const handleAboutUs = () => {
    // logEvent(analytics, 'nav_bar_clicked', {
    //   source: 'ABOUT'
    // })
    navigate('/about')
  }

  const handleSuccess = () => {
    // setShowPopUp(null)
    // if (!!redirect) {
    //   if (redirect.startsWith('canva=')) {
    //     setCanvaPopUp(redirect.substring(6, redirect.length - 1))
    //     return
    //   }
    //   navigate(redirect)
    // }
  }

  const handleMyEvents = () => {
    // logEvent(analytics, 'menu_clicked', {
    //   source: 'MY_EVENTS'
    // })
    navigate('/event-history')
  }

  const handleSettings = () => {
    navigate('/settings')
  }

  const handleHome = () => {
    navigate('/')
  }
  
  return (
    <div>
      <div
        id="header"
        className="w-[100%] h-20 flex items-center justify-between sm:px-10 px-5 relative bg-[#02000B] border-b-1 border-b-[#28272E] "      
      >
        <div className="absolute w-full h-[160px] bg-gradient-to-b from-[#5b4abc33] to-transparent bottom-[-161px] left-0"/>

        <Link to={'/'} style={{ zIndex: 10 }}>
          {/* <LogoIcon
            className="w-[120px] h-[50px] lg:w-[160px] lg:h-[90px]"
            fill={whiteLogo ? '#fff' : '#DD94F7'}
          /> */}
           <CoteriGlow          
            className="sm:w-[180px] sm:h-[180px] w-[140px] h-[140px] -ml-5"            
          />
        </Link>
        
        {menuList && <div className="flex flex-row items-center sm:gap-6 gap-0">   
          <Menu>
            <MenuButton />
            <MenuList zIndex="99">                         
              {menuList}
            </MenuList>
          </Menu>      
        </div>}   
      </div>             
    </div>
  )
}

export default memo(Header)
