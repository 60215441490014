import React, { useMemo } from 'react'
import Modal from '../../Modal/Modal'
import DottedLine from '../../DottedLine/DottedLine'
import SquaresSummaryModalItem from './SquaresSummaryModalItem'
import { XIcon } from '../../../assets/icons'

const SquaresSummaryModal = ({ squares, rsvpId, user, isOpen, onClose }) => {
  // const squareCost = Number(extractPercentage(sessionSettingsData?.costPerSquare, '$'))

  const currentUser = rsvpId || user?.uid

  const mySquaresSummary = useMemo(() => {
    return {
      amountOfSquares: Object.keys(squares).filter(
        (key) => squares[key].uid === currentUser
      ).length
    }
  }, [squares, currentUser])

  const guestsSquares = useMemo(() => {
    const guestsData = []
    Object.keys(squares).forEach((key) => {
      const { uid, initials, name } = squares[key]
      const guest = guestsData.find((g) => g.uid === uid)
      if (guest) {
        guest.squares += 1
      } else {
        guestsData.push({
          uid,
          initials,
          squares: 1,
          name
        })
      }
    })
    return guestsData
  }, [squares])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={'max-w-[450px] w-full mx-5'}
    >
      <div style={{ zIndex: 999 }}>
        <div className="bg-white rounded-input relative w-full flex flex-col">
          <div className="flex items-center justify-end mt-5 px-5">
            <div
              className="flex items-center cursor-pointer z-50"
              onClick={onClose}
            >
              <XIcon fill={'black'} width={23} height={23} />
            </div>
          </div>

          <div className="absolute inset-0 w-full h-full left-5 flex">
            <DottedLine color={'#80808033'} />
          </div>

          <div className="flex flex-col px-5 mt-3">
            <div className="flex items-center">
              <div className="w-[2px] h-5 bg-primary" />
              <span className="ml-5 text-[18px] text-primary font-semibold">
                Summary of Squares picks.
              </span>
            </div>
            <div className="flex items-center justify-between mx-5">
              <span className="text-[14px]">
                {mySquaresSummary?.amountOfSquares} squares selected
              </span>
              {/* <span className='font-semibold'>You owe ${mySquaresSummary?.totalPrice}</span> */}
            </div>
          </div>

          <DottedLine
            color={'#80808033'}
            className={'my-3 h-[1px]'}
            horizontal
            solid
          />

          {/* <div className='flex flex-row px-5'>
            <div className='w-[2px] h-5 bg-primary' />

            <div className='flex flex-col'>
              <span className='ml-5 text-[15px]'>Venmo: @jonathanorler</span>
              <span className='ml-5 text-[15px]'>Or pay cash upon arrival.</span>
            </div>
          </div>

          <DottedLine color={"#80808033"} className={"my-3 h-[1px]"} horizontal solid /> */}

          <div className="flex flex-col px-5 mb-5">
            <div className="flex items-center">
              <div className="w-[2px] h-5 bg-primary" />
              <span className="mr-5 text-[15px] font-semibold ml-5">
                Guest Summary
              </span>
            </div>

            <div className="mt-2 flex flex-col gap-2 mx-5">
              {guestsSquares?.map((item, key) => (
                <SquaresSummaryModalItem key={key} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SquaresSummaryModal
