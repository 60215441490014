import React, { memo } from 'react'
import RequestTabListItem from './RequestTabListItem'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'

const RequestTabList = ({ items, loading, rsvpId, guestsInfo, eventInfo, overrideDelete, owner }) => {
  if (loading) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  if (items?.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center mt-[15px]">
        <span>No items found</span>
      </div>
    )
  }

  return (
    <div className="mt-[14px] flex w-full gap-3 flex-col">
      {items?.sort((a, b) => {
        if (!a.createdAt) return -1;
        if (!b.createdAt) return 1;

        return moment(a.createdAt).diff(moment(b.createdAt));
      }).map((item, index) => (
        <RequestTabListItem
          key={index}
          item={item}
          rsvpId={rsvpId}
          eventInfo={eventInfo}
          guestsInfo={guestsInfo}
          overrideDelete={overrideDelete}
          owner={owner}
        />
      )).reverse()}
    </div>
  )
}

export default memo(RequestTabList)
