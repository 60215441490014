export const musics = [
  {
    "albumName": "Flowers",
    "appleMusic": {
      "albumName": "Endless Summer Vacation",
      "artistName": "Miley Cyrus",
      "artists": [
        "137057909"
      ],
      "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/c4/40/d8/c440d88a-2bcd-5d10-e814-e482271134f4/196871387361.jpg/100x100bb.jpg",
      "genres": [
        "Pop",
        "Music"
      ],
      "href": "/v1/catalog/us/songs/1702906535",
      "id": "1702906535",
      "isrc": "USSM12209777",
      "name": "Flowers",
      "popularity": 0,
      "provider": "apple_music",
      "releaseDate": "2023-01-12",
      "store": "us",
      "type": "songs",
      "withError": false
    },
    "appleMusicHREF": "/v1/catalog/us/songs/1702906535",
    "appleMusicID": "1702906535",
    "artistName": "Miley Cyrus",
    "artists": [
      "5YGY8feqx7naU7z4HrwZM6"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273f429549123dbe8552764ba1d",
    "genres": [
      "Pop",
      "Music"
    ],
    "href": "https://api.spotify.com/v1/tracks/0yLdNVWF3Srea0uzk55zFn",
    "id": "0yLdNVWF3Srea0uzk55zFn",
    "isrc": "USSM12209777",
    "name": "Flowers",
    "popularity": 32,
    "provider": "spotify",
    "releaseDate": "2023-01-13",
    "spotifyID": "0yLdNVWF3Srea0uzk55zFn",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "positions",
    "appleMusic": {
      "albumName": "Positions (Deluxe)",
      "artistName": "Ariana Grande",
      "artists": [
        "412778295"
      ],
      "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/76/fa/23/76fa232c-2e97-88de-9558-3302ed9d6656/21UMGIM11941.rgb.jpg/100x100bb.jpg",
      "genres": [
        "Pop",
        "Music"
      ],
      "href": "/v1/catalog/us/songs/1553944486",
      "id": "1553944486",
      "isrc": "USUM72019412",
      "name": "positions",
      "popularity": 0,
      "provider": "apple_music",
      "releaseDate": "2020-10-23",
      "store": "us",
      "type": "songs",
      "withError": false
    },
    "appleMusicHREF": "/v1/catalog/us/songs/1553944486",
    "appleMusicID": "1553944486",
    "artistName": "Ariana Grande",
    "artists": [
      "66CXWjxzNUsdJxJ2JdwvnR"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b2737368325f5998ef48d4fb5401",
    "genres": [
      "Pop",
      "Music"
    ],
    "href": "https://api.spotify.com/v1/tracks/7igeByaBM0MgGsgXtNxDJ7",
    "id": "7igeByaBM0MgGsgXtNxDJ7",
    "isrc": "USUM72019412",
    "name": "positions",
    "popularity": 0,
    "provider": "spotify",
    "releaseDate": "2020-10-23",
    "spotifyID": "7igeByaBM0MgGsgXtNxDJ7",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "Blinding Lights",
    "appleMusic": {
      "albumName": "Lieder Zum Duschen",
      "artistName": "The Weeknd",
      "artists": [
        "479756766"
      ],
      "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b2/e7/77/b2e777c0-92b7-839e-ffa6-6fc9cd10bb21/22UM1IM22285.rgb.jpg/100x100bb.jpg",
      "genres": [
        "R&B/Soul",
        "Music"
      ],
      "href": "/v1/catalog/us/songs/1651048013",
      "id": "1651048013",
      "isrc": "USUG11904206",
      "name": "Blinding Lights",
      "popularity": 0,
      "provider": "apple_music",
      "releaseDate": "2019-11-29",
      "store": "us",
      "type": "songs",
      "withError": false
    },
    "appleMusicHREF": "/v1/catalog/us/songs/1651048013",
    "appleMusicID": "1651048013",
    "artistName": "The Weeknd",
    "artists": [
      "1Xyo4u8uXC1ZmMpatF05PJ"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273c464fabb4e51b72d657f779a",
    "genres": [
      "R&B/Soul",
      "Music"
    ],
    "href": "https://api.spotify.com/v1/tracks/0sf12qNH5qcw8qpgymFOqD",
    "id": "0sf12qNH5qcw8qpgymFOqD",
    "isrc": "USUG11904206",
    "name": "Blinding Lights",
    "popularity": 15,
    "provider": "spotify",
    "releaseDate": "2019-11-29",
    "spotifyID": "0sf12qNH5qcw8qpgymFOqD",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "ROXANNE",
    "appleMusic": {
      "albumName": "ROXANNE - Single",
      "artistName": "Arizona Zervas",
      "artists": [
        "1026196272"
      ],
      "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5f/e7/20/5fe7205c-6853-c60a-6e32-ee8fd41e88c6/886448148753.jpg/100x100bb.jpg",
      "genres": [
        "Hip-Hop/Rap",
        "Music"
      ],
      "href": "/v1/catalog/us/songs/1487953560",
      "id": "1487953560",
      "isrc": "USSM11914320",
      "name": "ROXANNE",
      "popularity": 0,
      "provider": "apple_music",
      "releaseDate": "2019-10-10",
      "store": "us",
      "type": "songs",
      "withError": false
    },
    "appleMusicHREF": "/v1/catalog/us/songs/1487953560",
    "appleMusicID": "1487953560",
    "artistName": "Arizona Zervas",
    "artists": [
      "0vRvGUQVUjytro0xpb26bs"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273069a93617a916760ab88ffea",
    "genres": [
      "Hip-Hop/Rap",
      "Music"
    ],
    "href": "https://api.spotify.com/v1/tracks/696DnlkuDOXcMAnKlTgXXK",
    "id": "696DnlkuDOXcMAnKlTgXXK",
    "isrc": "USSM11914320",
    "name": "ROXANNE",
    "popularity": 74,
    "provider": "spotify",
    "releaseDate": "2019-10-10",
    "spotifyID": "696DnlkuDOXcMAnKlTgXXK",
    "store": "us",
    "type": "track"
  },
  {
    "albumName": "Levitating (feat. DaBaby)",
    "appleMusic": {
      "albumName": "Mix música estate 2023",
      "artistName": "Dua Lipa",
      "artists": [
        "1031397873"
      ],
      "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/a7/72/e1/a772e188-a4a4-bff7-9980-f81351e8c628/5059460289512.jpg/100x100bb.jpg",
      "genres": [
        "Pop",
        "Music"
      ],
      "href": "/v1/catalog/gb/songs/1746094740",
      "id": "1746094740",
      "isrc": "GBAHT2000942",
      "name": "Levitating (feat. DaBaby)",
      "popularity": 0,
      "provider": "apple_music",
      "releaseDate": "2020-03-27",
      "store": "gb",
      "type": "songs",
      "withError": false
    },
    "appleMusicHREF": "/v1/catalog/gb/songs/1746094740",
    "appleMusicID": "1746094740",
    "artistName": "Dua Lipa & DaBaby",
    "artists": [
      "6M2wZ9GZgrQXHCFfjv46we",
      "4r63FhuTkUYltbVAg5TQnk"
    ],
    "artworkURL": "https://i.scdn.co/image/ab67616d0000b273c5e7aa639b2920099f8c3b94",
    "genres": [
      "Pop",
      "Music"
    ],
    "href": "https://api.spotify.com/v1/tracks/463CkQjx2Zk1yXoBuierM9",
    "id": "463CkQjx2Zk1yXoBuierM9",
    "isrc": "GBAHT2000942",
    "name": "Levitating (feat. DaBaby)",
    "popularity": 69,
    "provider": "spotify",
    "releaseDate": "2020-10-01",
    "spotifyID": "463CkQjx2Zk1yXoBuierM9",
    "store": "gb",
    "type": "track"
  },
  // {
  //   "albumName": "Planet Her",
  //   "appleMusic": {
  //     "albumName": "TikTok Viral Hits 2022  2023",
  //     "artistName": "Doja Cat",
  //     "artists": [
  //       "830588310"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/46/d7/ed/46d7ed8e-216a-593e-a4f3-4b223ea5c5e9/196589727930.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Pop",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1659205216",
  //     "id": "1659205216",
  //     "isrc": "USRC12101532",
  //     "name": "Woman",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2021-06-25",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1659205216",
  //   "appleMusicID": "1659205216",
  //   "artistName": "Doja Cat",
  //   "artists": [
  //     "5cj0lLjcoR7YOSnhnX0Po5"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b273be841ba4bc24340152e3a79a",
  //   "genres": [
  //     "Pop",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/6Uj1ctrBOjOas8xZXGqKk4",
  //   "id": "6Uj1ctrBOjOas8xZXGqKk4",
  //   "isrc": "USRC12101532",
  //   "name": "Woman",
  //   "popularity": 84,
  //   "provider": "spotify",
  //   "releaseDate": "2021-06-25",
  //   "spotifyID": "6Uj1ctrBOjOas8xZXGqKk4",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Midnights",
  //   "appleMusic": {
  //     "albumName": "Midnights",
  //     "artistName": "Taylor Swift",
  //     "artists": [
  //       "159260351"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/8b/59/4b/8b594b75-5a1a-9a39-e003-b227d09d5b37/22UM1IM07364.rgb.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Pop",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1645937758",
  //     "id": "1645937758",
  //     "isrc": "USUG12205736",
  //     "name": "Anti-Hero",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2022-10-21",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1645937758",
  //   "appleMusicID": "1645937758",
  //   "artistName": "Taylor Swift",
  //   "artists": [
  //     "06HL4z0CvFAxyc27GXpf02"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b273bb54dde68cd23e2a268ae0f5",
  //   "genres": [
  //     "Pop",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/0V3wPSX9ygBnCm8psDIegu",
  //   "id": "0V3wPSX9ygBnCm8psDIegu",
  //   "isrc": "USUG12205736",
  //   "name": "Anti-Hero",
  //   "popularity": 85,
  //   "provider": "spotify",
  //   "releaseDate": "2022-10-21",
  //   "spotifyID": "0V3wPSX9ygBnCm8psDIegu",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Dreamland (+ Bonus Levels)",
  //   "appleMusic": {
  //     "albumName": "Lieder Zum Duschen",
  //     "artistName": "Glass Animals",
  //     "artists": [
  //       "528928008"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b2/e7/77/b2e777c0-92b7-839e-ffa6-6fc9cd10bb21/22UM1IM22285.rgb.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Alternative",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1651048016",
  //     "id": "1651048016",
  //     "isrc": "GBUM72000433",
  //     "name": "Heat Waves",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2020-06-28",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1651048016",
  //   "appleMusicID": "1651048016",
  //   "artistName": "Glass Animals",
  //   "artists": [
  //     "4yvcSjfu4PC0CYQyLy4wSq"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b2739e495fb707973f3390850eea",
  //   "genres": [
  //     "Alternative",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/02MWAaffLxlfxAUY7c5dvx",
  //   "id": "02MWAaffLxlfxAUY7c5dvx",
  //   "isrc": "GBUM72000433",
  //   "name": "Heat Waves",
  //   "popularity": 23,
  //   "provider": "spotify",
  //   "releaseDate": "2020-08-06",
  //   "spotifyID": "02MWAaffLxlfxAUY7c5dvx",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Mood (feat. iann dior)",
  //   "appleMusic": {
  //     "albumName": "Never saved - Single",
  //     "artistName": "Mr Ci$nero",
  //     "artists": [
  //       "1585688092"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/30/e7/3c/30e73c6c-5f66-5376-9012-4fd2abe69732/198309952578.png/100x100bb.jpg",
  //     "genres": [
  //       "Hip-Hop/Rap",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1737880257",
  //     "id": "1737880257",
  //     "isrc": "USQX92003025",
  //     "name": "Never Saved",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2024-03-27",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1737880257",
  //   "appleMusicID": "1737880257",
  //   "artistName": "24kGoldn & iann dior",
  //   "artists": [
  //     "6fWVd57NKTalqvmjRd2t8Z",
  //     "6ASri4ePR7RlsvIQgWPJpS"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b273ff8c985ecb3b7c5f847be357",
  //   "genres": [
  //     "Hip-Hop/Rap",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/3tjFYV6RSFtuktYl3ZtYcq",
  //   "id": "3tjFYV6RSFtuktYl3ZtYcq",
  //   "isrc": "USQX92003025",
  //   "name": "Mood (feat. iann dior)",
  //   "popularity": 2,
  //   "provider": "spotify",
  //   "releaseDate": "2020-07-24",
  //   "spotifyID": "3tjFYV6RSFtuktYl3ZtYcq",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "First Class",
  //   "appleMusic": {
  //     "albumName": "Spiaggia e relax",
  //     "artistName": "Jack Harlow",
  //     "artists": [
  //       "1047679432"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/e6/04/93/e6049373-dba7-7073-06e6-a75ffb2a6771/5059460289529.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Hip-Hop/Rap",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1748980296",
  //     "id": "1748980296",
  //     "isrc": "USAT22203024",
  //     "name": "First Class",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2024-05-16",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1748980296",
  //   "appleMusicID": "1748980296",
  //   "artistName": "Jack Harlow",
  //   "artists": [
  //     "2LIk90788K0zvyj2JJVwkJ"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b2730fbbde391655703a7c8cdc79",
  //   "genres": [
  //     "Hip-Hop/Rap",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/1rDQ4oMwGJI7B4tovsBOxc",
  //   "id": "1rDQ4oMwGJI7B4tovsBOxc",
  //   "isrc": "USAT22203024",
  //   "name": "First Class",
  //   "popularity": 4,
  //   "provider": "spotify",
  //   "releaseDate": "2022-04-08",
  //   "spotifyID": "1rDQ4oMwGJI7B4tovsBOxc",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Prisoner (feat. Dua Lipa)",
  //   "appleMusic": {
  //     "albumName": "NOW That's What I Call Music!, Vol. 78",
  //     "artistName": "Miley Cyrus",
  //     "artists": [
  //       "137057909"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/71/20/27/71202714-fd9a-4686-fe72-5faa57e421bf/21UMGIM23917.rgb.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Pop",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1562379730",
  //     "id": "1562379730",
  //     "isrc": "USRC12003364",
  //     "name": "Prisoner (feat. Dua Lipa)",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2020-11-19",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1562379730",
  //   "appleMusicID": "1562379730",
  //   "artistName": "Miley Cyrus & Dua Lipa",
  //   "artists": [
  //     "5YGY8feqx7naU7z4HrwZM6",
  //     "6M2wZ9GZgrQXHCFfjv46we"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b2733797253ef8b94a9e35e1b7c6",
  //   "genres": [
  //     "Pop",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/5JqZ3oqF00jkT81foAFvqg",
  //   "id": "5JqZ3oqF00jkT81foAFvqg",
  //   "isrc": "USRC12003364",
  //   "name": "Prisoner (feat. Dua Lipa)",
  //   "popularity": 0,
  //   "provider": "spotify",
  //   "releaseDate": "2020-11-20",
  //   "spotifyID": "5JqZ3oqF00jkT81foAFvqg",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "POPSTAR (feat. Drake)",
  //   "appleMusic": {
  //     "albumName": "POPSTAR (feat. Drake) - Single",
  //     "artistName": "DJ Khaled",
  //     "artists": [
  //       "157749142"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/73/8c/64/738c6422-f3e6-c0ab-e073-87ca7c1d49f3/886448646310.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Hip-Hop/Rap",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1523923824",
  //     "id": "1523923824",
  //     "isrc": "USSM12004501",
  //     "name": "POPSTAR (feat. Drake)",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2020-07-17",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1523923824",
  //   "appleMusicID": "1523923824",
  //   "artistName": "DJ Khaled & Drake",
  //   "artists": [
  //     "0QHgL1lAIqAw0HtD7YldmP",
  //     "3TVXtAsR1Inumwj472S9r4"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b273efaecb4b9cbae7c120d14617",
  //   "genres": [
  //     "Hip-Hop/Rap",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/6EDO9iiTtwNv6waLwa1UUq",
  //   "id": "6EDO9iiTtwNv6waLwa1UUq",
  //   "isrc": "USSM12004501",
  //   "name": "POPSTAR (feat. Drake)",
  //   "popularity": 68,
  //   "provider": "spotify",
  //   "releaseDate": "2020-07-17",
  //   "spotifyID": "6EDO9iiTtwNv6waLwa1UUq",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Kiss Me More (feat. SZA)",
  //   "appleMusic": {
  //     "albumName": "TikTok Songs: Pop Hits 2022  2023",
  //     "artistName": "Doja Cat",
  //     "artists": [
  //       "830588310"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/11/c7/3a/11c73a63-34f8-385b-018b-5ea5396f2214/196589727886.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Pop",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1659191722",
  //     "id": "1659191722",
  //     "isrc": "USRC12100543",
  //     "name": "Kiss Me More (feat. SZA)",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2021-04-09",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1659191722",
  //   "appleMusicID": "1659191722",
  //   "artistName": "Doja Cat & SZA",
  //   "artists": [
  //     "5cj0lLjcoR7YOSnhnX0Po5",
  //     "7tYKF4w9nC0nq9CsPZTHyP"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b2736c031afd210aed3084f80956",
  //   "genres": [
  //     "Pop",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/748mdHapucXQri7IAO8yFK",
  //   "id": "748mdHapucXQri7IAO8yFK",
  //   "isrc": "USRC12100543",
  //   "name": "Kiss Me More (feat. SZA)",
  //   "popularity": 75,
  //   "provider": "spotify",
  //   "releaseDate": "2021-04-09",
  //   "spotifyID": "748mdHapucXQri7IAO8yFK",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "MONTERO",
  //   "appleMusic": {
  //     "albumName": "Summer Party 2024 / Best Bangers",
  //     "artistName": "Lil Nas X & Jack Harlow",
  //     "artists": [
  //       "1400730578",
  //       "1047679432"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/f4/42/34/f4423458-ec8d-fde2-8e51-d0d05f7ac560/196872030808.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Hip-Hop/Rap",
  //       "Music",
  //       "Pop"
  //     ],
  //     "href": "/v1/catalog/us/songs/1741672393",
  //     "id": "1741672393",
  //     "isrc": "USSM12104539",
  //     "name": "INDUSTRY BABY",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2021-07-23",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1741672393",
  //   "appleMusicID": "1741672393",
  //   "artistName": "Lil Nas X & Jack Harlow",
  //   "artists": [
  //     "7jVv8c5Fj3E9VhNjxT4snq",
  //     "2LIk90788K0zvyj2JJVwkJ"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b273be82673b5f79d9658ec0a9fd",
  //   "genres": [
  //     "Hip-Hop/Rap",
  //     "Music",
  //     "Pop"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/5Z9KJZvQzH6PFmb8SNkxuk",
  //   "id": "5Z9KJZvQzH6PFmb8SNkxuk",
  //   "isrc": "USSM12104539",
  //   "name": "INDUSTRY BABY (feat. Jack Harlow)",
  //   "popularity": 74,
  //   "provider": "spotify",
  //   "releaseDate": "2021-09-17",
  //   "spotifyID": "5Z9KJZvQzH6PFmb8SNkxuk",
  //   "store": "us",
  //   "type": "track"
  // },
  // {
  //   "albumName": "Harry's House",
  //   "appleMusic": {
  //     "albumName": "Harry's House",
  //     "artistName": "Harry Styles",
  //     "artists": [
  //       "471260289"
  //     ],
  //     "artworkURL": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/2a/19/fb/2a19fb85-2f70-9e44-f2a9-82abe679b88e/886449990061.jpg/100x100bb.jpg",
  //     "genres": [
  //       "Pop",
  //       "Music"
  //     ],
  //     "href": "/v1/catalog/us/songs/1615585006",
  //     "id": "1615585006",
  //     "isrc": "USSM12200610",
  //     "name": "Late Night Talking",
  //     "popularity": 0,
  //     "provider": "apple_music",
  //     "releaseDate": "2022-05-20",
  //     "store": "us",
  //     "type": "songs",
  //     "withError": false
  //   },
  //   "appleMusicHREF": "/v1/catalog/us/songs/1615585006",
  //   "appleMusicID": "1615585006",
  //   "artistName": "Harry Styles",
  //   "artists": [
  //     "6KImCVD70vtIoJWnq6nGn3"
  //   ],
  //   "artworkURL": "https://i.scdn.co/image/ab67616d0000b2732e8ed79e177ff6011076f5f0",
  //   "genres": [
  //     "Pop",
  //     "Music"
  //   ],
  //   "href": "https://api.spotify.com/v1/tracks/1qEmFfgcLObUfQm0j1W2CK",
  //   "id": "1qEmFfgcLObUfQm0j1W2CK",
  //   "isrc": "USSM12200610",
  //   "name": "Late Night Talking",
  //   "popularity": 82,
  //   "provider": "spotify",
  //   "releaseDate": "2022-05-20",
  //   "spotifyID": "1qEmFfgcLObUfQm0j1W2CK",
  //   "store": "us",
  //   "type": "track"
  // }
]