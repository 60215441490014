import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { database, set } from '../firebase'
import Checkbox from '../components/Checkbox/Checkbox'
import Button from '../components/Button/Button'
import { useToast } from '@chakra-ui/react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Base from './base'
import { useObject } from 'react-firebase-hooks/database'
import { ref, update } from 'firebase/database'
import { Controller, useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { isActive } from '../services/helpers'
import { ReactComponent as MailSVG } from '../assets/icons/mail.svg'
import { v4 } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PhoneOrEmailVerificationModal from '../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import { mappedCountryCodes } from '../utils/countryCodes'
import { phoneMasks } from '../utils/phoneMasks'
import * as amplitude from '@amplitude/analytics-browser'
import RSVPEventDetailsView from '../components/RSVPEventDetailsView'
import EventCarouselView from '../components/EventCarouselView'
import ReactInputMask from 'react-input-mask'
import ClipLoader from 'react-spinners/ClipLoader'
import RSVPDecisionButton from '../components/RSVP/RSVPDecisionButton/RSVPDecisionButton'
import { eventTimePassed } from '../helpers'
import MuiThemeProvider from '../theme/mui'
import { sendEmail } from '../services/Support'

export const schema = yup
  .object({
    name: yup.string().required(),
    phoneNumber: yup.string(),
    email: yup.string().email(),
    countryCode: yup.object().shape({
      value: yup.string().required()
    }),
    method: yup.string().default('phone')
  })
  .required()

const NewGuest = () => {
  const { circleCode } = useParams()
  const [rsvpId, setRsvpId] = useState(null)
  const [sending, setSending] = useState(false)
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const [goingStatus, setGoingStatus] = useState(null)
  const [infoToVerify, setInfoToVerify] = useState(null)
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const eventInfo = eventSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false,
    defaultValues: {      
      method: 'phone'
    }
  })

  const [name, phoneNumber, countryCode, email, method] = form.watch([
    'name',
    'phoneNumber',
    'countryCode',
    'email',
    'method'
  ])

  const handleVerify = async () => {
    if (!checked) {
      form.setError('root', {
        message: 'Please check the checkbox if you want to proceed'
      })
      return
    }

    if (!phoneNumber && !email) {
      form.setError('root', {
        message: 'Please fill in your contact information'
      })
      return
    }

    try {
      setSending(true)

      const guestList = Object.entries(guestsInfo).map(([gKey, gData]) => ({
        id: gKey,
        ...gData
      }))

      if (method === 'phone') {
        const phone = phoneNumber.startsWith('0')
          ? `${countryCode.value}${phoneNumber.replace(/ /g, '').slice(1)}`
          : `${countryCode.value}${phoneNumber.replace(/ /g, '')}`

        // Check if phone or email exists
        const existingGuest = guestList.find((x) => x.phone === phone)
        const id = existingGuest?.id ?? v4().replace(/-/g, '')

        if (!existingGuest) {
          await set(ref(database, `circles/${circleCode}/guests/${id}`), {
            id,
            name,
            phone
          })
        }

        setRsvpId(id)
        setInfoToVerify({ info: phone, channel: 'sms' })
      } else {
        // Check if email exists
        const existingGuest = guestList.find((x) => x.email === email)
        const id = existingGuest?.id ?? v4().replace(/-/g, '')

        if (!existingGuest) {
          await set(ref(database, `circles/${circleCode}/guests/${id}`), {
            id,
            name,
            email
          })
        }

        setRsvpId(id)
        setInfoToVerify({ info: email, channel: 'email' })
      }

      setPhoneOrEmailModal(true)

      setSending(false)
    } catch (e) {
      console.log('handleAddGuest', e)
      toast({
        status: 'error',
        title: e.message || 'Error while updating the guest list',
        position: 'top'
      })
    } finally {
      setSending(false)
    }
  }

  const handleSetResponse = async () => {
    try {
      const hasGame = !!eventInfo?.squaresGameId
      const hasRequestList = !!eventInfo?.extraCollab ?? false
      const hasMusic = !!eventInfo?.musicCollab ?? false

      const response = goingStatus === false ? 'no' : 'yes'

      await update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        response
      })

      // logEvent(analytics, 'invite_response', {
      //   source: response
      // })
      amplitude.track(
        'Invite responded to',
        {
          circleCode,
          response
        },
        {
          user_id: rsvpId
        }
      )

      if (method === 'email') {
        await sendEmail('RSVP_CONFIRM', {
          rsvpId,
          code: circleCode,
          response: response,
          email,
          hostEmail: eventInfo.hostEmail,
          name: eventInfo.name,
          hostName: eventInfo.hostName,
          date: eventInfo.date,
          time: eventInfo.time,
          timezone: eventInfo.timezone,
          address: eventInfo.address
        })
      }

      form.setValue('name', '')
      form.setValue('phoneNumber', '')
      form.setValue('countryCode', mappedCountryCodes[0])
      form.setValue('method', 'email')

      if (!goingStatus) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      } else {
        if (!!hasGame) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help`)
        } else if (!!hasRequestList) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=list`)
        } else if (!!hasMusic) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help?view=music`)
        } else {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
        }
      }
    } catch (err) {
      console.log('new-guest: error seting response', err)
    }
  }

  useEffect(() => {
    if (!countryCode) {
      form.setValue('countryCode', mappedCountryCodes[0])
    }
  }, [form, countryCode])

  useEffect(() => {
    if (!eventLoading && !guestsLoading) {
      if (!eventSnap?.exists() || !isActive(eventInfo)) {
        navigate('/', { replace: true })
        return
      }
    }
  }, [
    eventSnap,
    guestsInfo,
    eventLoading,
    guestsLoading,
    eventInfo,
    navigate,
    circleCode
  ])

  useEffect(() => {
    if (method === 'email') {
      form.setValue('phoneNumber', '')
      form.setValue('countryCode', mappedCountryCodes[0])
    } else {
      form.setValue('email', '')
    }
  }, [method, form])

  useEffect(() => {
    amplitude.track('Invite viewed', {
      circleCode
    })
  }, [circleCode])

  const handlePhoneNumberMask = useMemo(() => {
    if (!countryCode) return
    const foundMask = phoneMasks.find((m) => m.value === countryCode.value)
    return foundMask ? foundMask.mask : '999999999999'
  }, [countryCode])

  if (eventLoading || guestsLoading)
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        whiteLogo
        headerBackgroundColor={'transparent'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Coteri - Respond to online invitation through invite link
          </title>
          <meta
            name="description"
            content="Enter your information to reply to the online invitation."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      headerBackgroundColor={'transparent'}
      whiteLogo
    >
      <div className="flex flex-1 flex-col md:items-stretch">
        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-2 flex-1">
            <RSVPEventDetailsView
              rsvpId={rsvpId}
              circleCode={circleCode}
              eventInfo={eventInfo}
              guestsInfo={guestsInfo}
            />
          </div>
          <div className="order-1 sm:w-[44%] w-[100%]">
            <EventCarouselView
              isGuest
              rsvpId={rsvpId}
              circleCode={circleCode}
              eventInfo={eventInfo}
              removeAttendingCard
            />
          </div>
        </div>

        {eventInfo.noteForGuests && (
          <div className="flex flex-col justify-center mt-[12px]">
            <span>Additional event information</span>
            <div className="w-full h-[2px] bg-secondary" />

            <div className="bg-off-white p-3 flex flex-col justify-center rounded-md mt-2">
              <span>{eventInfo.noteForGuests}</span>
            </div>
          </div>
        )}

        {isTimePassed ? (
          <div
            className="mt-6 w-full p-4 mb-4 text-md text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <div className="font-bold">Event Ended</div>
            <div>
              This event has ended, and responses are no longer being accepted.
            </div>
          </div>
        ) : (
          <>
            <div className="w-full mt-10 mb-10">
              <div className="text-[16px] text-primary font-semibold mb-6 sm:self-start self-center">
                Step 1: Select your response
              </div>
              <div className="w-full flex flex-col justify-between sm:flex-row gap-3">
                <RSVPDecisionButton
                  going
                  onClick={() => setGoingStatus(true)}
                  selected={goingStatus === true}
                />
                <RSVPDecisionButton
                  onClick={() => setGoingStatus(false)}
                  selected={goingStatus === false}
                />
              </div>
            </div>

            <div className="my-4">
              <div className="text-[16px] text-primary font-semibold mb-1 sm:self-start self-center">
                Step 2: Fill in your contact information
              </div>
              <p className="text-[14px] mb-6">
                You’ll receive a verification code to continue.
              </p>

              <div className="p-3 bg-off-white rounded-md">
                <div className="mb-6">
                  <div className="font-semibold text-primary mb-2">
                    Enter your name
                  </div>
                  <input
                    {...form.register('name')}
                    className={`mt-1 w-full rounded-md py-3 px-[14px] placeholder:text-primary placeholder:opacity-70 outline-none ${form.formState.errors.name?.message ? 'border-1 border-error' : ''}`}
                    placeholder="Enter name"
                    type="text"
                  />
                </div>

                <div className="font-semibold text-primary mb-3">
                  Choose a verification method
                </div>

                <div className="px-[9px] bg-white rounded-md mb-2">
                  <select
                    {...form.register('method')}
                    className="w-full text-start py-4 outline-none bg-white px-[1px]"
                  >
                    <option value="email">Email</option>
                    <option value="phone">Phone number</option>
                  </select>
                </div>

                <MuiThemeProvider>
                  {method === 'phone' ? (
                    <div className="flex flex-col sm:flex-row gap-2">
                      <div className="w-full sm:w-[250px] relative">
                        <Controller
                          name="countryCode"
                          control={form.control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Autocomplete
                                id="countryCode"
                                sx={{ width: '100%' }}
                                options={mappedCountryCodes}
                                value={value}
                                placeholder="Select country"
                                disableClearable
                                onChange={(_, v) => onChange(v)}
                                getOptionLabel={(option) => option?.label ?? ''}
                                renderOption={(props, option) => {
                                  const { key, ...optionProps } = props
                                  return (
                                    <Box
                                      key={key}
                                      component="li"
                                      autoFocus={false}
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 }
                                      }}
                                      {...optionProps}
                                    >
                                      {option.label}
                                    </Box>
                                  )
                                }}
                                renderInput={(params) => {
                                  console.log('new-guest: ', params)
                                  return (
                                    <TextField
                                      {...params}
                                      slotProps={{
                                        htmlInput: {
                                          ...params.inputProps,
                                          autoComplete: 'new-password' // disable autocomplete and autofill
                                        }
                                      }}
                                      sx={{
                                        backgroundColor: '#FFFFFF !important',
                                        borderRadius: '8px !important'
                                      }}
                                    />
                                  )
                                }}
                              />
                            )
                          }}
                        />
                      </div>
                      <Controller
                        name={'phoneNumber'}
                        control={form.control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactInputMask
                              mask={handlePhoneNumberMask}
                              className={`flex flex-1 rounded-md py-3 px-[14px] min-w-[40px] placeholder:text-primary placeholder:opacity-70 outline-none ${form.formState.errors.phoneNumber?.message ? 'border-1 border-error' : ''}`}
                              type="tel"
                              placeholder="Enter phone number"
                              onChange={onChange}
                              value={value}
                              autoComplete="off"
                              maskChar={''}
                            />
                          )
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <div className="flex items-center justify-center w-[55px] shrink-0 rounded-md bg-white">
                        <MailSVG style={{ width: 20, height: 20 }} />
                      </div>
                      <input
                        {...form.register('email')}
                        className={`w-full rounded-md py-3 px-[14px] placeholder:text-primary placeholder:opacity-70 outline-none ${form.formState.errors.email?.message ? 'border-1 border-error' : ''}`}
                        placeholder="Enter email"
                        type="email"
                      />
                    </div>
                  )}
                </MuiThemeProvider>

                <Checkbox
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  text={`I consent to receive ${method === 'phone' ? 'texts' : 'emails'} from Coteri. This is for the purpose of sending verification codes, and will not be used to send marketing content or shared with other businesses or entities.`}
                  className={'mt-4 items-start'}
                  textClassName={'text-[14px]'}
                  width={30}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {!!form.formState.errors
        ? Object.entries(form.formState.errors).map(([errKey, errMessage]) => (
            <div key={errKey} className="text-red-400">
              {errMessage.message ?? ''}
            </div>
          ))
        : null}

      {isTimePassed ? null : (
        <div className="flex items-center mt-4 justify-end w-full mb-6">
          <Button
            text={'Continue'}
            size="medium"
            onClick={form.handleSubmit(handleVerify)}
            disabled={sending}
            loading={sending}
          />
        </div>
      )}

      <PhoneOrEmailVerificationModal
        opened={phoneOrEmailModal}
        circleCode={circleCode}
        rsvpId={rsvpId}
        infoToVerify={infoToVerify}
        onClose={() => {
          setPhoneOrEmailModal(false)
          setInfoToVerify(null)
        }}
        onVerified={handleSetResponse}
      />
    </Base>
  )
}

export default NewGuest
