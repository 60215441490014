import React from 'react'
import Card from './Card'
import SectionHeader from '../SectionHeader/SectionHeader'

const PerfectInvite = () => {
  return (
    <SectionHeader title={"Build your perfect invite"} subtitle={ <span className='text-white break-point-900:text-[23px] text-[20px] mt-1'>Design it how <span className='italic'>you</span> want</span>}>
      <div className='flex items-center justify-between gap-6 break-point-900:flex-row flex-col'>
        <Card 
          className={'break-point-900:max-w-[638px] max-w-auto'} 
          title={'Card designs for any event'} 
          description={'Upload a design, select one of ours, or design your own. Coteri integrates with Canva to provide you with thousands of free templates.'}
          backgroundObjectClass={'break-point-900:max-w-[350px] max-w-[200px] opacity-30 w-full'}
        />
        
        <Card 
          className={'break-point-900:max-w-[436px] max-w-auto'} 
          title={'“What to bring” lists'} 
          description={'Coordinate with your guests on food, drinks, and more. Add items to your list for guests to pick from.'} 
          backgroundObjectClass={'max-w-[200px] bg-[#DD94F7] opacity-40 w-full'}
        />
      </div>

      <div className='flex items-center justify-between gap-6 mt-6 break-point-900:flex-row flex-col'>
        <Card 
            className={'break-point-900:max-w-[436px] max-w-auto'} 
          title={'Track RSVPs'} 
          description={'See who is going, who can’t make it, and who hasn’t responded. Copy the invite link and send via email or text, or send invites through our email distribution tool.'} 
          backgroundObjectClass={'max-w-[200px] bg-[#3E7CFA] opacity-40 w-full'}
        />

        <Card 
          className={'break-point-900:max-w-[638px] max-w-auto'} 
          title={'Curate music playlists with AI'}             
          description={"A guided process helps you craft a playlist your guests will love. Invite them to add music and let AI do the work to generate the perfect song to match your party’s vibes."}
          backgroundObjectClass={'break-point-900:max-w-[350px] max-w-[200px] bg-[#5B4ABC] opacity-40 w-full'}
        />
      </div>
    </SectionHeader>
  )
}

export default PerfectInvite
