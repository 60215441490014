import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../Button/Button'
import { Grid, useToast } from '@chakra-ui/react'
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage'
import { database, storage } from '../../../firebase'
import Modal from '../../Modal/Modal'
import { ref, update } from 'firebase/database'
import { XIcon, UploadIcon } from '../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import { ArrowDownIcon } from '../../../assets/icons'
import OptionImage from './OptionImage/OptionImage'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { categories } from '../../../utils/categories'

export const schema = yup
  .object({
    ocassion: yup.object()
  })
  .required()

const PhotoOrVideoModal = ({
  isOpen,
  handleOnClose,
  removeDesignOnCanva,
  onSelectImageUrl,
  onSelectImageFiles,
  action,
  ocassion: formOcassion
}) => {
  const fileInputRef = useRef(null)

  const options = categories.filter((c) => c.value !== 'other')

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [ocassion] = form.watch(['ocassion'])

  const selectedOcassion = useMemo(
    () => options.find((option) => option.value === ocassion),
    [options, ocassion]
  )

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event, filesToUpload) => {
    const files = filesToUpload || Array.from(event.target.files)

    if (files.length === 0 || files.length > 1) return

    onSelectImageFiles(files)
  }

  useEffect(() => {
    if (formOcassion) {
      form.setValue('ocassion', formOcassion)
      return
    }

    form.setValue('ocassion', options[10].value)
  }, [formOcassion])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      className={'max-w-[700px] w-full mx-5'}
    >
      <div className="p-2 bg-modal-bg rounded-input mx-3 md:mx-0 flex flex-col">
        <div className="flex justify-between mt-4 mx-5">
          <div className="gap-3 flex items-center">
            <div className="flex sm:flex-row flex-col gap-2">
              <>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
                <Button
                  type="terciary"
                  size="small"
                  text={'Upload your own design'}
                  onClick={handleButtonClick}
                  className="text-sm flex items-center"
                  iconLeft={
                    <UploadIcon
                      className="mr-1"
                      fill={'#5B4ABC'}
                      width={20}
                      height={20}
                    />
                  }
                />
              </>
              {!removeDesignOnCanva && (
                <Button
                  type="terciary"
                  size="small"
                  text={'Design on Canva'}
                  onClick={action}
                  className="text-sm"
                />
              )}
            </div>
          </div>
          <div onClick={handleOnClose} className="cursor-pointer">
            <XIcon fill={'black'} width={25} height={25} />
          </div>
        </div>

        <div className="flex flex-col px-5 items-start my-5 overflow-hidden">
          <div className="relative max-w-[300px] w-full">
            <select
              className="flex-1 py-3 px-2 pr-8 outline-none cursor-pointer bg-transparent border border-black bg-off-white rounded-md w-full appearance-none"
              {...form.register('ocassion')}
            >
              {options.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
            <ArrowDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none" />
          </div>

          <Grid
            templateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            }}
            gap={3}
            className="overflow-scroll no-scrollbar mt-3 sm:max-h-[500px] max-h-[400px] min-h-[300px] flex items-center"
          >
            {selectedOcassion?.images?.map((image, idx) => (
              <OptionImage
                key={idx}
                image={image}
                url={selectedOcassion?.urls[idx]}
                incomingKey={idx}
                handleSelectImage={onSelectImageUrl}
              />
            ))}
          </Grid>
        </div>
      </div>
    </Modal>
  )
}

export default memo(PhotoOrVideoModal)
