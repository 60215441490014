import React, { memo, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, signOut } from '../../../firebase'
import { useMediaQuery } from '../../../hook/useMediaQuery'
import LoginBox from '../../../components/LoginBox'
import RegisterBox from '../../../components/RegisterBox'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useNavigate, Link } from 'react-router-dom'
import { ReactComponent as CoteriGlow } from '../../../assets/icons/coteri-glow.svg'
import {    
  Menu,  
  MenuItem,
  MenuList,  
} from '@chakra-ui/react'
import MenuButton from '../../MenuButton/MenuButton'
import Button from '../../Button/Button'

const Header = ({ isFaq }) => {   
  const [showPopUp, setShowPopUp] = useState(null)
  const [canvaPopUp, setCanvaPopUp] = useState(null)
  const [redirect, setRedirect] = useState(null)
  
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const breakpoints = {
    sm: 640
  }

  const { sm } = useMediaQuery(breakpoints)

  const handleSignOut = async () => {
    // removeSettingsData()
    await signOut(auth)
  }

  const handleCreateEvent = () => {
    if (!user || user.isAnonymous) {
      setShowPopUp('register')
      return
    }

    navigate('/event/new')
  }  

  const handleSuccess = () => {
    setShowPopUp(null)
    if (!!redirect) {
      if (redirect.startsWith('canva=')) {
        setCanvaPopUp(redirect.substring(6, redirect.length - 1))
        return
      }
      navigate(redirect)
    }
  }

  const handleMyEvents = () => {
    // logEvent(analytics, 'menu_clicked', {
    //   source: 'MY_EVENTS'
    // })
    navigate('/event-history')
  }


  return (
    <div>
      <div
        id="header"
        className="w-[100%] h-20 flex items-center justify-between sm:px-10 px-5 relative bg-[#02000B] border-b-1 border-b-[#28272E]"
        style={{ zIndex: 10 }}
      >
        <div className="absolute w-full h-[160px] bg-gradient-to-b from-[#5b4abc33] to-transparent bottom-[-161px] left-0" />

        <Link to={'/'} style={{ zIndex: 10 }}>
          <CoteriGlow          
            className="sm:w-[180px] sm:h-[180px] w-[140px] h-[140px] -ml-5"            
          />
        </Link>
        
        <div className="flex flex-row items-center sm:gap-6 gap-0">
          {!!user && !user?.isAnonymous && (
            <button className='px-6 py-3 text-white hover:opacity-80 transition-all duration-200 sm:visible sm:flex hidden active:opacity-50' onClick={handleMyEvents}>
              My Events
            </button>
          )}          
          
          {(!user || user?.isAnonymous) && (
            <Button type={'light-purple'}  size={sm ? 'medium-2' : "extra-small"} onClick={() => setShowPopUp('login')} text={' Log in'} className={'sm:mr-0 mr-2'} />
          )}

          {sm && <Button type={'purple'} size="medium-2" onClick={handleCreateEvent} text={'Create your event'} />}
          
          <Menu>
            <MenuButton />
            <MenuList zIndex="99">                         
              <Link to="/superbowl/blog">
                <MenuItem>Super Bowl</MenuItem>
              </Link>
              {(!user || user.isAnonymous) && (
                <>
                  <MenuItem onClick={() => setShowPopUp('login')}>Sign in</MenuItem>
                  <MenuItem onClick={() => setShowPopUp('register')}>Sign up</MenuItem>
                </>
              )}
              {(!sm && user) && (
                <>
                  <Link to="/event-history">
                    <MenuItem>My events</MenuItem>
                  </Link>
                  <Link to="/event/new">
                    <MenuItem>Create event</MenuItem>
                  </Link>
                </>
              )}
              {!!user && !user.isAnonymous && (
                <Link to="/settings">
                  <MenuItem>My profile</MenuItem>
                </Link>
              )}
              {!!user && !user.isAnonymous && (
                <MenuItem onClick={handleSignOut}>Log out</MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>        
      </div>        

      <Modal isCentered isOpen={!!showPopUp} onClose={() => setShowPopUp(null)}>
        <ModalOverlay />
        <ModalContent py="12px" mx="20px">
          <ModalCloseButton mt="4px" mr="0" />
          <ModalBody>
            {showPopUp === 'login' && (
              <LoginBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'register' && (
              <RegisterBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default memo(Header)
