import React from 'react'
import CoteriHeaderPhone from '../../../assets/images/coteri-header-phone.jpg'
import Button from '../../Button/Button'

const eventNames = [
  'Holiday Party',
  'Birthday Party',
  'Dinner Party',
  'BBQ Party',
  'Baby Shower',
  'Bridal Shower'
]

const CreateInvite = ({ handleCreateEvent }) => {
  return (
    <div className="w-full flex justify-center bg-dark-bg break-point-900:pt-[100px] pt-10">
      <div className="flex break-point-900:flex-row flex-col bg-[#02000B] max-w-[1200px] w-full justify-between items-center sm:px-10 px-0">
        <div className="flex flex-col max-w-[440px] mr-5 w-full">
          <h2 className="break-point-900:text-[48px] text-[32px] text-white !font-medium break-point-900:text-left text-center sm:px-0 px-10">
            Create an invite for <br /> any event
          </h2>

          <span className="text-white break-point-900:text-lg text-md mt-6 break-point-900:text-left text-center">
            Digitally invite people to your
          </span>

          <div className="w-full inline-flex flex-nowrap overflow-hidden break-point-900:[mask-image:_linear-gradient(to_right,black_0px,black_80px,transparent_calc(100%-50px),transparent_100%)] [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
            <ul className="flex items-center justify-center md:justify-start [&_li]:mx-2 [&_img]:max-w-none animate-infinite-scroll">
              {eventNames.map((event, index) => (
                <li key={index} className="text-white text-nowrap">
                  {event}
                </li>
              ))}
            </ul>
            <ul
              className="flex items-center justify-center md:justify-start [&_li]:mx-2 [&_img]:max-w-none animate-infinite-scroll"
              aria-hidden="true"
            >
              {eventNames.map((event, index) => (
                <li key={index} className="text-white text-nowrap">
                  {event}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col max-w-[320px] break-point-900:self-start self-center">
            <Button
              size="medium-2"
              type="purple"
              text="Create your digital invite"
              onClick={() => handleCreateEvent('/event/new')}
              className={'mt-5'}
            />

            <span className="text-center text-[#C3C3C3] mt-2 break-point-900:text-[14px] text-[12px]">
              It’s fast, easy, and free*
            </span>
          </div>
        </div>

        <div className="relative overflow-hidden w-[100%] sm:mt-0 mt-5">
          <img
            src={CoteriHeaderPhone}
            className="w-full"
            alt="Phone Header"
          />
        </div>
      </div>
    </div>
  )
}

export default CreateInvite
