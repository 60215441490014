import React from 'react'
import Ocassion from '../../../assets/icons/ocassion'
import { categories } from '../../../utils/categories'
import { Tooltip } from '@chakra-ui/react'
import Input from '../../Input/Input'
import { useWatch } from 'react-hook-form'

const NewEventOcassion = ({ form }) => {
  const [fieldOcassion, fieldOcassionOther] = useWatch({
    name: ['ocassion', 'ocassionOther'],
    control: form.control
  })

  return (
    <div
      className={`${form.formState.errors.ocassion ? 'border-error' : 'bg-gray'} bg-off-white flex flex-col justify-center mt-[12px] gap-3 rounded-md border-1 cursor-pointer shadow-purple`}
    >
      <Tooltip label="Select ocassion">
        <div className="flex relative w-full">
          <div className="flex items-center left-3 top-0 bottom-0 absolute">
            <Ocassion fill="#5b4abc" />
          </div>
          {!!fieldOcassion ? (
            <select
              className="grow p-3 mr-3 pl-11 h-12 flex-1 outline-none cursor-pointer bg-transparent"
              {...form.register('ocassion')}
              autoFocus
            >
              <option value={null}></option>
              {categories.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
          ) : (
            <div
              className="grow text-primary p-3 pl-11 font-semibold hover:underline"
              onClick={() => form.setValue('ocassion', categories[10].value)}
            >
              Select an ocassion
            </div>
          )}
        </div>
      </Tooltip>
      {fieldOcassion === 'other' && (
        <div className="p-3 pt-1">
          <Input
            type="multiline-fixed-placeholder"
            placeholder="What’s the occasion? How do you plan to use Coteri?"
            fixedPlaceholder={'Occasion *'}
            register={form.register('ocassionOther')}
            hasError={form.formState.errors.ocassionOther?.message}
            form={form}
          />
        </div>
      )}
    </div>
  )
}

export default NewEventOcassion
