import React from 'react'
import SuperBowlImage from '../../../assets/images/home/super-bowl.png'
import SectionHeader from '../SectionHeader/SectionHeader'
import { useNavigate } from 'react-router-dom'
import Button from '../../Button/Button'

const PlanSuperBowl = ({ handleCreateEvent }) => {
  const navigate = useNavigate()
  
  return (
    <SectionHeader title={"Plan your Super Bowl"} subtitle={"Elevate your game day"}>
      <div className='flex break-point-900:flex-row flex-col gap-10'>          
        <img
          src={SuperBowlImage}
          className="rounded-lg shadow-white w-full h-auto break-point-900:max-w-[638px] max-w-full min-w-0"
        />
      
        <div className='relative flex-1 h-full break-point-900:max-w-[436px] max-w-full w-full min-w-[300px]'>
          <div className={'h-[200px] break-point-900:w-[300px] w-[250px] bg-super-bowl-purple absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 blur-3xl z-10'} />

          <div className={'p-6 h-full rounded-lg bg-[#D9D9D9] relative bg-clip-padding backdrop-filter backdrop-blur-2xl bg-opacity-10 flex flex-1 flex-col z-10'}>
            <h2 className='mb-4 text-super-bowl-purple !font-semibold break-point-900:text-[32px] text-[22px]'>Coming soon!</h2>

            <h3 className='mb-4 text-white break-point-900:text-[20px] text-[16px] leading-5 !font-semibold'>Get Ready for Game Day Introducing Super Bowl Squares</h3>

            <h4 className='mb-4 text-white !font-normal text-[15px]'>With Coteri’s easy-to-use platform, guests can join in on the fun, track their squares real-time, and take the competition to the next level - all while you focus on hosting the perfect party.</h4>

            <span className='mb-4 text-white underline cursor-pointer' onClick={() => navigate('/superbowl/blog')}>How does it work?</span>

            <div className='flex flex-1 items-end justify-end'>
              <Button type={"purple"} size={'medium-2'} text="Create your event" onClick={() => handleCreateEvent('/event/new?view=super-bowl')} />             
            </div>
          </div>
        </div>
      </div>
    </SectionHeader>    
  )
}

export default PlanSuperBowl
