import React, { memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  Text,
  Container,
  Flex,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  Textarea,
  ModalContent,
  useToast
} from '@chakra-ui/react'
import Button from '../Button/Button'
import { sendIssue } from '../../services/Support'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { LogoIcon } from '../../assets/icons'

const Footer = ({ className }) => {
  const navigate = useNavigate()
  const toast = useToast()

  const [user] = useAuthState(auth)

  const [issue, setIssue] = React.useState('')
  const [isReporting, setReporting] = React.useState(false)

  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport
  } = useDisclosure()

  const handleTermAndCondition = () => {
    navigate('/terms')
  }

  const handlePrivacyAndPolicy = () => {
    navigate('/privacy')
  }

  const handleHelp = () => {
    setIssue('')
    onOpenReport()
  }

  const handleReport = async () => {
    try {
      setReporting(true)
      await sendIssue(user?.uid || '1', user?.email || '', issue)
      onCloseReport()
      setIssue('')
      toast({
        title: `We have recieved your issue!`,
        position: 'top',
        status: 'success',
        isClosable: true
      })
      setReporting(false)
    } catch (e) {
      setReporting(false)
      console.log('handleReport e', e)
      toast({
        title: e.message || 'Error while sending request',
        position: 'top',
        status: 'error'
      })
    }
  }

  return (
    <Flex
      gap="6px"
      bg="#02000B"
      pt="50px"
      pb="30px"
      position="relative"
      flexDirection={{ base: 'column', sm: 'row' }}
      className={className}
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        w="100%"
        maxW="1200px"
        px={{ base: '16px', md: '36px' }}
      >
        <Flex gap="10px" alignItems="center" mb="60px">
          <Flex>

            <LogoIcon className="w-[137px] h-[37px]" />
          </Flex>
          <Flex flex="1" h="1px" bg="#5B4ABC" />
        </Flex>
        <Flex gap="10px" justifyContent="space-between" mb="60px">
          <Flex gap="10px" flexDirection="column">
            <Text fontSize="15px" color="white" fontWeight="700" mb="10px">
              About Coteri
            </Text>
            {/* <Link to="/blog">
              <Text fontSize="15px" color="white">
                Blog
              </Text>
            </Link> */}
            <div onClick={handleHelp} className='cursor-pointer'>
              <Text fontSize="15px" color="white">
                Help
              </Text>
            </div>
          </Flex>
          <Flex gap="10px" flexDirection="column">
            <Text fontSize="15px" color="white" fontWeight="700" mb="10px">
              Support
            </Text>
            <a href="mailto:support@coteri.us">
              <Text fontSize="15px" color="white">
                Feedback
              </Text>
            </a>
          </Flex>
          <Flex gap="10px" flexDirection="column" alignItems={'end'}>
            <Text fontSize="15px" color="white" fontWeight="700" mb="10px">
              Connect
            </Text>
            <a href="https://www.linkedin.com/company/coteri-services/">
              <Text fontSize="15px" color="white">
                LinkedIn
              </Text>
            </a>
            <a href="https://www.instagram.com/cotericonnection?igsh=Z3VmZ2dtOWc3cjRr&utm_source=qr">
              <Text fontSize="15px" color="white">
                Instagram
              </Text>
            </a>
          </Flex>
        </Flex>
        <Flex
          flex="1"
          pt="20px"
          gap="20px"
          borderTop="1px"
          borderColor="#5B4ABC"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex flex="1">
            <Text fontSize="15px" color="white">
              © 2024 Coteri Services LLC | All rights reserved.
            </Text>
          </Flex>
          <Flex
            gap="20px"
            alignItems="flex-start"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <Text
              fontSize="15px"
              fontWeight="400"
              color="white"
              className="cursor-pointer"
              onClick={handleTermAndCondition}
            >
              Terms & Conditions
            </Text>
            <Text
              fontSize="15px"
              fontWeight="400"
              color="white"
              className="cursor-pointer"
              onClick={handlePrivacyAndPolicy}
            >
              Privacy Policy
            </Text>
          </Flex>
        </Flex>
      </Container>

      <Modal isOpen={isReportOpen} onClose={onCloseReport} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please provide details on the issue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              name="issue"
              h="130px"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            />
          </ModalBody>
          <div
            className={`flex items-center ${isReporting ? 'justify-center' : 'justify-between'} px-[30px] py-4`}
          >
            {isReporting ? (
              <ClipLoader color="#5B4ABC" />
            ) : (
              <>
                <Button
                  type="text"
                  text={'Cancel'}
                  size={'medium'}
                  onClick={onCloseReport}
                />
                <Button
                  text={'Submit'}
                  size={'medium'}
                  onClick={handleReport}
                />
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default memo(Footer)
