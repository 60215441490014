import React from 'react'
import { MusicFlowIcon } from '../../../assets/icons'
import MusicCard from '../../../components/EventDetails/MusicCard/MusicCard'
import { musics } from './musics.ts'
import SectionHeader from '../SectionHeader/SectionHeader.js'

const CraftUltimateMusic = () => {
  return (
    <SectionHeader title={<span>Craft the ultimate music playlist for your party <span className='text-light-purple-3'>with AI</span></span>} subtitle={"You pick the vibe, we do the rest"}>
      <div className='break-point-900:invisible break-point-900:h-0 flex visible flex-col'>
        {musics.map((music, index) => (
          <MusicCard
            key={music.id}
            music={music}
            className="mb-3 shadow-white"
            checked={index === 0 || index === 1}      
            disabled={true}       
          />
        ))}
      </div>  

      <div 
        className='w-full bg-white rounded-md px-6 pt-6 flex break-point-900:flex-row flex-col break-point-900:h-[585px] h-auto break-point-900:gap-0 break-point-900:mt-0 mt-5'
        style={{
          background: 'linear-gradient(to bottom right, white 50%, #DD94F7 110%)',
        }}
      >    
        <div className='flex flex-col justify-between gap-14 w-full break-point-900:h-full h-auto break-point-900:max-w-[259px] max-w-full break-point-900:mb-0 mb-14'>
          <div className='w-full'>
            <p className='text-primary font-bold'>Step 1</p>

            <p className='font-semibold break-point-900:text-[24px] text-[20px]'>Add a music playlist to your event</p>

            <h4 className='!font-normal text-[15px]'>Once your event is created, you can add a music playlist. Simply click the feature to begin the AI process.</h4>
          </div>

          <div className='w-full break-point-900:mb-16 mb-0'>
            <p className='text-primary font-bold'>Step 2</p>

            <p className='font-semibold break-point-900:text-[24px] text-[20px]'>Set the vibe</p>

            <h4 className='!font-normal text-[15px]'>Choose a few songs to include in your event playlist. Coteri will use AI to generate personalized song suggestions for your guests based on your selections.</h4>
          </div>
        </div>

        <div className="break-point-900:flex-1 h-0 flex-col mx-5 break-point-900:min-w-[350px] break-point-900:visible break-point-900:flex invisible">
          {musics.map((music, index) => (
            <MusicCard
              key={music.id}
              music={music}
              className="mb-3"
              checked={index === 0 || index === 1}      
              disabled={true}       
            />
          ))}          
        </div>

        <div className='flex flex-col justify-between gap-14 w-full break-point-900:h-full h-auto break-point-900:max-w-[259px] max-w-full'>
          <div className='w-full'>
            <p className='text-primary font-bold'>Step 3</p>

            <p className='font-semibold break-point-900:text-[24px] text-[20px]'>Send the invites</p>

            <h4 className='!font-normal text-[15px]'>During the RSVP process, your guests will be prompted with songs to add to the playlist.</h4>
          </div>

          <div className='w-full break-point-900:mb-16 mb-8'>
            <p className='text-primary font-bold'>Step 4</p>

            <p className='font-semibold break-point-900:text-[24px] text-[20px]'>Start listening</p>

            <h4 className='!font-normal text-[15px]'>To open the playlist, click on the “Listen on Spotify” button on your event page.</h4>
            
            <MusicFlowIcon height={172} className={'w-full mb-[-50px]'} />              
          </div>
        </div>
      </div>
    </SectionHeader>
   
  )
}

export default CraftUltimateMusic
