import { HamburgerIcon } from '@chakra-ui/icons'
import { IconButton, MenuButton as ChakraMenuButton } from '@chakra-ui/react'
import React from 'react'

const MenuButton = () => {
  return (
    <ChakraMenuButton
      as={IconButton}
      aria-label="Options"
      icon={<HamburgerIcon color={'white'} fontSize={25} />}
      _hover={{
        backgroundColor: 'transparent'
      }}
      _active={{
        backgroundColor: 'transparent'
      }}
      backgroundColor={'transparent'}
    />
  )
}

export default MenuButton
