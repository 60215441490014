import { Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { CostIcon } from '../../../assets/icons'
import NewEventCostModal from './NewEventCostModal'
import queryString from 'query-string'

const NewEventCost = ({ form, costPerSquare, fixedPrice, hasPaymentOption, noFixedPrice }) => {
  const { costPerGuest } = queryString.parse(window.location.search);
  const editCostPerGuest = !!costPerGuest

  const [opened, setOpened] = useState(false)

  const onClick = () => {
    setOpened(true)
  }

  useEffect(() => {
    if (editCostPerGuest) {
      setOpened(true)
    }
  }, [editCostPerGuest])

  return (
    <React.Fragment>
      <NewEventCostModal
        hasOldCost={hasPaymentOption}
        opened={opened}
        onClose={() => setOpened(false)}
        form={form}
      />

      <div>
        <Tooltip label="Insert your costs">
          <div
            className={`bg-off-white p-3 flex flex-row justify-between mt-[12px] rounded-md border-1 shadow-purple`}
            onClick={onClick}
          >
            <div className="flex items-center">
              <CostIcon className="w-[24px] h-[24px]" fill={'#5B4ABC'} />
              <span
                className={`ml-[8px] text-primary font-semibold ${!costPerSquare && 'hover:underline cursor-pointer'}`}
              >
                {hasPaymentOption
                  ? noFixedPrice ? `View or edit payment options` : `$${costPerSquare} per guest`
                  : '+ Cost per guest'}
              </span>
            </div>

            {costPerSquare && !noFixedPrice && (
              <span className={` text-primary underline cursor-pointer`}>
                Edit payment options
              </span>
            )}
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  )
}

export default NewEventCost
