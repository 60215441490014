import React, { useState } from 'react'
import Button from '../../Button/Button'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../firebase'
import {
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import LoginBox from '../../LoginBox'
import RegisterBox from '../../RegisterBox'

const SuperBowlBlogBanner = () => {
  const [showPopUp, setShowPopUp] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const url = '/event/new?view=super-bowl'

  const createEvent = async () => {
    if (!user || user.isAnonymous) {
      setRedirect(url)
      setShowPopUp('register')
      return
    }

    navigate(url)
  }

  const handleSuccess = () => {
    setShowPopUp(null)

    if (!!redirect) {
      navigate(redirect)
    }
  }

  return (
    <>
      <Modal isCentered isOpen={!!showPopUp} onClose={() => setShowPopUp(null)}>
        <ModalOverlay />
        <ModalContent py="12px" mx="20px">
          <ModalCloseButton mt="4px" mr="0" />
          <ModalBody>
            {showPopUp === 'login' && (
              <LoginBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'register' && (
              <RegisterBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="bg-off-white rounded-md flex flex-col items-center p-3 cursor-pointer">
        <span className="text-primary text-[20px] font-bold mb-5">
          Plan your Super Bowl today!
        </span>

        <Button text={'Play Super Bowl Squares'} onClick={createEvent} />
      </div>
    </>
  )
}

export default SuperBowlBlogBanner
