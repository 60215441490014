import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Country, State } from 'country-state-city'
import moment from 'moment'

// export const formatPhoneOrEmail = (input) => {
//   let isNum = /^[0-9]+|\(|\)$/.test(input)
//   if (!isNum) return input.replace(/[() ]/g, '')
//   let num = input.replace(/\D/g, '')
//   let size = num.length
//   if (size > 0) {
//     num = '(' + num
//   }
//   if (size > 3) {
//     num = num.slice(0, 4) + ') ' + num.slice(4, 11)
//   }
//   if (size > 6) {
//     num = num.slice(0, 9) + '-' + num.slice(9)
//   }
//   return num
// }

export const formatAddress = (address, props = null) => {
  const { label, address2, city, state, country, value, latlng } = address || {}
  if (!label || !value || !state || !country)
    return <Text fontSize="18px" color="black" p="0" cursor="default"></Text>
  const countryInfo = Country.getCountryByCode(country)
  const stateInfo = State.getStateByCodeAndCountry(state, country)
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={
        latlng
          ? `https://www.google.com/maps/search/?api=1&query=${latlng?.lat}%2C${
              latlng?.lng
            }${value.place_id ? `&query_place_id=${value.place_id}` : ''}`
          : value.place_id
            ? `https://www.google.com/maps/search/?api=1&query_place_id=${value.place_id}`
            : null
      }
    >
      <Flex direction="column" _hover={{ textDecoration: 'underline' }}>
        {address2 ? (
          <Text fontSize="18px" color="black" p="0" {...props}>
            {address2}
          </Text>
        ) : null}
        <Text fontSize="18px" color="black" p="0" {...props}>
          {label}
        </Text>
        <Text fontSize="18px" color="black" p="0" {...props}>
          {city}
          {', '}
          {stateInfo?.name || ''}
          {', '}
          {countryInfo?.name || ''}
        </Text>
      </Flex>
    </a>
  )
}

export const eventTimePassed = (event) => {
  if (!event) return true
  const { date, time, timezone } = event ?? {}
  return !!date && !!time && !!timezone
    ? moment().valueOf() >=
        moment
          .tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', timezone)
          .add(2, 'hours')
          .valueOf()
    : true
}

export const getPacakge = (oldPaid = 0, length = 0, maxSize = '10') => {
  const packages = ['10', '20', '40', '2500']
  const cost = getCost(maxSize)
  if (length > parseInt(maxSize)) {
    const idx = packages.findIndex((x) => parseInt(x) >= length)
    const newMaxSize = idx > -1 ? packages[idx] : packages[packages.length - 1]
    const newCost = getCost(newMaxSize)
    const toBePaid = newCost - oldPaid
    return {
      newSize: newMaxSize,
      paymentLink: getPaymentlink(newMaxSize, toBePaid),
      cost: newCost,
      toBePaid
    }
  }
  return {
    newSize: maxSize,
    paymentLink: getPaymentlink(maxSize, 0),
    cost,
    toBePaid: cost - oldPaid
  }
}

export const getCost = (size = '10') => {
  switch (size) {
    case '10':
      return 0
    case '20':
      return 5
    case '40':
      return 12
    case '2500':
      return 25
    default:
      return 0
  }
}

export const getPaymentlink = (size = '10', remaining = 0) => {
  switch (size) {
    case '10':
      return null
    case '20':
      return `https://buy.stripe.com/7sI2bkgsG9hb0IU5kF`
    case '40':
      if (remaining === 7) return 'https://buy.stripe.com/00g03c1xMeBvgHSfZl'
      return `https://buy.stripe.com/fZe2bk90egJDgHScN8`
    case '2500':
      if (remaining === 13) return 'https://buy.stripe.com/4gwdU20tIeBvgHSfZn'
      if (remaining === 20) return 'https://buy.stripe.com/aEU3fo1xMctndvG5kK'
      return `https://buy.stripe.com/eVa5nwa4ifFz63e5kI`
    default:
      return null
  }
}
