import React from 'react'

const SectionHeader = ({ title, subtitle, children }) => {
  return (
    <div className='w-full flex justify-center bg-dark-bg pt-[85px] pb-[50px]'>
      <div className="flex flex-col bg-[#02000B] max-w-[1200px] w-full px-10">
        <div className='flex flex-col items-center justify-center mb-12'>
          <h2 className='text-white break-point-900:text-[42px] text-[32px] text-center !font-semibold'>{title}</h2>

          <h3 className='text-white break-point-900:text-[23px] text-[20px] mt-1 text-center !font-normal'>{subtitle}</h3>
        </div>


        {children}
      </div>
    </div>
  )
}

export default SectionHeader
