import axios from 'axios'
import qs from 'qs'
import { database, updateProfile, updateSettings } from '../../firebase'
import base64 from 'base-64'
import { prepareTrack } from '../Coteri'
import { ref, update } from 'firebase/database'

const getHeaders = (token, json = true) => {
  return {
    headers: {
      'Content-Type': json
        ? 'application/json'
        : 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
}


const getSpotifyUser = async (accessToken) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    'https://api.spotify.com/v1/me',
    headersConfig
  )
  return response.data
}

const getSpotifyToken = async (authorization_code) => {
  const location = typeof window != null ? window.location.origin : ''
  const clientID = process.env.REACT_APP_SPOTIFY_CLIENT_ID
  const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET_ID

  const headersConfig = {
    headers: {
      Authorization: `Basic ${base64.encode(`${clientID}:${clientSecret}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const requestBody = {
    grant_type: 'authorization_code',
    code: authorization_code,
    redirect_uri: `${location}/callback`
  }

  try {
    console.log('requestBody', requestBody)
    const { data } = await axios.post(
      'https://accounts.spotify.com/api/token',
      qs.stringify(requestBody),
      headersConfig
    )

    const spotifyData = await getSpotifyUser(data.access_token)

    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + data.expires_in)

    let tokenInfo = {
      spotifyID: spotifyData.id,
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      scope: data.scope,
      expires_in: data.expires_in,
      expiryDate,
      spotifyCountry: spotifyData.country || null
    }
    console.log('tokenInfo', tokenInfo)
    return tokenInfo
  } catch (error) {
    console.log('Error: spotify token', error)
    return null
  }
}

const refreshSpotifyToken = async (refreshToken) => {
  try {
    const clientID = process.env.REACT_APP_SPOTIFY_CLIENT_ID
    const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET_ID

    const headersConfig = {
      headers: {
        Authorization: `Basic ${base64.encode(`${clientID}:${clientSecret}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const requestBody = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }

    const response = await axios.post(
      'https://accounts.spotify.com/api/token',
      qs.stringify(requestBody),
      headersConfig
    )
    const accessToken = response.data.access_token
    const scope = response.data.scope
    const expires_in = response.data.expires_in
    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + expires_in)

    const spotifyUserData = await getSpotifyUser(accessToken)

    let data = {
      accessToken,
      scope,
      expires_in,
      expiryDate,
      spotifyID: spotifyUserData.id,
      spotifyCountry: spotifyUserData.country || null
    }

    await updateProfile(data)

    console.log('refreshSpotifyToken', data)
    return data
  } catch (e) {
    console.log('refreshSpotifyToken', e)
    return null
  }
}

const refreshOtherSpotifyToken = async (refreshToken, hostID = null) => {
  try {
    const clientID = process.env.REACT_APP_SPOTIFY_CLIENT_ID
    const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET_ID
    const headersConfig = {
      headers: {
        Authorization: `Basic ${base64.encode(`${clientID}:${clientSecret}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const response = await axios.post(
      'https://accounts.spotify.com/api/token',
      qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      }),
      headersConfig
    )
    const accessToken = response.data.access_token
    const scope = response.data.scope
    const expires_in = response.data.expires_in
    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + expires_in)

    const spotifyUserData = await getSpotifyUser(accessToken)

    if (!!hostID) {
      await update(ref(database, `users/${hostID}`), {
        accessToken,
        scope,
        expires_in,
        expiryDate,
        spotifyID: spotifyUserData.id,
        spotifyCountry: spotifyUserData.country || null
      })
    } else {
      await updateSettings({
        spotifyToken: accessToken,
        spotifyExpiry: expiryDate,
        spotifyID: spotifyUserData.id,
        spotifyCountry: spotifyUserData.country || null
      })
    }
    console.log('refreshOtherSpotifyToken')
    return { accessToken, spotifyID: spotifyUserData.id }
  } catch (e) {
    console.log('refreshOtherSpotifyToken', e)
    return {}
  }
}

const getSpotifyRecentlyPlayed = async (
  accessToken,
  offset = 0,
  limit = 30
) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    `https://api.spotify.com/v1/me/player/recently-played?offset=${offset}&limit=${limit}`,
    headersConfig
  )
  console.log('recentlyPlayed', response.data.items)
  const recentlyPlayed = response.data.items.map(({ track }) => track)
  // await getTracksAudioFeatures(accessToken, recentlyPlayed)
  const tracks = await getArtistInfo(accessToken, recentlyPlayed)
  const trackPromises = []
  tracks.forEach((track) => {
    trackPromises.push(prepareTrack('spotify', track))
  })
  const results = await Promise.all(trackPromises)
  return results.filter((x) => !!x)
}

const getTopItems = async (accessToken, termLength, offset = 0, limit = 30) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    `https://api.spotify.com/v1/me/top/tracks?time_range=${termLength}&offset=${offset}&limit=${limit}`,
    headersConfig
  )
  const topItems = response.data.items
  // console.log('topItems', topItems)
  // const track = await getTracksAudioFeatures(accessToken, topItems)
  const tracks = await getArtistInfo(accessToken, topItems)

  const trackPromises = []
  tracks.forEach((track) => {
    trackPromises.push(prepareTrack('spotify', track))
  })
  const results = await Promise.all(trackPromises)
  return results.filter((x) => !!x)
}

const getSpotifyPlaylistTracks = async (accessToken, playlistID) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    `https://api.spotify.com/v1/playlists/${playlistID}/tracks`,
    headersConfig
  )
  console.log('getSpotifyPlaylistTracks', response.data.items)
  const items = response.data.items.map(({ track }) => track)
  const tracks = await getArtistInfo(accessToken, items)
  const results = []

  for (const i in tracks) {
    const track = tracks[i]
    results.push(await prepareTrack('spotify', track))
    delay(1000)
  }
  return results.filter((x) => !!x)
}

const getSpotifyRecommendations = async (
  accessToken,
  tracks = [],
  offset = 0,
  limit = 30
) => {
  const headersConfig = getHeaders(accessToken)
  const tracksString = tracks
    .map((track) =>
      track.provider === 'apple_music'
        ? track.spotifyID || ''
        : track.spotifyID || track.id
    )
    .filter((x) => x !== '')
    .slice(0, 3)
    .join(',')
  const artistsString = tracks
    .map((track) => track.artists || [])
    .flat()
    .filter((x, i, arr) => arr.indexOf(x) === i)
    .slice(0, 2)
    .join(',')
  const response = await axios.get(
    `https://api.spotify.com/v1/recommendations?seed_tracks=${tracksString}${artistsString.length > 0 ? `&seed_artists=${artistsString}` : ''
    }&min_popularity=60&target_popularity=80&offset=${offset}&limit=${limit}`,
    headersConfig
  )
  const trackPromises = []
  response.data.tracks.forEach((track) => {
    trackPromises.push(prepareTrack('spotify', track))
  })
  const results = await Promise.all(trackPromises)
  return results.filter((x) => !!x)
}

const getSpotifyTrackData = async (isrc, accessToken) => {
  try {
    const headersConfig = getHeaders(accessToken)
    const response = await axios.get(
      `https://api.spotify.com/v1/search?type=track&q=isrc:${isrc}`,
      headersConfig
    )
    const data = response?.data?.tracks?.items || []
    return data.length > 0 ? data[0] : null
  } catch (e) {
    if (e.response.status === 401) {
      console.log('Error: getTrackByISRC', isrc, 401)
      return '401'
    }
    console.log('getTrackByISRC', isrc, e)
    return null
  }
}

const delay = (millis) =>
  new Promise((resolve, reject) => {
    setTimeout((_) => resolve(), millis)
  })

// const getTrackByISRC = async (isrc, headersConfig) => {
//   try {
//     const response = await axios.get(
//       `https://api.spotify.com/v1/search?type=track&q=isrc:${isrc}`,
//       headersConfig
//     )
//     const data = response?.data?.tracks?.items || []
//     if (data.length > 0) {
//       return await prepaTrack('spotify', data[0])
//     }
//     return null
//   } catch (e) {
//     console.log('getTrackByISRC', isrc, e)
//     return null
//   }
// }

const searchSpotify = async (accessToken, text, offset = 0, limit = 30) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    `https://api.spotify.com/v1/search?q=${text}&type=track,artist&offset=${offset}&limit=${limit}`,
    headersConfig
  )
  const tracks = response.data.tracks.items
  console.log('searchSpotify 2', tracks)

  const trackPromises = []
  tracks.forEach((track) => {
    trackPromises.push(prepareTrack('spotify', track))
  })
  console.log('tracks', tracks)
  const results = await Promise.all(trackPromises)
  console.log('results', results)
  return results.filter((x) => !!x)
}

const getSpotifyLikedSongs = async (accessToken, offset = 0, limit = 30) => {
  const headersConfig = getHeaders(accessToken)
  const response = await axios.get(
    `https://api.spotify.com/v1/me/tracks?offset=${offset}&limit=${limit}`,
    headersConfig
  )
  const tracks = response.data.items
  const trackPromises = []
  tracks.forEach(({ track }) => {
    trackPromises.push(prepareTrack('spotify', track))
  })
  const results = await Promise.all(trackPromises)
  return results.filter((x) => !!x)
}

const getArtistInfo = async (accessToken, trackData) => {
  const artistObj = {}
  trackData.forEach((data) => {
    data.artists.forEach((artist) => {
      artistObj[artist.id] = true
    })
  })
  const artistInfo = Object.keys(artistObj)
  const artistsIDs = []

  // Split up array into several arrays for minimal requests to Spotify API.
  // Maximum 30 artists in request
  while (artistInfo.length) {
    artistsIDs.push(artistInfo.splice(0, 29))
  }

  let artistGenres = {}
  for (let i = 0; i < artistsIDs.length; i++) {
    let requestContent = artistsIDs[i]
    artistGenres = await getArtistsGenres(
      accessToken,
      requestContent,
      artistGenres
    )
  }

  trackData.forEach((data) => {
    let genresObj = {}
    data.artists.forEach((artist) => {
      const obj = (artistGenres[artist.id].genres || []).reduce((map, obj) => {
        map[obj] = true
        return map
      }, {})
      genresObj = { ...obj }
    })
    data.genres = Object.keys(genresObj)
  })

  return trackData
}

const getArtistsGenres = async (accessToken, artistsArray, objectToFill) => {
  const headersConfig = getHeaders(accessToken)
  let url_getSeveralArtists = 'https://api.spotify.com/v1/artists?ids='
  url_getSeveralArtists = url_getSeveralArtists + artistsArray[0]

  for (let i = 1; i < artistsArray.length; i++) {
    url_getSeveralArtists = url_getSeveralArtists + '%2C' + artistsArray[i]
  }

  const response = await axios.get(url_getSeveralArtists, headersConfig)
  for (let i = 0; i < response.data.artists.length; i++) {
    let genres = []
    for (let j = 0; j < response.data.artists[i].genres.length; j++) {
      genres[j] = response.data.artists[i].genres[j]
    }
    let id = response.data.artists[i].id
    objectToFill[id] = {
      genres
    }
  }
  return objectToFill
}

const getTracksAudioFeatures = async (accessToken, trackData) => {
  const headersConfig = getHeaders(accessToken)
  let url_getAudioFeatures = 'https://api.spotify.com/v1/audio-features?ids='
  url_getAudioFeatures = url_getAudioFeatures + trackData[0].id
  for (let i = 1; i < trackData.length; i++) {
    url_getAudioFeatures = url_getAudioFeatures + '%2C' + trackData[i].id
  }

  const response = await axios.get(url_getAudioFeatures, headersConfig)
  const trackFeaturesArray = response.data.audio_features

  for (let i = 0; i < trackFeaturesArray.length; i++) {
    trackData[i].danceability = trackFeaturesArray[i].danceability
    trackData[i].energy = trackFeaturesArray[i].energy
    trackData[i].key = trackFeaturesArray[i].key
    trackData[i].loudness = trackFeaturesArray[i].loudness
    trackData[i].mode = trackFeaturesArray[i].mode
    trackData[i].speechiness = trackFeaturesArray[i].speechiness
    trackData[i].acousticness = trackFeaturesArray[i].acousticness
    trackData[i].instrumentalness = trackFeaturesArray[i].instrumentalness
    trackData[i].liveness = trackFeaturesArray[i].liveness
    trackData[i].valence = trackFeaturesArray[i].valence
    trackData[i].tempo = trackFeaturesArray[i].tempo
  }
  return trackData
}

export {
  getHeaders,
  getSpotifyToken,
  getSpotifyUser,
  searchSpotify,
  getSpotifyTrackData,
  refreshSpotifyToken,
  refreshOtherSpotifyToken,
  getSpotifyLikedSongs,
  getSpotifyRecommendations,
  getSpotifyRecentlyPlayed,
  getArtistInfo,
  getArtistsGenres,
  getTracksAudioFeatures,
  getSpotifyPlaylistTracks,
  getTopItems
}
