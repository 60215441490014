import React, { memo, useState } from 'react'
import ActiveEventsCard from '../../../../components/MyEvents/ActiveEventsCard/ActiveEventsCard'
import CancelEventModal from '../../../../components/MyEvents/CancelEventModal/CancelEventModal'

const ActiveEvents = ({ isToggled, futureEvents, setDeleteEventModal }) => {
  return (
    <div className={`${isToggled ? 'mt[10px]' : 'hidden'} flex flex-1 `}>
      {futureEvents.length === 0 ? (
        <div className="flex flex-1 items-center justify-center">
          <span>No active events</span>
        </div>
      ) : (
        <div id="header" className="flex flex-1 flex-col mt-[10px] items-center">
          {futureEvents.map((event, index) => (
            <ActiveEventsCard
              key={index}
              event={event}
              setDeleteEventModal={setDeleteEventModal}
              className={`mb-4 max-w-[650px]`}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(ActiveEvents)
