import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import EditProfileModal from '../components/Settings/EditProfileModal/EditProfileModal'
import ClipLoader from 'react-spinners/ClipLoader'
import SocialMediaCard from '../components/Settings/SocialMediaCard/SocialMediaCard'
import Edit from '../assets/icons/edit'
import Button from '../components/Button/Button'
import { Link } from 'react-router-dom'
import {
  auth,
  database,
  ref,
  sendPasswordResetEmail,
  updateAuthProfile,
  updateProfile
} from '../firebase'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  useToast,
  useDisclosure,
  Textarea
} from '@chakra-ui/react'
import Base from './base'
import { prepareSpotifyLoginUrl } from '../services/User'
import { useObject } from 'react-firebase-hooks/database'
import { sendIssue } from '../services/Support'
import { get, query } from '../firebase'
import { getHostToken } from '../services/helpers'
import { remove, update } from 'firebase/database'
import { getSpotifyPlaylistTracks } from '../services/Spotify'
import { getSavedPhone, setSavedPhone } from '../services/database'
import { Phone, PhoneOutlined } from '@mui/icons-material'
import Footer from '../components/Footer/Footer'

const Settings = () => {
  const [name, setName] = useState('')
  const [modal, setModal] = useState(null)
  const [authenticating, setAuthenticating] = useState(null)
  const [circlesHosted, setCirclesHosted] = useState('--')
  const [isReporting, setReporting] = useState(false)
  const [user, loading] = useAuthState(auth)
  const [issue, setIssue] = useState('')
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editModal, setEditModal] = useState(false)
  const [syncing, setSyncing] = useState(false)

  const savedPhone = useMemo(() => getSavedPhone() ?? {}, [setSavedPhone])

  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport
  } = useDisclosure()

  const [profileSnap, profileLoading] = useObject(
    ref(database, `users/${user?.uid || '1'}`)
  )
  const [devicesSnap, devicesLoading] = useObject(
    ref(database, `phones/${savedPhone?.phone || '1'}/devices`)
  )
  const profile = profileSnap?.val() || {}
  const devices = devicesSnap?.val() || {}

  const handleUpdateName = async () => {
    if (!name || name === '') return
    setAuthenticating('name')
    try {
      await updateProfile({
        name
      })
      await updateAuthProfile(user, { displayName: name })
    } catch (e) {
      console.log('handleUpdateName e', e)
    }
    setAuthenticating(null)
  }

  const handleChangePassword = async () => {
    await sendPasswordResetEmail(auth, user.email)
    toast({
      title: `Reset password link sent successfully`,
      position: 'top',
      status: 'success',
      isClosable: true
    })
  }

  const handleReport = async () => {
    try {
      setReporting(true)
      await sendIssue(user?.uid || '1', user?.email || '', issue)
      onCloseReport()
      setIssue('')
      toast({
        title: `We have recieved your issue!`,
        position: 'top',
        status: 'success',
        isClosable: true
      })
      setReporting(false)
    } catch (e) {
      setReporting(false)
      console.log('handleReport e', e)
      toast({
        title: e.message || 'Error while sending request',
        position: 'top',
        status: 'error'
      })
    }
  }

  const handleSpotify = async () => {
    if (!!profile.accessToken) {
      setModal({
        title: 'Disconnect Spotify',
        description:
          'Are you sure you want to disconnect your Spotify account?',

        onSubmit: async () => {
          onClose()
          setAuthenticating('spotify')
          try {
            await updateProfile({
              refreshToken: null,
              accessToken: null,
              scope: null,
              spotifyID: null,
              expires_in: null,
              expiryDate: null
            })
          } catch (e) {
            console.log('handleSpotify e', e)
          }
          setAuthenticating(null)
        }
      })
      return
    }

    setAuthenticating('spotify')
    const spotifyAuthURL = prepareSpotifyLoginUrl('settings')
    window.open(spotifyAuthURL, '_self')
    setAuthenticating(null)
  }

  const getFirstTwoLetters = () => {
    const [firstName, lastName] = profile.name.split(' ')

    const firstTwoLetters =
      (firstName ? firstName.substring(0, 1) : '') +
      (lastName ? lastName.substring(0, 1) : '')

    return firstTwoLetters.toUpperCase()
  }

  const handleEdit = () => {
    setEditModal(true)
  }

  const handleModalClose = () => {
    setEditModal(false)
  }

  const handleAppleMusic = async () => {
    if (typeof window == null) return

    if (!!profile.appleMusicToken) {
      setModal({
        title: 'Disconnect Apple Music',
        description:
          'Are you sure you want to disconnect your Apple Music account?t',
        onSubmit: async () => {
          onClose()
          setAuthenticating('apple_music')
          try {
            await window.handleAppleMusicLogout()
            await updateProfile({
              appleMusicToken: null
            })
          } catch (e) {
            console.log('handleAppleMusic e', e)
          }
          setAuthenticating(null)
        }
      })
      return
    }

    setAuthenticating('apple_music')
    try {
      await window.handleAppleMusicLogout()
      const appleMusicToken = await window.handleAppleMusicLogin()
      console.log('appleMusicToken', appleMusicToken)
      await updateProfile({
        appleMusicToken
      })
    } catch (error) {
      console.log('handleAppleMusic', error)
    }
    setAuthenticating(null)
  }

  const handleHelpAndFeedback = () => {
    setIssue('')
    onOpenReport()
  }

  const onSyncCoteriPlaylists = async () => {
    setSyncing(true)
    const { accessToken } = await getHostToken({
      accessToken:
        'BQBjM8YqjmEy5Y9BiYT1reZB_5Ttz15BsKz1RgZNvuMCc9FwX7Tzyzsr-zHdSpUAzmv2bPlaK9Csr3bCLPTPOIa8-CBCzuvsKNrGFUYMWn6OuA1FwORjRqc9EZqLATMVpXE1RPqjrjrSEIz_99GPK4FrYIu75l8Hx1CFTVrj87tYzJMlksCwyxkh0jw_LaiTTYz1qxka0zGXFsHXJ5nVpgtfWFHSu0i4HdtWkqw1kp4qHdjP4ytvFtfU8fd1W1QnG14pNe4-j9j1ThnB2WAbvw',
      refreshToken:
        'AQC1CleRkpgLSgbmLOiTuQm18boom1WDn3SZlSv2kRmctLlAwlCMUP4Mi8F3tpu0n8ZurO1mSG92s3LkEb84Lly8DgUg3JZfITbBlpYpWU1ftmWFuobgHB9YXuwckTCJQY0',
      spotifyID: '1246192547'
    })

    const playlists = [
      '4zAkxb3J6a5cM6KAP0nVGm',
      '7ejtgMFVvgow5Pk2Zq3KeG',
      '6xMqitAbn0EF8pQs5UVUcZ',
      '6oVSYR8QHmeN2tWSFqRm6o',
      '16Zwb7N1OCH4JqzOAL9FLx',
      '5pD9zzBjztHtTrABt7Abcb',
      '3gHktisWbRE7EulXb0VCVh',
      '0yN4owB2wQJ41oZFo9u8X3'
    ]

    const delay = (millis) =>
      new Promise((resolve, reject) => {
        setTimeout((_) => resolve(), millis)
      })

    const updateDB = {}
    let counter = 0
    for (const i in playlists) {
      const playlistID = playlists[i]
      const tracks = await getSpotifyPlaylistTracks(accessToken, playlistID)
      updateDB[`${playlistID}/tracks`] = tracks
      counter += 1
      delay(1000)
    }
    if (Object.keys(updateDB).length > 0 && counter === playlists.length) {
      await update(ref(database, `coteriPlaylists`), updateDB)
      toast({
        title: 'Success',
        description: `Synced Coteri playlists successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setSyncing(false)
      return
    }

    toast({
      title: 'Error',
      description: `Failed to sync Coteri Playlists.`,
      status: 'error',
      duration: 5000,
      isClosable: true
    })
    setSyncing(false)
  }

  const fetchCircles = useCallback(async () => {
    const circlesSnap = await get(
      query(ref(database, `circlesRef/${user?.uid}`))
    )
    const circlesObj = circlesSnap?.val() || {}

    const amountOfPartiesHosted = Object.keys(circlesObj).length

    setCirclesHosted(amountOfPartiesHosted)
  }, [user?.uid])

  const onRemoveDevices = useCallback(async () => {
    setSavedPhone({})
    await remove(ref(database, `phones/${savedPhone?.phone || '1'}/devices`))
  }, [savedPhone])

  useEffect(() => {
    if (modal) {
      onOpen()
    } else {
      onClose()
    }
  }, [modal])

  useEffect(() => {
    if (!loading && !profileLoading) {
      setName(profile.name)
    }
  }, [profileLoading, loading, profile.name])

  useEffect(() => {
    if (!loading) {
      if (user) {
        fetchCircles().then(() => null)
      }
    }
  }, [user, loading, fetchCircles])

  useEffect(() => {
    if ((!user || user.isAnonymous) && !loading)
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
  }, [user, loading, navigate])

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true} mainDivClassName={'bg-[#02000B]'} whiteLogo>
      <div className="flex flex-1 flex-col md:items-stretch mt-[60px]">
        {loading || !user || !!profileLoading || !!devicesLoading ? (
          <div className="flex flex-1 items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : (
          <>
            <div id="header" className="flex flex-col w-full rounded-md relative items-center justify-center">                        
              <div className="w-[120px] h-[120px] rounded-full bg-dark-bg shadow-pink flex items-center justify-center relative">
                <span className="text-[48px] text-light-purple-3">
                  {getFirstTwoLetters()}
                </span>

                <Edit
                  fill="#FFF"
                  className="cursor-pointer absolute top-0 -right-12"
                  onClick={handleEdit}
                />
              </div>

              <div className="flex flex-1 flex-col items-center mt-[20px] p-[12px]">
                <span className="text-[24px] font-medium text-white">{profile.name}</span>

                <span className='text-white'>{profile.email}</span>
                
                <Link to="/event-history">
                  <div className="mt-4 text-center text-[32px] text-white">
                    {circlesHosted}
                  </div>
                  <div className='text-white'>parties hosted</div>
                </Link>

                <Link to="/event-history" className="my-3">
                  <div className="w-full flex items-center justify-center">
                    <button 
                      className='px-8 mt-4 py-2 rounded-full text-white font-semibold border-1 border-light-purple-3 shadow-purple hover:opacity-80 transition-all duration-200 active:opacity-50'
                    >
                        View my events
                    </button>
                  </div>
                </Link>
              </div>
            </div>

            <div id="content" className="flex flex-col mt-4">
              <div
                id="socialMedia"
                className="w-full rounded-md border-1 bg-white shadow-white p-2 mt-[15px]"
              >
                <SocialMediaCard
                  value={profile.accessToken ? true : false}
                  setValue={handleSpotify}
                  type={'spotify'}
                  additionalText={
                    profile.accessToken ? 'Connected' : 'Not connected'
                  }
                />
              </div>
            </div>

            <div className="text-[15px] text-center mt-10 text-white">
              <PhoneOutlined className='mr-2'/>              
              Phone number associated with this account:
              {savedPhone.phone ?? 'None'}.
            </div>

            <div className="mt-12 flex items-center justify-center">
              <button 
                className='px-8 py-2 rounded-full text-white font-semibold border-1 border-white shadow-white hover:opacity-80 transition-all duration-200 active:opacity-50'
                onClick={handleHelpAndFeedback}
              >
                  Help and feedback
              </button>
            </div>

            <div id="content" className="flex gap-1 flex-col mt-12 pb-4">             
              {!!savedPhone.phone ? (
                <>
                  <div className="text-[15px] font-semibold text-center text-white">
                    Want to reset your trusted device and remove your phone
                    number?
                  </div>
                  <div className="mt-4 flex items-center justify-center">
                    <button 
                      className='px-8 py-2 rounded-full text-white font-semibold border-1 border-white shadow-white hover:opacity-80 transition-all duration-200 active:opacity-50'
                      onClick={onRemoveDevices}
                    >
                      Reset trusted device
                    </button>                    
                  </div>
                </>
              ) : null}
            </div>

            {profile?.isAdmin ? (
              <div className="mt-12 flex items-center justify-center">
                <button 
                  className='px-8 py-2 rounded-full text-white font-semibold border-1 border-white shadow-white hover:opacity-80 transition-all duration-200 active:opacity-50'
                  onClick={onSyncCoteriPlaylists}
                  disabled={syncing}
                >
                  Sync Coteri Playlists
                </button>                  
              </div>
            ) : null}
          </>
        )}

        <Footer />
      </div>

      <EditProfileModal
        isOpen={editModal}
        onClose={handleModalClose}
        profileDefaultValues={profile}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modal?.title || ''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modal?.description || ''}</ModalBody>
          <ModalFooter>
            <>
              <Button
                type="text"
                text={'Cancel'}
                size={'medium'}
                onClick={onClose}
              />
              <Button
                text={'Disconnect'}
                size={'medium'}
                onClick={modal?.onSubmit}
              />
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isReportOpen} onClose={onCloseReport} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please provide details on the issue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              name="issue"
              h="130px"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            />
          </ModalBody>
          <div
            className={`flex items-center ${isReporting ? 'justify-center' : 'justify-between'} px-[30px] py-4`}
          >
            {isReporting ? (
              <ClipLoader color="#5B4ABC" />
            ) : (
              <>
                <Button
                  type="text"
                  text={'Cancel'}
                  size={'medium'}
                  onClick={onCloseReport}
                />
                <Button
                  text={'Submit'}
                  size={'medium'}
                  onClick={handleReport}
                />
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </Base>
  )
}

export default Settings
